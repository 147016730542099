import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { useCurrentUser, useCurrentUserIsReaderEAA, useCurrentUserIsReaderFBiB } from "../Persistence/CurrentUserContext";

export const EAADivider = ({ icon, label, onClickHandler, className }) => {
	const currentUser = useCurrentUser();
	const isReaderEAA = useCurrentUserIsReaderEAA()
	const isReaderFBiB = useCurrentUserIsReaderFBiB()
	return (
		<Divider className={"eaa-divider " + (className ? className : ' h-1rem')} align="center">
			<Button disabled={isReaderEAA && isReaderFBiB} className=' m-0 px-5 py-2' onClick={onClickHandler}>
				<i className={icon + " mr-4"}></i>
				<label>{label}</label>
			</Button>
		</Divider>

	)
}