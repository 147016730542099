import React, { useContext, useState, useEffect } from "react";
import { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { useCurrentUser } from "./CurrentUserContext";
import { getSortedList } from "../snippets";

const listenernames = ['created', 'patched'];
const providername = 'RemindersProvider';
const servicename = 'reminders';
const RemindersContext = React.createContext();
const RemindersObjectContext = React.createContext();

export function useRemindersList() {
	return useContext(RemindersContext);
}

export function useObjectRemindersList() {
	return useContext(RemindersObjectContext);
}

export function RemindersProvider({ children }) {
	const currentUser = useCurrentUser();
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	async function getRemindersForObject(servicename, id) {
		return list.filter(e => e.serviceName === servicename && e.serviceId === id)
	}

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, (_list) => setList(getSortedList(_list, 'date', false)), servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			const query = {
				active: 1,
				reminded: { $in: [-1, 0] },
				userId: currentUser.id,
				$sort: { date: 1 }
			}
			findData('reminders', query).then((_list) => {
				setList(getSortedList(_list, 'date', false));
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length}`)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted, currentUser])

	return (
		<RemindersObjectContext.Provider value={getRemindersForObject}>
			<RemindersContext.Provider value={list}>
				{children}
			</RemindersContext.Provider>
		</RemindersObjectContext.Provider>
	)
}