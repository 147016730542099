import React, { useContext, useState, useEffect } from "react";

const StrgPressedContext = React.createContext();

export function useStrgPressed() {
	return useContext(StrgPressedContext);
}

export function StrgPressedProvider({ children }) {

	const [strgPressed, setStrgPressed] = useState(false)

	useEffect(() => {
		function handleKeyDown(e) { setStrgPressed(e.keyCode === 17) }
		function handleKeyUp(e) { setStrgPressed(false) }
		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('keyup', handleKeyUp);

		// Don't forget to clean up
		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('keydown', handleKeyUp);
		}
	}, []);

	return (
		<StrgPressedContext.Provider value={strgPressed}>
			{children}
		</StrgPressedContext.Provider>
	)
}
