import { Card } from "primereact/card"
import { useEffect, useState } from "react";
import { ENUM_ICONS } from "../Components/Icons";
import { getData } from "../feathers";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useCurrentUser, useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { logDefault } from "../Utils/logger";
import { CompanyAddresses } from "./CompanyAddresses"
import { CompanyChangeDialog } from "./CompanyChangeDialog";
import { employmentObligationOptions } from "./CompanyForm";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { getCompany } from "./CompanyManager";
import { CONTACTPERSON_SERVICECONFIGLIST } from "../ContactPersons/ContactPersonsForm";
import { ContactPersonsSheet } from "../ContactPersons/ContactPersonsSheet";
import { CompanyFBiBJobOpenings } from "./CompanyFBiBJobOpenings";
import { useFBiBMode } from "../Persistence/ConfigurationsContext";


const CLASSNAME = 'CompanySheet'
export const CompanySheet = ({ company, onUpdateCompany, readonly, hideSpecialFields, fbibCaseMode, fbibCaseId, onSelectCompany }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const addBreadcrumb = useBreadcrumbsAdd();
	const fbibMode = useFBiBMode();
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
	const [contactPersons, setContactPerson] = useState([]);
	const [addresses, setAddresses] = useState([]);

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'CompanySheet readonly: ' + readonly)
	})

	useEffect(() => {
		setContactPerson((company && company.contactPersons) ? company.contactPersons.filter(e => e.active === 1) : [])
		setAddresses((company && company.addresses) ? company.addresses.filter(e => e.active === 1) : [])
	}, [company])

	const onCompanyUpdate = async (company) => {
		setDisplayCompanyDialog(false)
		onUpdateCompany(company)

	}
	const onChangeCompanyId = async (companyId) => {
		setDisplayCompanyDialog(false)
		if (onSelectCompany) {
			await getCompany(companyId).then(async (company) => {
				await onSelectCompany(company)
			})

		} else {
			await getCompany(companyId, true).then(async (company) => {
				await onUpdateCompany(company)
			})

		}
	}

	const renderHeader = () => {
		const fbibJobopening = (company && company.fbibJobOpenings ? company.fbibJobOpenings : []).find(e => {
			return fbibCaseId === e.fbibCaseId && (e.status === 'ARRANGED' || e.status === 'RESERVED')
		})
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Unternehmen</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>{!company ? 'kein Unternehmen gesetzt' : company.name} </div>
				<div className='flex gap-2'>
					<div className='hidden w-1rem' onClick={handleSelectCompany}><i className={ENUM_ICONS.EYE} /></div>
					<div className={isReader || readonly ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayCompanyDialog(true)}><i className={company ? ENUM_ICONS.PENCIL : ENUM_ICONS.PLUS_CIRCLE} /></div>
					<div className={fbibMode && fbibCaseMode && !fbibJobopening && company ? 'flex w-1rem' : 'hidden'} onClick={() => setDisplayDeleteDialog(true)}><i className={ENUM_ICONS.TRASH} /></div>
				</div>

			</div>
		</div>
	}

	const handleSelectCompany = async () => {
		await getData(ENUM_SERVICES.COMPANIES, company.id).then((company) => {
			addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.COMPANIES}/` + company.id);
		})
	}

	const handleDeleteCompanyInParent = async () => {
		await onSelectCompany(null).then(() => {
			setDisplayDeleteDialog(false);
		})
	}

	const renderQuote = () => {
		const employees = company && company.employees && company.employees > 0 ? company.employees : 0;
		const employeesSbM = company && company.employeesSbM ? company.employeesSbM : 0;
		const quote = employees > 0 ? (100 / employees) * employeesSbM : 0;
		return quote.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%'
	}

	return (
		<>
			<Card className={'companysheet card-sheet border-none mb-3'} header={renderHeader}>
				<div className={company && company.phone && !hideSpecialFields ? 'grid' : 'hidden'}>
					<div className="col-fixed">Telefon</div>
					<div className="col">{!company ? '' : company.phone}</div>
				</div>
				<div className={company && company.fax && !hideSpecialFields ? 'grid' : 'hidden'}>
					<div className="col-fixed">Fax</div>
					<div className="col">{!company ? '' : company.fax}</div>
				</div>
				<div className={company && company.mobile && !hideSpecialFields ? 'grid' : 'hidden'}>
					<div className="col-fixed">Mobil</div>
					<div className="col">{!company ? '' : company.mobile}</div>
				</div>
				<div className={company && company.mail && !hideSpecialFields ? 'grid' : 'hidden'}>
					<div className="col-fixed">E-Mail</div>
					<div className="col">{!company ? '' : company.mail}</div>
				</div>
				<div className={company ? 'grid' : 'hidden'}>
					<div className="col-fixed">Mitarbeiter</div>
					<div className="col">{!company ? '' : company.employees}</div>
				</div>
				<div className={company ? 'grid' : 'hidden'}>
					<div className="col-fixed">SbM</div>
					<div className="col">{!company ? '' : company.employeesSbM}</div>
				</div>
				{!company || !company.branch ? '' : <div className='grid'>
					<div className="col-fixed">Branche</div>
					<div className="col">{company.branch.name}</div>
				</div>}
				<div className={company && company.chamber ? 'grid' : 'hidden'}>
					<div className="col-fixed">Kammer</div>
					<div className="col">{!company ? '' : company.chamber}</div>
				</div>
				<div className={company && company.chamberNo ? 'grid' : 'hidden'}>
					<div className="col-fixed">Betriebsnummer</div>
					<div className="col">{!company ? '' : company.chamberNo}</div>
				</div>
				<div className={company ? 'grid' : 'hidden'}>
					<div className="col-fixed">Region</div>
					<div className="col">{!company ? '' : company.region.name}</div>
				</div>
				<div className={company ? 'grid' : 'hidden'}>
					<div className="col-fixed">Besch.-pflicht</div>
					<div className="col">{!company ? '' : employmentObligationOptions.find(entry => entry.value === company.employmentObligation).display}</div>
				</div>
				<div className={company ? 'grid' : 'hidden'}>
					<div className="col-fixed">Quote</div>
					<div className="col">{renderQuote()}</div>
				</div>
			</Card>
			<CompanyChangeDialog
				companyId={company ? company.id : null}
				displayCompanyDialog={displayCompanyDialog}
				setDisplayCompanyDialog={setDisplayCompanyDialog}
				handleOnCompanyPatched={onCompanyUpdate}
				changeCompanyId={company ? undefined : onChangeCompanyId} />

			{!company ? '' : <ContactPersonsSheet
				parentId={company.id}
				contactPersons={contactPersons}
				addressList={addresses}
				numCarousel={1}
				readonly={readonly}
				handleUpdateParent={onUpdateCompany}
				CONTACTPERSON_SERVICECONFIG={CONTACTPERSON_SERVICECONFIGLIST.COMPANY} />}


			{!company || (fbibMode && fbibCaseMode) ? '' : <CompanyAddresses readonly={readonly} hideDivider={true} company={company} addresses={addresses} numCarousel={1} onUpdateCompany={onUpdateCompany} />}
			<ConfirmDialog title='Unternehmen aus FBiB Fall entfernen' message={`<span>Wollen Sie das Unternehmen <br/><b>${company ? company.name : ''}</b><br/>aus dem FBiB Fall<br/>löschen?<span>`} labelOk='Ja'
				handleOnClick={handleDeleteCompanyInParent} displayConfirmDialog={displayDeleteDialog} setDisplayConfirmDialog={setDisplayDeleteDialog} />
			{company && fbibMode && fbibCaseMode ? <CompanyFBiBJobOpenings
				readonly={readonly}
				company={company}
				numCarousel={1}
				onUpdateCompany={onUpdateCompany}
				fbibCaseId={fbibCaseId}
				fbibCaseMode={fbibCaseMode}
			/> : ''}
		</>
	)

}