import { format, parseISO } from "date-fns";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from "react";
import { findData, patchData } from "../feathers";
import { useCurrentUser, useCurrentUserIsAdmin } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog"
import { EAADivider } from "./EAADivider";
import { EditorChangesChecked } from "./EditorChangesChecked";
import { ENUM_ICONS } from "./Icons";
import { useOrganizationsSelectionList } from "../Persistence/OrganizationsContext";
import { useUserList } from "../Persistence/UserContext";
import { useFormik } from "formik";
import { InputTextareaChangesChecked } from "./InputTextareaChangesChecked";
import { DropdownChangesChecked } from "./DropdownChangesChecked";
import { EditDialog } from "./Dialogs/EditDialog";
import logger from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { formatDateTime, getSortedList } from "../snippets";
import { useFBiBMode, useFormatDate } from "../Persistence/ConfigurationsContext";

export const emptyComment = { id: null, version: 1, active: 1, origin: 'EAA', comment: '', commentDate: new Date(), reminderMessage: '', reminder: false, reminderUser: null }
const validateCommentsModul = (data) => {
	let errors = {};
	if (!data.comment || data.comment === '') {
		errors.comment = 'Eine Beschreibung muss eingegeben werden!';
	}
	if (data.reminder && !data.reminderMessage) {
		errors.reminderMessage = 'Bei aktivierter Wiedervorlage muss eine Notiz eingegeben werden!';
	}
	if (data.reminder && !data.reminderUser) {
		errors.reminderUser = 'Bei aktivierter Wiedervorlage muss ein/e Bearbeiter*in eingegeben werden!';
	}
	return errors;
}

export const CommentsModule = ({ servicename, readonly, comments, onUpdateComment, idFieldname, parentId, reminderParentService, reminderName }) => {
	const formatDate = useFormatDate();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const userList = useUserList();
	const fbib = useFBiBMode();
	const [organizationUserList, setOrganizationUserList] = useState([])
	const [organization, setOrganization] = useState(undefined);
	const [sortedComments, setSortedComments] = useState([]);
	const [mounted, setMounted] = useState(false);
	const [displayConfirmDeleteCommentDialog, setDisplayConfirmDeleteCommentDialog] = useState(false);
	const [displayCommentDialog, setDisplayCommentDialog] = useState(false);

	useEffect(() => {
		if (comments) {
			comments = comments.filter(entry => entry.active);
			const sortedComments = comments.sort((x, y) => { const a = y.commentDate; const b = x.commentDate; return a == b ? 0 : a > b ? 1 : -1; })
			setSortedComments(sortedComments)
		} else {
			getComments()
		}
		formikCommentsModul.setValues({ ...JSON.parse(JSON.stringify(emptyComment)), reminderMessage: currentUser.displayname + ' hat Ihnen eine Notiz hinterlassen!', commentDate: new Date(), })
		setMounted(true);
	}, [comments, parentId])

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.active === 1 && entry.organizationId === organization.id) : userList;
		setOrganizationUserList(getSortedList(tempList, 'lastname'));
		formikCommentsModul.setFieldValue('reminderUser', null)
	}, [organization, userList])


	const getComments = () => {
		findData(servicename, { [idFieldname]: parentId }).then((comments) => {
			const sortedComments = comments.sort((x, y) => { const a = y.commentDate; const b = x.commentDate; return a == b ? 0 : a > b ? 1 : -1; })
			setSortedComments(sortedComments)
		})
	}


	const patchComment = async () => {
		const comment = formikCommentsModul.values
		if (!comment.id) {
			comment.origin = (fbib ? 'FBIB' : 'EAA')
			comment.userId = currentUser.id;
			comment.commentDate = new Date();
		}
		comment[idFieldname] = parentId
		await patchData(servicename, comment).then(async () => {
			formikCommentsModul.setValues({ ...JSON.parse(JSON.stringify(emptyComment)), reminderMessage: currentUser.displayname + ' hat Ihnen eine Notiz hinterlassen!', commentDate: new Date(), })
			if (comment.reminder) {
				const reminder = {
					id: null, description: `zu Kommentar vom ${formatDateTime(comment.commentDate)} ${comment.reminderMessage}`, date: new Date(), serviceName: reminderParentService, serviceId: parentId,
					userId: comment.reminderUser.id, name: reminderName, reminded: -1
				}
				await patchData(ENUM_SERVICES.REMINDERS, reminder).catch((error) => {
					logger.error("Error  while patch reminder: " + error.message)
				})
			}
			setDisplayCommentDialog(false);
			setDisplayConfirmDeleteCommentDialog(false);
			if (!comments) {
				getComments()
			} else if (onUpdateComment) {
				onUpdateComment()
			}
		})
	}

	const onDeleteCommentHandler = async (comment) => {
		formikCommentsModul.setValues({ ...comment, commentDate: parseISO(comment.commentDate) })
		setDisplayConfirmDeleteCommentDialog(true);
	}

	const deleteComment = async () => {
		formikCommentsModul.values.active = 0;
		patchComment()
	}

	const onNewComment = () => {
		formikCommentsModul.setValues({ ...JSON.parse(JSON.stringify(emptyComment)), reminderMessage: currentUser.displayname + ' hat Ihnen eine Notiz hinterlassen!', commentDate: new Date(), })
		setDisplayCommentDialog(true)
	}

	const onEditComment = (comment) => {
		formikCommentsModul.setValues({ ...comment, commentDate: parseISO(comment.commentDate) })
		setDisplayCommentDialog(true);
	}

	const renderComments = () => {
		return sortedComments.map((comment) => {
			return (!comment.active ? '' :
				<div className='flex flex-column mr-2' key={'comment_' + comment.id}>
					<div className='flex flex-row w-full gap-3'>
						<div className='flex w-15rem color-text-blue font-medium font-bold'>
							{comment.user.lastname + ', ' + comment.user.firstname}
						</div>
						<div className='flex w-15rem color-lessblack font-medium font-bold'>
							{formatDateTime(parseISO(comment.commentDate))}
						</div>
						<div className='flex flex-grow-1 justify-content-end pt-1 color-lessblack font-medium gap-3'>
							{currentUser.id !== comment.userId || readonly ? '' : <i className={ENUM_ICONS.PENCIL + "edit-icon"} onClick={() => { onEditComment(comment) }} />}
							{currentUser.id !== comment.userId || readonly ? '' : <i className={ENUM_ICONS.TRASH + "del-icon"} onClick={() => { onDeleteCommentHandler(comment) }} />}
						</div>
					</div>
					<div className='flex flex-row w-full mt-1'>
						<div className='flex-1 text-base color-lessblack editor-changes-checked border-round-sm p-2' dangerouslySetInnerHTML={{ __html: (comment.anotation ? `<p><strong>@${comment.anotation}:</strong>` : '') + comment.comment }} />

					</div>
				</div>
			)
		})
	}

	/** formik commentsModul */
	const formikCommentsModul = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyComment)),
		validate: validateCommentsModul,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
		validateOnBlur: true,
	});

	return (!mounted ? '' :
		<div className='comments-module'>
			<div className="flex flex-column">
				<div className='flex m-2 text-600 text-base'>
					{(sortedComments ? sortedComments.filter(entry => entry.active).length : 'keine') + ' Kommentare'}
				</div>

				<div className='flex bg-white shadow-2 m-2 w-full overflow-y-auto'>
					<div className='flex flex-column w-full gap-4 m-4 surface-section '>
						<EAADivider className={readonly ? 'hidden' : 'mt-2 '} icon={ENUM_ICONS.PLUS} label='Kommentar anlegen' key='comments-dialog' onClickHandler={onNewComment} />
						{sortedComments ? renderComments() : ''}
					</div>
				</div>
			</div>
			<EditDialog
				form={<div>
					<div className='flex w-full mt-4'>
						<span className="p-float-label w-full">
							<EditorChangesChecked id='comment' formik={formikCommentsModul} value={formikCommentsModul.values.comment} setValue={(value) => formikCommentsModul.setFieldValue('comment', value)} label='Beschreibung' />
						</span>
					</div>
					<div className={(formikCommentsModul.values.id || !isAdmin ? 'hidden' : 'formgrid flex flex-column')}>
						<div className="flex field-checkbox ml-2">
							<Checkbox inputId="reminder" checked={formikCommentsModul.values.reminder} onChange={e => formikCommentsModul.setFieldValue('reminder', e.checked)} />
							<label htmlFor="reminder">Wiedervorlage erstellen</label>
						</div>
						<div className={formikCommentsModul.values.id || !formikCommentsModul.values.reminder ? 'hidden' : 'flex'}>
							<div className='flex w-6'>
								<InputTextareaChangesChecked id='reminderMessage' formik={formikCommentsModul} label='Notiz der Wiedervorlage' autoFocus rows={5} className='w-full' setShowDialog={() => { }} />
							</div>
							<div className='flex w-6 flex-column '>
								<div className="field col mr-3 " style={{ maxHeight: '73px' }}>
									<label htmlFor='organization' className='block'>Auswahl Träger</label>
									<Dropdown id="organization" className='bg-white w-full' showClear filter name="organization" optionLabel="name" value={organization} options={organizationsSelectionList} onChange={(e) => setOrganization(e.value)} autoFocus />
								</div>
								<DropdownChangesChecked filter={true} editmode={true} label={'Wiedervorlage für Bearbeiter*in'} id={'reminderUser'} itemLabel={'displayname'} formik={formikCommentsModul} options={organizationUserList} />
							</div>
						</div>
					</div>
				</div>
				}
				formik={formikCommentsModul}
				header={formikCommentsModul.values.id ? 'Kommentar ändern' : 'Neuen Kommentar hinzufügen'}
				message=''
				setDisplayDialog={setDisplayCommentDialog}
				displayDialog={displayCommentDialog}
				patchForm={async () => await patchComment()}
			/>

			<ConfirmDialog title='Kommentar entfernen' message={`Sind Sie sicher, Ihren Kommentar vom ${formikCommentsModul.values.commentDate ? formatDate(formikCommentsModul.values.commentDate) : ''}\nzu löschen?`} labelOk='Ja'
				handleOnClick={deleteComment} displayConfirmDialog={displayConfirmDeleteCommentDialog} setDisplayConfirmDialog={setDisplayConfirmDeleteCommentDialog} />
		</div>
	)

}