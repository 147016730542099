import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const listenernames = ['created', 'patched'];
const providername = 'TaskareasProvider';
const servicename = ENUM_SERVICES.TASKAREAS;
const TaskareasContext = React.createContext();
const TaskareasSelectionContext = React.createContext();

export function useTaskareasList() {
	return useContext(TaskareasContext);
}
export function useTaskareasSelectionList() {
	return useContext(TaskareasSelectionContext);
}

export function TaskareasProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', '########### ' + providername + ' update');
			updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
			setListSelection(list.filter((entry) => entry.active));
		}

	}, [list])

	useEffect(() => {
		if (mounted) {
			getData(servicename).then((_list) => {
				setList(_list);
				setListSelection(_list.filter((entry) => entry.active));
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length}`)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<TaskareasContext.Provider value={list}>
			<TaskareasSelectionContext.Provider value={listSelection}>
				{children}
			</TaskareasSelectionContext.Provider>
		</TaskareasContext.Provider>
	)
}