import { format } from 'date-fns';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import client from '../feathers';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useState } from 'react';
import { ToastError } from '../Utils/ToastError';
import { NavigationEntry } from './NavigationEntry';
import { ENUM_ROUTES } from './Routes';


export const Datenschutz = ({ showSidebarLabel }) => {
	const configurationsList = useConfigurationsList();
	const [toastParams, setToastParams] = useState(null);
	const getDatenschutzblatt = async () => {
		await client.service(ENUM_SERVICES.UTILS).create({ method: 'datenschutzblatt' }).then(async (result) => {
			const base64 = await fetch(result);
			var blob = await base64.blob();
			// Erstellen einer Blob-URL
			const fileURL = URL.createObjectURL(blob);
			// Öffnen der Datei in einem neuen Tab
			window.open(fileURL);
		}).catch((error) => {
			setToastParams({ title: 'Beim Holen des Datenschutzblattes ist ein Fehler aufgesteten !', message: error.message })
		});
	}


	return !configurationsList.eaaModules.datenschutzblatt.visible ? '' :
		<>
			<ToastError toastParams={toastParams} />
			<NavigationEntry
				key={'datenschutz'}
				icon='fa fa-info-circle'
				label='Datenschutz Infos'
				route={ENUM_ROUTES.DASHBOARD}
				onSidebarClick={getDatenschutzblatt}
				showLabel={showSidebarLabel} />
		</>

}


