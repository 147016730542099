import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const listenernames = ['created', 'patched'];
const providername = 'TaskareaTagsProvider';
const servicename = ENUM_SERVICES.TASKAREATAGS;
const TaskareaTagsContext = React.createContext();

export function useTaskareaTagsList() {
	return useContext(TaskareaTagsContext);
}

export function TaskareaTagsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			logContext(providername, 'info', '########### ' + providername + ' listItemChanged', listItemChanged);
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(providername, 'info', '########### ' + providername + ' update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			getData(servicename).then((_list) => {
				setList(_list);
				logContext(providername, 'info', `########### ${providername} useEffect found:${_list.length}`, _list)
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (

		<TaskareaTagsContext.Provider value={list}>
			{children}
		</TaskareaTagsContext.Provider>
	)
}