import { useEffect, useState } from "react";
import { useFormatDate } from "../../Persistence/ConfigurationsContext";
import { Tag } from "primereact/tag";

const CLASSNAME = 'BasicSheetSubtitle';

export const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];
export const BasicSheetSubtitle = ({ object }) => {
	const formatDate = useFormatDate();
	const [status, setStatus] = useState(listStatus.find(entry => entry.alias === object.active))

	useEffect(() => {
		setStatus(listStatus.find(entry => entry.alias === object.active))
	}, [object])

	return <div className='flex text-center justify-content-center text-lg mb-1 gap-2'>
		<div>{object.displayId + ' erstellt am ' + (object.createdAtMillis ? formatDate(object.createdAtMillis) : '') + (object.creator ? (' von ' + object.creator.displayname) : '')}</div>
		<Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
	</div>
}