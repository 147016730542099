import { Multiselect } from '../Components/FormElements/Multiselect';
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { useTaskareasList, useTaskareasSelectionList } from "../Persistence/TaskareasContext";
import { useEffect, useState } from "react";
import { logDefault } from '../Utils/logger';

const CLASSNAME = 'RequestDistributionUserRegionAndTaskareaForm';
export const RequestDistributionUserRegionAndTaskareaForm = ({ formik }) => {
	const regionsListSelection = useRegionsSelectionList();
	const regionsListAll = useRegionsList();
	const taskareaListSelection = useTaskareasSelectionList();
	const taskareaListAll = useTaskareasList();

	const [regionOptions, setRegionOptions] = useState(regionsListAll)
	const [taskareaOptions, setTaskareaOptions] = useState(taskareaListAll)

	useEffect(() => {
		if (regionsListAll && regionsListSelection) {
			const regionOptionsList = JSON.parse(JSON.stringify(regionsListSelection));
			formik.values.regions.forEach(entry => {
				const listEntry = regionsListAll.find(e => e.id === entry.id);
				if (!regionOptionsList.find(e => e.id === entry.id)) {
					regionOptionsList.push(listEntry);
				}
			})
			setRegionOptions(regionOptionsList)
			logDefault(CLASSNAME, 'info', 'regionOptionsList:' + regionOptionsList.length);
		}
	}, [regionsListSelection, regionsListAll, formik.values.regions])

	useEffect(() => {
		if (taskareaListAll && taskareaListSelection) {
			const optionsList = JSON.parse(JSON.stringify(taskareaListSelection));
			formik.values.taskareas.forEach(entry => {
				const listEntry = taskareaListAll.find(e => e.id === entry.id);
				if (!optionsList.find(e => e.id === entry.id)) {
					optionsList.push(listEntry);
				}
			})
			setTaskareaOptions(optionsList)
			logDefault(CLASSNAME, 'info', 'optionsList:' + optionsList.length);
		}
	}, [taskareaListSelection, taskareaListAll, formik.values.taskareas])

	return <div className='col'>
		<div className="formgrid grid">
			<div className="field col">
				<div className="formgrid grid flex flex-column">
					<Multiselect id='regions' options={regionOptions} filter={true} editmode={true} placeholder="Regionen wählen" itemLabel="name" showClear={false} label='Regionen' formik={formik} display='chip' />
					<Multiselect id='taskareas' options={taskareaOptions} filter={true} editmode={true} placeholder="Aufgabenbereiche wählen" itemLabel="name" showClear={false} label='Aufgabenbereiche' formik={formik} display='chip' />
				</div>
			</div>
		</div>
	</div>
}

export const emptyUserRegionAndTaskarea = { regions: null, taskareas: null, firstname: '', lastname: '' }
export const validateUserRegionAndTaskareaForm = (data) => {
	let errors = {};

	return errors;
}