import { startOfYear } from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect } from "react";
import { useCurrentUser, useCurrentUserIsAdmin } from "../Persistence/CurrentUserContext";
import { sortUserList, useUserList } from "../Persistence/UserContext";

export const StatisticPerson = ({ onClickCreateStatistic }) => {
	const userList = useUserList();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const [dateFrom, setDateFrom] = useState(startOfYear(new Date()));
	const [dateTo, setDateTo] = useState(new Date());
	const [personId, setPersonId] = useState(currentUser.id);
	const [personList, setPersonList] = useState([]);


	useEffect(() => {
		const sortedList = sortUserList(userList)
		setPersonList(sortedList)
	}, [userList])

	return (
		<div className="card shadow-2 p-4 my-4 bg-white">
			<div className='font-bold mb-4'>Persönliche Statistik</div>
			<div className="flex  gap-6">
				<div className='flex  flex-column'>
					<div className="p-fluid grid formgrid gap-4">
						<div className={isAdmin && currentUser.showPersonalStatisticForAdmin === '1' ? 'field col-fixed w-15rem' : 'hidden'}>
							<label htmlFor="dateCommonTo">Person</label>
							<Dropdown
								id="personlist"
								name="personlist"
								optionLabel="displayname"
								optionValue="id"
								value={personId}
								options={personList}
								onChange={(e) => setPersonId(e.value)}
								autoFocus
								filter
								filterBy="displayname"
								showFilterClear
								showClear
								className={'bg-white w-full'} />
						</div>
						<div className="field col-fixed w-15rem">
							<label htmlFor="dateCommonFrom">Von</label>
							<Calendar maxDate={dateTo} id="dateCommonFrom" value={dateFrom} onChange={(e) => setDateFrom(e.value)} showIcon dateFormat="dd.mm.yy" />
						</div>
						<div className="field col-fixed w-15rem">
							<label htmlFor="dateCommonTo">Bis</label>
							<Calendar minDate={dateFrom} maxDate={new Date()} id="dateCommonTo" value={dateTo} onChange={(e) => setDateTo(e.value)} showIcon dateFormat="dd.mm.yy" />
						</div>
					</div>
					<div className='flex '>
						<Button disabled={dateFrom == null || dateTo == null} onClick={() => onClickCreateStatistic(dateFrom, dateTo, isAdmin ? personId : currentUser.id)} className='flex  text-white' label='Statistik generieren' />
					</div>
				</div>
			</div>
		</div>
	)
}