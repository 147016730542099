import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button";
import { v4 as uuidv4 } from 'uuid';

export const ConfirmDialog = ({ title, message, labelOk, labelCancel, displayConfirmDialog, setDisplayConfirmDialog, handleOnClick, handleOnReject, className, style }) => {

	return (<>
		<Dialog key={uuidv4()}
			style={style}
			header={title}
			closable={false}
			visible={displayConfirmDialog}
			onHide={() => setDisplayConfirmDialog(false)}
			id="dialog-confirm"
			className={'eaa-inputdialog ' + (className || 'w-28rem')} >
			<div className='flex font-light my-3 p-0 ' style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: message }}>{ }</div>
			<div className="flex flex-row card-container flex-row-reverse mx-4 my-2">
				<Button key={uuidv4()} type="button" className="flex-order-1 button-cancel" label={labelCancel ? labelCancel : 'Abbrechen'} onClick={() => { handleOnReject ? handleOnReject() : setDisplayConfirmDialog(false) }} />
				<Button key={uuidv4()} onClick={() => { handleOnClick(); setDisplayConfirmDialog(false) }} className="flex-order-0 ml-3 " label={labelOk ? labelOk : 'Ok'} />
			</div>

		</Dialog>
	</>)
}
