import { format, parseISO } from "date-fns"
import { Accordion, AccordionTab } from "primereact/accordion"
import { useEffect, useState } from "react"
import { CaseEventDivider } from "../Components/CaseEventDivider"
import { ConfirmDialog } from "../Components/ConfirmDialog"
import { ENUM_AWESOMEICON, ENUM_ICONS, Icon } from "../Components/Icons"
import { deleteData } from "../feathers"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext"
import { useCurrentUser } from "../Persistence/CurrentUserContext"
import { UserProvider } from "../Persistence/UserContext"
import logger from "../Utils/logger"
import { CaseEventsForm, emptyCaseEvent, validateCaseEvent } from "./CaseEventsForm"
import { useFormik } from "formik"
import { EditDialog } from "../Components/Dialogs/EditDialog"
import { formatDateTime, getSortedList } from "../snippets"
import { patchCaseEvent } from "./CaseEventsManager"


export const CaseEventsSheet = ({ eaaCase, caseEvents, eaaCaseUpdate, readonly }) => {
	const currentUser = useCurrentUser();
	const [collapseTabs, setCollapsTabs] = useState(false);
	const [activeIndex, setActiveIndex] = useState([]);
	const [lastUpdatedId, setLastUpdatedId] = useState(null);
	const [caseEventToDelete, setCaseEventToDelete] = useState();
	const [displayCaseEventDialog, setDisplayCaseEventDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [eventClassifierStructure, setEventClassifierStructure] = useState([]);
	const [sortEventsAsc, setSortEventsAsc] = useState(true);
	const configurationsList = useConfigurationsList();

	useEffect(() => {
		if (configurationsList.CaseEventClassifierStructure) {
			setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		}
	}, [configurationsList])

	useEffect(() => {
		let counter = 0;
		const tags = collapseTabs ? [] : caseEvents.map(() => { return counter++ });
		if (activeIndex.length === 0 && lastUpdatedId === null) {
			setActiveIndex([0]);
		}
	}, [caseEvents, lastUpdatedId])

	const renderEventClassifiers = (eventClassifiers) => {
		const allItems = [];
		const eventClassifiersList = eventClassifiers;//eventClassifiers.map(eventClassifier => eventClassifier.caseEventClassifier)

		if (!eventClassifierStructure) {
			return ''
		}
		const classifierStructure = eventClassifierStructure.map((structure) => {
			structure.items.forEach((item) => allItems.push(item))
			const structureItems = structure.items.map(item => item.caseEventClassifier);
			const items = eventClassifiersList.filter(item => structureItems.includes(item.caseEventClassifier) && item.active === 1);
			return { ...structure, items }
		}).filter(structure => structure.items.length > 0)


		return classifierStructure.map((structure) => {
			const items = getSortedList(structure.items, 'caseEventClassifier', false);
			return <div className='flex flex-column' key={'classifier_' + structure.headline}>
				<div className='flex p-0 m-0 text-600 text-base font-semibold'>
					<div>{structure.headline}</div>
				</div>

				<div className='flex flex-column p-1 m-1'>

					{items.map((item) => {
						const structureItem = allItems.find(entry => entry.caseEventClassifier === item.caseEventClassifier)
						const caseEventClassifierOption = configurationsList.CaseEventClassifierList.find(e => e.caseEventClassifier === item.caseEventClassifier);
						return <div className={item.count === 0 || caseEventClassifierOption.hide === 1 ? 'hidden' : 'flex grid'} key={'classifier_' + item.caseEventClassifier}>
							<div className='col-fixed p-1 w-2rem'>{structureItem.fieldType === 'NUMBER' ? item.count : (item.count === 0 ? '' : <i className={ENUM_ICONS.CHECK_SQUARE}></i>)}</div>
							<div className='col p-1'>{structureItem.display} </div>
						</div>

					})}
				</div>
			</div>
		})
	}

	const onEditCaseEvent = (caseEvent) => {
		setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		const editCaseEvent = JSON.parse(JSON.stringify(caseEvent))
		setDisplayCaseEventDialog(true);
		setLastUpdatedId(caseEvent.id)
		formikCaseEvent.setValues(editCaseEvent);

	}
	const onDeleteCaseEvent = (caseEvent) => {
		setCaseEventToDelete(caseEvent);
		setDisplayConfirmDialogDelete(true);
	}

	const deleteCaseEvent = async () => {

		const id = caseEventToDelete.id
		setCaseEventToDelete(null);
		await deleteData('case-events', id).then(() => {
			eaaCaseUpdate();
			setDisplayConfirmDialogDelete(false);
		}).catch((error) => {
			logger.error('Error while deleteCaseEvent: ' + error.message)
		})

	}

	const handleCreateEvent = (eventType) => {
		setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		const reminderMessage = currentUser.displayname + ' hat Ihnen eine Notiz hinterlassen!'
		const classifierSonstiges = configurationsList.CaseEventClassifierList.find(e => e.caseEventClassifier === 'G8');
		classifierSonstiges.active = 1;
		classifierSonstiges.count = 1;
		const caseEventClassifiers = ['CONTACT', 'CONTACTRESULT', 'SUSTAINABILITY'].includes(eventType) ? [classifierSonstiges] : []
		const newEvent = { ...emptyCaseEvent, caseEventClassifiers, reminderMessage, eventType: configurationsList.EventTypeList.find(entry => entry.alias === eventType), userId: currentUser.id }
		formikCaseEvent.setValues(newEvent)
		setDisplayCaseEventDialog(true)
	}

	const toggleAccordionPages = (mode) => {
		setCollapsTabs(mode)
		if (mode) {
			setActiveIndex([]);
		} else {
			let counter = 0;
			const tags = caseEvents.map(() => { return counter++ });
			setActiveIndex(tags);
		}
	}

	const accordionPageHeader = (caseEvent) => {

		const eventType = caseEvent.eventType && typeof caseEvent.eventType === 'object' && caseEvent.eventType.constructor === Object ? caseEvent.eventType.acoordionHeader : caseEvent.eventType
		const eventDate = caseEvent.eventDate ? format(parseISO(caseEvent.eventDate), 'dd.MM.yyyy') : '';
		const eventTime = caseEvent.eventDate ? format(parseISO(caseEvent.eventDate), 'HH:mm') : '';
		const disableEditingEventType = (caseEvent.eventType && typeof caseEvent.eventType === 'object') ? caseEvent.eventType.disableEditing : false;
		const disableEditing = disableEditingEventType || readonly || (!configurationsList.caseEventEditing.includes(currentUser.permissions) && caseEvent.creator && caseEvent.creator.id !== currentUser.id)
		const disableDelete = disableEditingEventType || readonly || (!configurationsList.caseEventDelete.includes(currentUser.permissions) && caseEvent.creator && caseEvent.creator.id !== currentUser.id)

		return <div className="flex w-full justify-content-between m-0">
			<div className='flex w-25rem  gap-4'>
				<div className="flex font-bold w-9rem m-0 ">{`${eventType}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CALENDAR} /> {` ${eventDate}`}</div>
				<div className="flex font-bold m-0 gap-2"><i className={ENUM_ICONS.CLOCK} /> {` ${eventTime}`}</div>
			</div>
			<div className='flex flex-grow-1 '>
				<div className="flex font-bold m-0"> {`Ersteller*in: ${caseEvent.creator ? caseEvent.creator.displayname : ''}`}</div>
			</div>
			<div className="flex m-0 justify-content-end gap-2">
				<i className={disableEditing ? 'hidden' : (ENUM_ICONS.PENCIL + 'fa-lg text-white-500')} onClick={(event) => { onEditCaseEvent(caseEvent); event.stopPropagation(); }} />
				<i className={disableDelete ? 'hidden' : (ENUM_ICONS.TRASH + 'fa-lg text-white-500')} onClick={(event) => { onDeleteCaseEvent(caseEvent); event.stopPropagation(); }} />
			</div>
		</div>
	}

	const renderCaseEvent = (caseEvent) => {
		const eventClassifiers = caseEvent.caseEventClassifiers ? caseEvent.caseEventClassifiers : [];
		const eventType = caseEvent.eventType.alias;
		const isTransfer = eventType === 'CASETRANSFER' || eventType === 'CASERECEIVE';
		return <AccordionTab key={'caseEvent_' + caseEvent.id} headerClassName={(eventType === 'INTERN' ? 'accordion-tab-intern' : (isTransfer ? 'accordion-tab-transfer' : '')) + ' w-full'} header={accordionPageHeader(caseEvent)} >
			<div className="flex flex-column " >
				<div className='flex flex-column py-2'>
					{!isTransfer ? '' :
						<div className='flex text-600 text-base mb-3'>
							{(eventType === 'CASETRANSFER' ? 'Übergabe des Falls an "' : 'Eingehender Fall von "') + caseEvent.transferOrganization + '" ' + caseEvent.transferUser}
						</div>}
					{!caseEvent.description ? '' : <><div className={'flex text-600 text-base font-semibold ' + (isTransfer ? '' : 'mb-2')}>
						{isTransfer ? 'Nachricht' : 'Beschreibung'}
					</div>
						<div className='editor-changes-checked' dangerouslySetInnerHTML={{ __html: caseEvent.description }} /></>}
				</div>
				{renderEventClassifiers(eventClassifiers)}
			</div>
		</AccordionTab>
	}

	const renderCaseEvents = (caseEvents) => {
		if (caseEvents) {
			getSortedList(caseEvents, 'eventDate', sortEventsAsc)
			//			caseEvents.sort((x, y) => { const a = sortEventsAsc ? x.eventDate : y.eventDate; const b = sortEventsAsc ? y.eventDate : x.eventDate; return a == b ? (y.id > x.id ? 1 : -1) : a > b ? 1 : -1 })
		} else {
			return 'keine'
		}
		const tabs = caseEvents.map((caseEvent) => {
			return renderCaseEvent(caseEvent)
		});

		return <div className='w-full'>
			<CaseEventDivider caseEvents={caseEvents} icon={ENUM_ICONS.PLUS} label='Kontakt anlegen' onClickHandler={handleCreateEvent} className={eaaCase.status.alias !== 'INACTIVE' ? 'my-3' : 'hidden'} readonly={readonly} />
			<div className='flex justify-content-between mb-2'>
				<div className='flex gap-6'>
					<div className='flex cursor-pointer' onClick={() => toggleAccordionPages(false)}>
						<Icon className='mr-2' ENUM_AWESOMEICON={ENUM_AWESOMEICON.EXPAND} />
						<label className=''>Alle Kontakte erweitern</label>
					</div>
					<div className='flex cursor-pointer' onClick={() => toggleAccordionPages(true)}>
						<Icon className='mr-2' ENUM_AWESOMEICON={ENUM_AWESOMEICON.COLLAPSE} />
						<label className=''>Alle Kontakte schliessen</label>
					</div>
				</div>
				<div className='flex gap-2 cursor-pointer' onClick={() => { setSortEventsAsc(!sortEventsAsc) }} ><i className="pi pi-sort-alt" /><span>{(!sortEventsAsc ? 'Aufsteigend' : 'Absteigend') + ' nach Datum'}</span></div>
			</div>
			<Accordion
				id='accordion-caseeventsheet'
				multiple
				className='w-full' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} >
				{tabs}
			</Accordion>
		</div>
	}

	/** formik CaseEvent */
	const formikCaseEvent = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyCaseEvent)),
		validate: validateCaseEvent,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
		validateOnBlur: true,
	});

	return (
		<>
			<ConfirmDialog title='Kontakt löschen?' message={`<span>Sind Sie sicher, dass Sie <br/><b>${caseEventToDelete ? caseEventToDelete.eventType.deleteMessage : ''} vom ${caseEventToDelete ? formatDateTime(parseISO(caseEventToDelete.eventDate)) : ''}</b><br/> wirklich unwiderruflich löschen wollen?</span>`} labelOk='Ja'
				handleOnClick={deleteCaseEvent} displayConfirmDialog={displayConfirmDialogDelete} setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />

			<EditDialog
				form={<UserProvider><CaseEventsForm formik={formikCaseEvent} caseEventsParent={caseEvents} key='caseEventForm' /></UserProvider>}
				formik={formikCaseEvent}
				header={formikCaseEvent.values.id ? formikCaseEvent.values.eventType.dialogHeader : formikCaseEvent.values.eventType.dialogHeaderNew}
				message=''
				setDisplayDialog={setDisplayCaseEventDialog}
				displayDialog={displayCaseEventDialog}
				patchForm={async () => await patchCaseEvent(formikCaseEvent.values, eaaCase, setDisplayCaseEventDialog, eaaCaseUpdate, setLastUpdatedId)}
			/>
			<div className="grid m-0 caseeventssheet select-none" >
				{renderCaseEvents(caseEvents)}
			</div>
		</>
	)

}