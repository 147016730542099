import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useState } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_ICONS } from "../Components/Icons";
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { logDefault } from "../Utils/logger";
import { getData, patchData } from "../feathers";
import { OrganizationForm, emptyOrganization, validateOrganization } from './OrginizationForm';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { ENUM_DATAVIEWS_CONFIG, } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { TemplateDataViewPagination } from "../Components/TemplateDataViewPagination";
import { useCurrentUserIsAdmin } from "../Persistence/CurrentUserContext";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.ORGANIZATIONS;
const CLASSNAME = 'OrganizationDataview'
export const OrganizationDataview = ({ handleStatusChange }) => {
	const getDataviewConfig = useDataviewsConfig();
	const isAdmin = useCurrentUserIsAdmin()
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));
	const configurationsList = useConfigurationsList();
	const [refreshTime, setRefreshTime] = useState(null)
	const [displayOrganizationDialog, setDisplayOrganizationDialog] = useState(false);

	const patchOrganization = async () => {
		const org = { id: formikOrganization.values.id, version: formikOrganization.values.version, name: formikOrganization.values.name, type: formikOrganization.values.orgtype.alias, ignoreInStatistics: formikOrganization.values.ignoreInStatistics ? 1 : 0 };
		await patchData(ENUM_SERVICES.ORGANIZATIONS, org).then(() => {
			setDisplayOrganizationDialog(false)
			setRefreshTime(Date.now())
		})
	}

	/** Organizations */
	const formikOrganization = useFormik({
		initialValues: { ...emptyOrganization },
		validate: validateOrganization,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const onEditOrganization = async (entry) => {
		await getData('organizations', entry.id).then((organization) => {
			organization.orgtype = configurationsList.OrganizationTypesList.find(entry => entry.alias === organization.type)
			organization.ignoreInStatistics = (organization.ignoreInStatistics === 1)
			logDefault(CLASSNAME, 'info', 'onEditOrganization organization:', organization)
			formikOrganization.setValues(organization);
			setDisplayOrganizationDialog(true)
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Träger bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditOrganization(rowData) } },
		{ label: rowData.active ? 'Inaktiv setzen' : 'Aktiv setzen', icon: (rowData.active ? ENUM_ICONS.DEACTIVATE : ENUM_ICONS.REACTIVATE), command: () => { handleStatusChange(rowData, 'organizations', 'Organisation') } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const renderOrganizationType = (row) => {
		const type = configurationsList.OrganizationTypesList.find(entry => entry.alias === row.type)
		return type ? type.display : row.type
	}

	const columnsOrganizations = [
		{ filterAlias: 'col_01', field: "ignoreInStatistics", sortable: true, filter: "ignoreInStatistics", header: "Statistik", style: { width: '120px' }, body: 'renderListIcon' },
		{ filterAlias: 'col_02', field: "name", sortable: true, filter: "name", header: "Name", style: { flexGrow: 1, flexBasis: '300px' } },
		{ filterAlias: 'col_03', field: "type", sortable: true, filter: "type", header: "Typ", style: { flexGrow: 1, flexBasis: '300px' }, body: renderOrganizationType },
		{ filterAlias: 'col_04', field: "active", sortable: true, filter: "active", header: "Status", style: { width: '200px' }, body: 'renderTag' },
		{ style: { width: '30px' }, body: renderContextMenu },
	]

	return <div className='flex flex-column gap-2'>
		<EditDialog
			form={<OrganizationForm formik={formikOrganization} key='organization-form' />}
			formik={formikOrganization}
			patchForm={patchOrganization}
			header={formikOrganization.values.id ? 'Träger ändern' : 'Träger neu anlegen'}
			message={formikOrganization.values.id ? 'Hier können sie den Träger: ' + formikOrganization.values.name + ' ändern.' : 'Hier können sie einen neuen Träger anlegen'}
			setDisplayDialog={setDisplayOrganizationDialog}
			displayDialog={displayOrganizationDialog}
		/>
		{!dataviewConfig ? '' : <TemplateDataViewPagination
			key='datatable-organizations'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			buttons={[<Button
				key='button-new-organization'
				disabled={!isAdmin}
				label='Träger anlegen'
				onClick={() => { formikOrganization.setValues({ ...emptyOrganization }); setDisplayOrganizationDialog(true) }} />]}
			columns={columnsOrganizations}
			onDoubleClick={onEditOrganization}
			dataTableWidth='100%'
			refreshTime={refreshTime}
			dataviewConfig={dataviewConfig}
		/>}

	</div>
}