import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { patchData } from '../feathers';
import { ChangePassword } from "../Login/ChangePassword";
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReader, useCurrentUserUpdate } from "../Persistence/CurrentUserContext"
import { emptyUser, UserForm, validateUser } from './UserForm';
import { useTaskareasList } from '../Persistence/TaskareasContext';
import { useRegionsList } from '../Persistence/RegionsContext';

export const UserProfile = () => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const updateCurrentuser = useCurrentUserUpdate();
	const configurationsList = useConfigurationsList();
	const taskareasList = useTaskareasList();
	const regionsList = useRegionsList();
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);
	const [displayUserDialog, setDisplayUserDialog] = useState(false);

	const formikUser = useFormik({
		initialValues: { ...emptyUser },
		validate: validateUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	useEffect(() => {
		if (currentUser) {
			formikUser.setValues({ ...currentUser })
		}
	}, [currentUser])

	const handleChangePassword = () => {
		setDisplayPasswordDialog(true);
	}
	const handleEditUser = () => {
		formikUser.values.permissions = configurationsList.RolesList.find((entry) => entry.alias === currentUser.permissions)
		formikUser.values.regions = formikUser.values.regions.map(e => regionsList.find(region => e.id === region.id));
		formikUser.values.taskareas = formikUser.values.taskareas.map(e => taskareasList.find(taskarea => e.id === taskarea.id))
		setDisplayUserDialog(true)
	}

	const patchUser = async () => {
		formikUser.values.displayname = formikUser.values.firstname + " " + formikUser.values.lastname
		const { id, active, version, mobilenumber, phonenumber, firstname, lastname, regions, taskareas, displayname } = formikUser.values

		await patchData('users', { id, version, active, mobilenumber, phonenumber, firstname, lastname, regions, taskareas, displayname }).then((user) => {
			updateCurrentuser(user);
			formikUser.setValues(user)
			setDisplayUserDialog(false);
		})
	}


	return (
		<div className='mx-auto eaa-maincontent'>
			<Dialog header={'Passwort ändern: ' + currentUser.displayname} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} hideLogo={true} hideDialog={setDisplayPasswordDialog} />
			</Dialog>
			<EditDialog
				form={<UserForm formik={formikUser} key='user-form' />}
				formik={formikUser}
				patchForm={patchUser}
				header='Eigenes Profil bearbeiten'
				message='Hier können sie Ihre eigenen Daten ändern.'
				setDisplayDialog={setDisplayUserDialog}
				displayDialog={displayUserDialog}
			/>
			<div className='flex justify-content-end mb-4 gap-2'>
				<Button onClick={handleEditUser} icon={ENUM_ICONS.PENCIL} disabled={isReader} className='flex text-white ' label='Bearbeiten' />
			</div>
			<div className='flex grid'>
				<div className='col flex  flex-column  text-4xl font-semibold'>
					<div className='flex justify-content-center'>
						{currentUser.firstname + " " + currentUser.lastname}
					</div>
				</div>
				<div className='col-fixed w-2rem'>
					<OverlayPanelMenu items={[{ label: 'Passwort ändern', icon: ENUM_ICONS.USER, command: handleChangePassword }]} />
				</div>
			</div>
			<div className='flex card-container blue-container overflow-hidden grid'>
				<FieldInfo label='Telefon' content={currentUser.phonenumber} className='col-3' />
				<FieldInfo label='Mobil' content={currentUser.mobilenumber} className='col-3' />
				<FieldInfo label='E-Mail' content={currentUser.email} className='col-3' />
			</div>
			<div className='flex card-container blue-container overflow-hidden grid'>
				<FieldInfo label='Träger' content={!currentUser.organization ? '' : currentUser.organization.name} className='col-3' />
				{!configurationsList.eaaModules.REQUESTDISTRIBUTION ? '' :
					<FieldInfo label='Regionen' content={currentUser.regions.map(e => e.name).join('; ')} className='col-3' />
				}
				{!configurationsList.eaaModules.REQUESTDISTRIBUTION ? '' :
					<FieldInfo label='Bereiche' content={currentUser.taskareas.map(e => e.name).join('; ')} className='col-3' />
				}
			</div>

		</div>
	)
}