import { useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext";
import { Button } from "primereact/button";

export const FBiBCaseEventDivider = ({ onClickHandler, companyId, fbibCasePersonId, trainingCenterId, readonly }) => {
	const isReader = useCurrentUserIsReaderFBiB();

	return (<div className='flex flex-column'>
		<div className={isReader ? 'hidden' : 'flex flew-row w-full justify-content-between my-4'} id='createbuttons-caseeventsheet'>
			<div className='flex flex-auto '>
				<Button disabled={readonly || !companyId} className='accordion-tab-event' label='Kontakt Unternehmen' onClick={() => onClickHandler('CONTACT')} />
				<Button disabled={readonly || !fbibCasePersonId} className='accordion-tab-event' label='Kontakt Fallperson' onClick={() => onClickHandler('CONTACTPERSON')} />
				<Button disabled={readonly || !trainingCenterId} className='accordion-tab-event' label='Kontakt Ausbildungsstätte' onClick={() => onClickHandler('CONTACTTRAININGCENTER')} />
			</div>
			<div className='justify-content-end'>
				<Button disabled={readonly} className='w-12rem accordion-tab-intern' label='Interne Notiz anlegen' onClick={() => onClickHandler('INTERN')} />
				<Button disabled={readonly} className='w-12rem accordion-tab-transfer' label='Übergabe anlegen' onClick={() => onClickHandler('CASETRANSFER')} />
			</div>
		</div>
	</div>
	)
}