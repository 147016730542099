

export const FieldInfo = ({ label, content, icon, className }) => {
	return (
		<div className={(className ? className : 'flex') + " align-items-stretch flex-grow-1 col"}>
			<div className={"bg-white shadow-2 m-2 border-round-sm flex-grow-1"}>
				<div className=" grid fieldinfo p-0 m-0">
					<div className="col flex flex-column justify-content-between">
						<div className='text-base text-600'>
							<label>{label}</label>
						</div>
						<div className='text-xl ' style={{ wordBreak: 'break-word' }}>
							{content}
						</div>
					</div>
					{!icon ? '' : <div className={'flex col-fixed justify-content-end w-3rem'}>
						{icon}
					</div>}
				</div>
			</div>
		</div>
	)
}