import { useCallback, useEffect, useState } from 'react';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { OverviewCaseRequestsDataview } from './OverviewCaseRequestsDataview';
import { OverviewCasePersonsDataview } from './OverviewCasePersonsDataview';
import { OverviewCaseEventsDataview } from './OverviewCaseEventsDataview';
import { useCurrentUser, useTabViewsIndex, useTabViewsIndexUpdate } from '../Persistence/CurrentUserContext';
import { OverviewAssociatedContactsDataview } from './OverviewAssociatedContactsDataview';
import { ENUM_TABVIEWS } from '../Enums/ENUM_TABVIEWS';
import { OverviewRelatedUsersDataview } from './OverviewRelatedUsersDataview';
import { ENUM_DATAVIEWS_CONFIG, getDataviewConfig } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { OverviewCompanyAddressesDataview } from './OverviewCompanyAddressesDataview';
import { OverviewCompanyContactsDataview } from './OverviewCompanyContactsDataview';
import { Menubar } from 'primereact/menubar';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../Components/Icons';
import { OverviewProviderActivitiesDataview } from './OverviewProviderActivitiesDataview';
import { OverviewEaaCasesReviewDataview } from './OverviewEaaCasesReviewDataview';

function arrayMax(array) {
	return array.reduce((a, b) => Math.max(a, b));
}

export const OverviewContainer = () => {
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const menubarItemSelected = useTabViewsIndex();
	const setMenubarItemSelected = useTabViewsIndexUpdate();
	const [mounted, setMounted] = useState(false)
	const [items, setItems] = useState(null)
	const [dataView, setDataView] = useState();

	useEffect(() => {
		const value = menubarItemSelected ? menubarItemSelected[ENUM_TABVIEWS.OVERVIEWS] : ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES
		setMenuItems(value)
		switch (value) {
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES: setDataView(<OverviewCompanyAddressesDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS: setDataView(<OverviewCompanyContactsDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS: setDataView(<OverviewCaseEventsDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS: setDataView(<OverviewCaseRequestsDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES: setDataView(<OverviewProviderActivitiesDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS: setDataView(<OverviewAssociatedContactsDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS: setDataView(<OverviewRelatedUsersDataview />); break;
			case ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW: setDataView(<OverviewEaaCasesReviewDataview />); break;
			default: setDataView(<OverviewCompanyAddressesDataview />)
		}
	}, [menubarItemSelected])

	useEffect(() => {
		if (configurationsList && !mounted) {
			setMounted(true);
		}

	}, [configurationsList])

	const getMenuItemVisibility = useCallback((enum_dataview_configs) => {
		if (currentUser && configurationsList) {
			const configOverview = configurationsList.eaaModules || [];
			const visible = enum_dataview_configs.map((overview) => {
				const config = configOverview.OVERVIEWS_TEST[overview] || configOverview.OVERVIEWS[overview]
				if (overview === ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS && configurationsList.eaaModules.ASSOCIATEDCONTACTS !== "1") {
					return 0;
				}
				return (config.VISIBLEROLES.includes(currentUser.permissions) || config.VISIBLEROLES.includes(currentUser.permissionsFBiB)) ? 1 : 0
			})
			return arrayMax(visible) === 1
		}
		return false
	}, [configurationsList, currentUser])

	const changeDataView = (enum_dataview_config, component) => {
		setMenubarItemSelected({ ...menubarItemSelected, [ENUM_TABVIEWS.OVERVIEWS]: enum_dataview_config })
	}

	const setMenuItems = useCallback((enum_dataview_config) => {
		const items = [
			{
				label: 'Unternehmen',
				icon: ENUM_ICONS.COMPANY,
				className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS].includes(enum_dataview_config)) ? 'overviews-menubar-selected' : '',
				visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS]),
				items: [
					{
						label: 'Adressen',
						visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES]),
						className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES].includes(enum_dataview_config)) ? 'overviews-menubaritem-selected' : '',
						command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES, <OverviewCompanyAddressesDataview />),
					},
					{
						label: 'Ansprechpartner*innen',
						visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS]),
						className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS].includes(enum_dataview_config)) ? 'overviews-menubaritem-selected' : '',
						command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYCONTACTS, <OverviewCompanyContactsDataview />),
					}
				]
			},
			{
				label: 'Fälle',
				icon: ENUM_ICONS.EAACASE,
				className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW].includes(enum_dataview_config)) ? 'overviews-menubar-selected' : '',
				visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW]),
				items: [
					{
						label: 'Kontakte',
						visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS]),
						className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS].includes(enum_dataview_config)) ? 'overviews-menubaritem-selected' : '',
						command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS, <OverviewCaseEventsDataview />)
					},
					{
						label: 'Anträge',
						visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS]),
						className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS].includes(enum_dataview_config)) ? 'overviews-menubaritem-selected' : '',
						command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS, <OverviewCaseRequestsDataview />)
					},
					{
						label: 'Überprüfung',
						visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW]),
						className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW].includes(enum_dataview_config)) ? 'overviews-menubaritem-selected' : '',
						command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_EAACASESREVIEW, <OverviewEaaCasesReviewDataview />)
					},
					{
						label: 'Fallpersonen',
						visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS]),
						className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS].includes(enum_dataview_config)) ? 'overviews-menubaritem-selected' : '',
						command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEPERSONS, <OverviewCasePersonsDataview />)
					}
				]
			},
			{
				label: 'Trägeraktivitäten',
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.PROVIDERACTIVITIES}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES].includes(enum_dataview_config)) ? 'mr-2 color-text-blue' : 'mr-2'}
				/>,
				className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES].includes(enum_dataview_config)) ? 'overviews-menubar-selected' : '',
				visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES]),
				command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES, undefined)
			},
			{
				label: 'Operative Rollen',
				icon: ENUM_ICONS.USER,
				className: ([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS].includes(enum_dataview_config)) ? 'overviews-menubar-selected' : '',
				visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS, ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS]),
				items: [
					{ label: 'Netzwerkkontakte', visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS]), command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS, <OverviewAssociatedContactsDataview />) },
					{ label: 'Bearbeiter*innen', visible: getMenuItemVisibility([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS]), command: () => changeDataView(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS, <OverviewRelatedUsersDataview />) }
				]
			}
		]
		setItems(items);
	}, [])

	return !mounted || !dataView ? '' : <div className='mx-auto eaa-dataView flex' style={{ width: 'calc(100vw - 150px)' }}>
		<div className='flex flex-column w-full border-1 border-gray-200 bg-white' >
			<Menubar model={items} className='border-none border-bottom-1' />
			<div className='z-0 mx-3 my-1'>{dataView}</div>
		</div>
	</div>
}