import React, { useContext, useEffect, useState } from "react";
import { logContext } from "../Utils/logger";

const CLASSNAME = 'ErrorContext';
const ErrorContext = React.createContext();
const ErrorUpdateContext = React.createContext();

export function useError() {
	return useContext(ErrorContext);
}
export function useErrorUpdate() {
	return useContext(ErrorUpdateContext);
}

export function ErrorProvider({ children }) {
	const [error, setError] = useState(null);

	function updateError(title, message) {
		logContext(CLASSNAME, 'info', `############# updateError ############### ${title} ${message}`);
		setError({ title, message });
	}

	return (
		<ErrorContext.Provider value={error ? error : { title: 'Fehlerinformation', message: null }}>
			<ErrorUpdateContext.Provider value={updateError}>
				{children}
			</ErrorUpdateContext.Provider>
		</ErrorContext.Provider>
	)
}

export const ERROR_RESOURCENOTFOUND = {
	title: 'Zugriff verweigert', message: 'Die von Ihnen gewünschte Ressource kann nicht geöffnet werden. Dies kann eine <br/>oder mehrere der folgenden Gründe haben:' +
		'<ul><li>Sie haben nicht die erforderlichen Berechtigungen</li>' +
		'<li>Die Berechtigungen der Ressource haben sich geändert</li>' +
		'<li>Die Ressource wurde durch eine Administrator*in gelöscht</li></ul>' +
		'Mehr Informationen erhalten Sie in der EAA DOQ Dokumentation unter<br/>' +
		'<a href="https://docs.eaa.management/#/support/?id=fehlermeldungen" target="_blank">https://docs.eaa.management/#/support/?id=fehlermeldungen</a><br/>' +
		'Bei Rückfragen wenden Sie sich bitte an die EAA DOQ Koordinator*innen.'
}