import { useTranslation } from "react-i18next";
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { DropdownChangesChecked } from "../../Components/DropdownChangesChecked"
import { InputChangesChecked } from "../InputChangesChecked";


export const Address = ({ formik, validateForm, className, readonly }) => {
	const { t } = useTranslation(['formelements']);
	const configurationsList = useConfigurationsList();

	const handleCityChange = async (value) => {
		const postcode = configurationsList.postcodes.find(e => e.postcodeAndCity === value)
		formik.setFieldValue('city', postcode ? postcode.city : '');
		formik.setFieldValue('postcode', postcode ? postcode.postcode : '')
		if (validateForm) {
			await validateForm().then(() => {
				validateForm()
			})
		}
	}

	return <div className={className}>
		<label >{t('formelements.address.headline.label')}</label>
		<div className="border-1 surface-border p-3">
			<div className="field grid">
				<InputChangesChecked
					disabled={readonly}
					tabIndex={100}
					formik={formik}
					id='line1'
					label={t('formelements.address.line1.label')}
					rowStyle
					colLabels={'col-fixed w-9rem'}
					type='text' />
			</div>
			<div className="field grid">
				<InputChangesChecked
					disabled={readonly}
					tabIndex={101}
					formik={formik}
					id='line2'
					label={t('formelements.address.line2.label')}
					rowStyle
					colLabels={'col-fixed w-9rem'}
					type='text' />
			</div>
			<div className="field grid">
				<InputChangesChecked
					disabled={readonly}
					tabIndex={102}
					formik={formik}
					id='line3'
					label={t('formelements.address.line3.label')}
					rowStyle
					colLabels={'col-fixed w-9rem'}
					type='text' />
			</div>
			{formik.values.country === 'Deutschland' ?
				<div className={'field grid'}>
					<DropdownChangesChecked virtualScrollerOptions={{ itemSize: 38 }}
						tabIndex={0}
						filter={true}
						label={'Postleitzahl | Ort'}
						id={'postcodeAndCity'}
						formik={formik}
						optionValue='postcodeAndCity'
						itemLabel={'postcodeAndCity'}
						handleChange={handleCityChange}
						colLabels='col-fixed w-9rem'
						className={'m-0'}
						rowStyle
						disabled={readonly}
						options={configurationsList.postcodes} />
				</div>
				:
				<div className={'field grid'}>
					<div className='flex w-full mb-0'>
						<div className=''>
							<InputChangesChecked
								disabled={readonly}
								tabIndex={103}
								formik={formik}
								id='postcode'
								label={t('formelements.address.postcode.label')}
								rowStyle
								colLabels={'col-fixed w-9rem'}

								type='text' />
						</div>
						<InputChangesChecked
							disabled={readonly}
							tabIndex={104}
							formik={formik}
							id='city'
							label={t('formelements.address.city.label')}
							rowStyle
							colLabels={'col-fixed w-9rem'}
							type='text' />
					</div>

				</div>}

			<div className="field grid mb-0">
				<DropdownChangesChecked
					tabIndex={106}
					label={'Land'}
					id={'country'}
					formik={formik}
					optionValue='country'
					itemLabel={'country'}
					rowStyle
					disabled={readonly}
					colLabels='col-fixed w-9rem'
					className={'m-0'}
					options={configurationsList.CountryList} />

			</div>
		</div>
	</div>
}