import { useState, useEffect } from 'react';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { v4 as uuidv4 } from 'uuid';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { ENUM_ROUTES } from '../Navigation/Routes';

const CLASSNAME = 'OverviewCompanyAddressesDataview'
export const OverviewCompanyAddressesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES));
	const configurationsList = useConfigurationsList()
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const renderAddressType = (rowData) => {
		return !configurationsList || !configurationsList.AddressTypeList ? '' : configurationsList.AddressTypeList.find(e => e.alias === rowData.addressType).display
	}

	const columns = [
		{ filterAlias: 'col_01', style: { width: '100px' } },
		{ filterAlias: 'col_02', style: { width: '130px' }, body: renderAddressType },
		{ filterAlias: 'col_03', style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ filterAlias: 'col_04', style: { width: '250px' }, body: renderResponsible },
		{ filterAlias: 'col_05', style: { width: '300px' }, body: null },
		{ filterAlias: 'col_06', style: { width: '250px' }, body: (row) => <div>{[row.line1, row.line2, row.line3].map(e => <div key={uuidv4()}>{e}</div>)}</div> },
		{ filterAlias: 'col_07', style: { width: '100px' } },
		{ filterAlias: 'col_08', style: { width: '200px' } },
		{ filterAlias: 'col_09', style: { width: '150px' }, body: 'renderTag' }
	]

	return <div className='w-full overflow-auto'>
		{!dataviewConfig ? '' : <TemplateDataViewPagination
			showOpenLinkColumn={true}
			key='datatable-overviews-companyaddresses'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			ROUTE={ENUM_ROUTES.COMPANIES}
			columns={columns}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig}
		/>}
	</div>
}