import { useEffect, useState } from "react";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { findData, getData } from "../../feathers";
import { DropdownChangesChecked } from "../../Components/DropdownChangesChecked";

export const TrainingCourseDropdown = ({ formik, readonly, handleTrainingCourseChange, handleTrainingCourseBlur }) => {
	const [mounted, setMounted] = useState(true)
	const [trainingCourseList, setTrainingCourseList] = useState();

	useEffect(() => {
		getData(ENUM_SERVICES.TRAININGCOURSE).then((trainingCourseList) => {

			setTrainingCourseList(trainingCourseList);
			setMounted(true);
		})
	}, [])

	const chamberOptionTemplate = (option) => {
		return (
			<div className="flex m-0 p-0">
				{(option.name + ' | ' + (option.chamber ? option.chamber.display : ''))}
			</div>
		);
	}

	return (!mounted ? '' :
		<DropdownChangesChecked
			editmode={true}
			formik={formik}
			id='trainingCourseId'
			optionValue={'id'}
			itemTemplate={chamberOptionTemplate}
			valueTemplate={(option, props) => !option ? <span>{props.placeholder}</span> : (option.name + ' | ' + (option.chamber ? option.chamber.display : ''))}
			itemLabel='name'
			filter={true}
			showClear={false}
			filterBy="name"
			label='Name des Ausbildungsgangs'
			options={trainingCourseList}
			className='mr-3'
			key='trainingCourse' />
	)
}