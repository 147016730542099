import { InputField } from "../Components/InputField"
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES"
import { copyObject, getSortedList } from "../snippets";
import { DropdownChangesChecked } from "../Components/FormElements/DropdownChangesChecked";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useEffect, useState } from "react";

const CLASSNAME = 'ContactPersonsForm'
export const CONTACTPERSON_SERVICECONFIGLIST = {
	COMPANY: { servicename: ENUM_SERVICES.COMPANIES, contactTypeListname: 'ContactTypeList', refFieldname: 'companyId', displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	TRAININGCENTER: { servicename: ENUM_SERVICES.TRAININGCENTERS, contactTypeListname: 'TrainingcenterContactTypeList', refFieldname: 'trainingCenterId', displayname: 'Ausbildungsstätte', deleteMessage: 'von der Ausbildungsstätte', editMessage: 'der Ausbildungsstätte' }
}



export const ContactPersonsForm = ({ formik, CONTACTPERSON_SERVICECONFIG, addressList }) => {
	const configurationsList = useConfigurationsList();

	const getAddressList = () => {
		const addresses = copyObject(addressList)
		for (let address of addresses) {
			const addressType = configurationsList.AddressTypeList.find(e => e.alias === address.addressType)
			address.addressTypeDisplay = addressType ? addressType.display : addressType
			address.listentry = `${addressType ? addressType.display : ''} ${address.line1} ${address.line2} ${address.line3} in ${address.postcode} ${address.city}`
		}
		addresses.unshift({ id: null, addressTypeDisplay: 'keine Adresse zugeordnet', listentry: '', city: '', postcode: '', line1: '' })
		return addresses
	}

	const addressListTemplate = (entry) => {
		const addressType = configurationsList.AddressTypeList.find(e => e.alias === entry.addressType)
		return <div>
			<div>{`${entry.addressTypeDisplay}`}</div>
			<div>{`${entry.line1}`}</div>
			{entry.line2 ? <div>{`${entry.line2}`}</div> : ''}
			{entry.line3 ? <div>{`${entry.line3}`}</div> : ''}
			<div>{`${entry.postcode} ${entry.city}`}</div>
		</div>
	}

	return <div className='grid'>{!formik ? <div></div> :
		<div className='col'>
			<div className="formgrid grid mr-3">
				<DropdownChangesChecked
					id='contactType'
					editmode={true}
					label='Kontakt Klassifikation'
					formik={formik}
					itemLabel='display'
					itemValue={'alias'}
					className={'bg-white w-full'}
					classnameDiv='mr-0'
					options={getSortedList(configurationsList[CONTACTPERSON_SERVICECONFIG.contactTypeListname], 'display', false)}
				/>
			</div>
			<div className="formgrid grid mr-3">
				<InputField formik={formik} id='lastname' label='Nachname' type='text' />
				<InputField formik={formik} id='firstname' label='Vorname' type='text' />
			</div>
			<div className="formgrid grid mr-3">
				<InputField formik={formik} id='phone' label='Telefon' type='text' />
				<InputField formik={formik} id='fax' label='Fax' type='text' />
			</div>
			<div className="formgrid grid  mr-3">
				<InputField formik={formik} id='mobile' label='Mobile' type='text' className='w-full' />
				{!addressList ? '' : <DropdownChangesChecked
					options={getAddressList()}
					formik={formik}
					editmode={true}
					id='addressId'
					itemValue='id'
					itemLabel='listentry'
					itemTemplate={addressListTemplate}
					label='Adresse'
					placeholder='keine Adresse zugeordnet'
					classnameDiv='m-0'
				/>}
			</div>
			<div className="formgrid grid mr-3">
				<InputField formik={formik} id='mail' label='E-Mail' type='text' className='w-full' />
				<DropdownChangesChecked
					options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]}
					formik={formik}
					editmode={true}
					id='advertisingBan'
					optionValue='value'
					itemLabel='label'
					label='Werbeverbot'
					classnameDiv='m-0'
				/>
			</div>
			<div className="formgrid grid  mr-3">
				<InputField formik={formik} id='jobDescription' label='Job-Beschreibung' type='text' className='w-full' />
			</div>
		</div>
	}</div>

}

export const emptyContactPerson = { id: null, active: 1, versionCompanyContactPerson: 1, version: 1, origin: 'EAA', contactType: '', lastname: '', firstname: '', phone: '', mail: '', advertisingBan: 0, fax: '', mobile: '', jobDescription: '', addressId: '' }
export const validateContactPerson = (data) => {
	let errors = {};

	if (!data.lastname) {
		errors.lastname = 'Nachname ist ein Pflichtfeld';
	}
	if (!data.contactType) {
		errors.contactType = 'Sie müssen die Kontakt Klassifikation für dieses Unternehmen angeben';
	}
	return errors;
}