import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { ROUTES } from "../Navigation/Routes";
import { useFBiBMode } from "../Persistence/ConfigurationsContext";

export const AssociatedContactCard = ({ associatedContact, onDeleteHandler, onEditHandler, readonly }) => {
	const fbib = useFBiBMode();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const onShowHandler = (associatedContact) => {
		const route = fbib ? ROUTES.FBIB_ASSOCIATEDCONTACTS : ROUTES.ASSOCIATEDCONTACTS
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + associatedContact.id }] })

	}


	const renderContent = () => {
		const { phone, mobile, email, region, regionId, fax } = associatedContact
		const height = ((phone ? 1 : 0) + (mobile ? 1 : 0) + (email ? 1 : 0) + (regionId ? 1 : 0) + (fax ? 1 : 0))
		return <div className={`flex align-content-end flex-column px-2 py-1 h-${3 + height}rem`}>
			<div className={phone ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col overflow-hidden'>{phone}</div></div>
			<div className={mobile ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col overflow-hidden'>{mobile}</div></div>
			<div className={fax ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.FAX} /></div><div className='col overflow-hidden'>{fax}</div></div>
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col overflow-hidden'>{email}</div></div>
			<div className={regionId ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.REGIONS} size={ENUM_AWESOMEICON_SIZE.X1} /></div><div className='col overflow-hidden'>{region ? region.name : ''}</div></div>
		</div>
	}
	const renderTitle = (associatedContact) => {
		return <div className={`flex title ${associatedContact.active && associatedContact.organization.active ? '' : 'color-contact-inactive'}`}>
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{associatedContact.name}
				</div>
				<div>
					{associatedContact.organization.name}
				</div>
			</div>
			<div className={"flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.EYE + 'title-eye'} onClick={() => { onShowHandler(associatedContact) }} />
				<i className={!onEditHandler || readonly || associatedContact.isOrganizationEntry ? 'hidden' : ENUM_ICONS.PENCIL} onClick={() => { onEditHandler(associatedContact) }} />
				{!onDeleteHandler ? '' : <i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(associatedContact) }} />}
			</div>
		</div>
	}

	return (<>
		{false && !onEditHandler && associatedContact.active === 0 ? '' :
			<div id='associatedcontact-card' className={`eaa-card associatedcontact-card shadow-2 ${associatedContact.isSelectedContact ? 'color-contact-selected' : ''}`}>
				<div >{renderTitle(associatedContact)}</div>
				{renderContent(associatedContact)}
			</div>
		}
	</>)
}