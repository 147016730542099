import { useEffect } from "react";
import { ENUM_ICONS } from "../Components/Icons";
import { logDefault } from "../Utils/logger";
import { format, toDate } from "date-fns";
import { useFormatDate } from "../Persistence/ConfigurationsContext";

const CLASSNAME = 'RemindersCard';
export const ReminderCard = ({ reminder, onEditHandler, onDoneHandler }) => {

	const formatDate = useFormatDate();
	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'RemindersCard', reminder)
	}, [reminder])

	const renderContent = () => {
		return <div className="flex align-content-end flex-column h-5rem px-2 py-1">
			{reminder.description}
		</div>
	}
	const renderTitle = () => {
		logDefault(CLASSNAME, 'info', 'RemindersCard', reminder)
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{formatDate(toDate(reminder.millisecondsDate))}
				</div>
			</div>
			<div className={"flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(reminder) }} />
				<i className={ENUM_ICONS.CHECK + 'title-check'} onClick={() => { onDoneHandler(reminder) }} />
			</div>
		</div>
	}

	return (<>
		{!reminder || reminder.active === 0 ? '' :
			<div id='reminder-card' className="eaa-card reminder-card shadow-2">
				<div>{renderTitle()}</div>
				{renderContent()}
			</div>
		}
	</>)
}