import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { UserProvider } from "../Persistence/UserContext";
import { RelatedUserCard } from "./RelatedUserCard";
import { RelatedUsersForm, emptyRelatedUser, validateRelatedUser } from "./RelatedUsersForm";
import { ToastError } from "../Utils/ToastError";
import { Carousel } from "primereact/carousel";
import { ENUM_ICONS } from "../Components/Icons";
import { useEffect, useState } from "react";
import { EAADivider } from "../Components/EAADivider";
import { addRelatedUser, deleteRalatedUser, getRelatedUsersFromParent } from "./RelatedUserManager";
import { useFormik } from "formik";
import { copyObject } from "../snippets";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

export const RelatedUsersSheet = ({ readonly, relatedUserServiceConfig, parentId, hideDivider }) => {
	const configurationsList = useConfigurationsList();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [displayConfirmDialogResponsible, setDisplayConfirmDialogResponisble] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const [relatedUsers, setRelatedUsers] = useState([]);
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		if (mounted) {
			getRelatedUsersFromParent(relatedUserServiceConfig, parentId).then((result) => {
				setRelatedUsers(result)
			})
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const onRelatedUsersUpdate = async () => {
		const relatedUser = formik.values
		relatedUser[relatedUserServiceConfig.idFieldname] = parentId;

		relatedUser.roleType = relatedUser.roleType.alias || relatedUser.roleType
		await addRelatedUser(relatedUserServiceConfig, parentId, relatedUser, setToastParams, setRelatedUsers).then(() => {
			setDisplayEditDialog(false);
		})
	}

	const onRelatedUserDelete = async () => {
		await deleteRalatedUser(formik.values.id, relatedUserServiceConfig, parentId, setToastParams, setRelatedUsers)
	}

	const onRelatedUserEdit = (relatedUser) => {
		if (relatedUser) {
			relatedUser.isResponsible = relatedUser.roleType === 'R';
		} else {
			relatedUser = copyObject(emptyRelatedUser)
		}
		const existingRelatedUsers = relatedUsers.filter(e => e.relatedUserActive === 1)
		const existingResponsibleId = existingRelatedUsers.find(e => e.roleType === 'R')
		relatedUser.existingRelatedUsers = existingRelatedUsers.map(e => e.id)
		relatedUser.existingResponsible = existingResponsibleId && existingResponsibleId !== formik.values.id
		formik.setValues(relatedUser)
		setDisplayEditDialog(true)
	}

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	const confirmRelatedUserDelete = async (relatedUser) => {
		relatedUser.isResponsible = relatedUser.roleType === 'R';
		formik.setValues(relatedUser);
		setDisplayConfirmDialogDelete(true)
	}

	const confirmRelatedUserUpdate = async () => {
		if (formik.values.isResponsible && formik.values.alias !== 'R') {
			/** Wenn der zu ändernde related-user der Verantwortliche war und jetzt nicht mehr
			* -> Abfrage ob der Verantwortliche wirklich 'geändert' werden soll */
			setDisplayConfirmDialogResponisble(true)
		} else {
			await onRelatedUsersUpdate()
		}
	}

	/** Related User */
	const formik = useFormik({
		initialValues: copyObject(emptyRelatedUser),
		validate: validateRelatedUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderHeader = () => {
		return <div className={(hideDivider ? 'flex' : 'hidden') + ' flex-column'}>
			<div className='flex font-bold mb-2'>Operative Rollen</div>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Fachberater*innen</div>
				<div className='flex '>
					<div className={readonly ? 'hidden' : 'flex w-1rem open-icon'} onClick={() => { onRelatedUserEdit(null); setDisplayEditDialog(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderRelatedUserCard = (relatedUser) => {
		return !relatedUser ? '' : <RelatedUserCard key={'relatedusercard_' + relatedUser.id} readonly={readonly} relatedUser={relatedUser} onDeleteHandler={confirmRelatedUserDelete} onEditHandler={onRelatedUserEdit} />
	}

	const renderRelatedUsers = (relatedUsers) => {
		return !relatedUsers ? 'keine' : <div className='flex flex-column gap-1 w-full'> {relatedUsers.map((relatedUser) => {
			return <RelatedUserCard key={'relatedusercard_' + relatedUser.id} relatedUser={relatedUser} onDeleteHandler={confirmRelatedUserDelete} onEditHandler={onRelatedUserEdit} readonly={readonly} />
		})}</div>
	}

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				title={'Operative Rolle entfernen'}
				message={`<span>Sind Sie sich sicher, die Fachberater*in
					<b>${formik.values.lastname}, ${formik.values.firstname}</b>
					mit der Rolle "${formik.values.roleType ? configurationsList.CaseRoleList.find(e => e.alias === formik.values.roleType).display : ''}" ${relatedUserServiceConfig.deleteMessage} zu entfernen?
					${formik.values.isResponsible ? 'Damit ist keine verantwortliche Person mehr gesetzt!' : ''}</span>`}
				labelOk='Ja'
				handleOnClick={onRelatedUserDelete}
				displayConfirmDialog={displayConfirmDialogDelete}
				setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />
			<ConfirmDialog
				title='Fachberater*in als "Verantwortliche Rolle" entfernen'
				message={`<span>Sind Sie sich sicher, die verantwortliche Fachberater*in <br/><b>${formik.values.lastname}, ${formik.values.firstname}</b><br/>${relatedUserServiceConfig.deleteMessage} zu entfernen?<br/>Damit ist keine verantwortliche Person mehr gesetzt!</span>`} labelOk='Ja'
				handleOnClick={onRelatedUsersUpdate}
				displayConfirmDialog={displayConfirmDialogResponsible}
				setDisplayConfirmDialog={setDisplayConfirmDialogResponisble} />
			<EditDialog
				form={<RelatedUsersForm formik={formik} key='relateduser-form' />}
				formik={formik}
				patchForm={confirmRelatedUserUpdate}
				header={`Operative Rolle ${formik.values.id ? 'ändern' : 'hinzufügen'}`}
				message={'Hier können Sie eine operative Rolle ' + (relatedUserServiceConfig.editMessage) + (formik.values.id ? ' ändern.' : ' erstellen.')}
				setDisplayDialog={setDisplayEditDialog}
				displayDialog={displayEditDialog}
			/>
			<Card className="relatedusersheet card-sheet" header={renderHeader}>
				<EAADivider className={(hideDivider ? 'hidden ' : '') + 'm-0 p-1'} icon={ENUM_ICONS.PLUS} label='Neue Fachberater*in zuweisen' onClickHandler={() => onRelatedUserEdit(null)} />

				{hideDivider ?
					renderRelatedUsers(relatedUsers.filter(e => e.relatedUserActive === 1)) :
					<Carousel className='w-full mt-10 ' showIndicators={false} value={relatedUsers} numVisible={3} numScroll={3} itemTemplate={renderRelatedUserCard} responsiveOptions={responsiveOptions} />
				}
			</Card>
		</>
	)

}