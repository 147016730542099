import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export enum ENUM_ROUTES {
	ERROR = 'Fehler',
	JOBPROFILES = 'Stellenprofile', STATISTICS = 'Statistik', PROVIDERACTIVITIES = 'Traegeraktivitaeten', COMPANIES = 'Unternehmen', REQUESTDISTRIBUTION = 'Aufgabenverteilung', REQUESTDISTRIBUTIONARCHIVE = 'Aufgabenverteilung-Archiv', REQUESTDISTRIBUTIONUSERRELATIONS = 'Aufgabenverteilung-Verwaltung',
	EAACASES = 'Faelle', DASHBOARD = 'Dashboard', ADDRESSES = 'Adressen', USERADMINISTRATION = 'Nutzer*innen', SETTINGS = 'Stammdaten', USERPROFILE = 'Profil'
	, ASSOCIATEDCONTACTS = 'Netzwerkkontakte'
	, ASSOCIATEDORGANIZATIONS = 'Netzwerkorganisationen'
	, OVERVIEWS = 'Uebersichten'
	, FBIB = 'FBiB'
	, FBIB_DASHBOARD = 'FBiB/Dashboard'
	, FBIB_CASES = 'FBiB/Faelle'
	, FBIB_COMPANIES = 'FBiB/Unternehmen'
	, FBIB_PROVIDERACTIVITIES = 'FBiB/Traegeraktivitaeten'
	, FBIB_JOBPROFILES = 'FBiB/Stellenprofile'
	, FBIB_ASSOCIATEDCONTACTS = 'FBiB/Netzwerkkontakte'
	, FBIB_ASSOCIATEDORGANIZATIONS = 'FBiB/Netzwerkorganisationen'
	, FBIB_TRAININGCENTERS = 'FBiB/Ausbildungsstaetten'
	, FBIB_TRAININGCOURSES = 'FBiB/Ausbildungsgaenge'
	, FBIB_STATISTICS = 'FBiB/Statistik'
	, FBIB_USERPROFILE = 'FBiB/Profil'
};

export const ROUTES = {
	ERROR: { label: 'Keine Berechtigung', icon: ENUM_ICONS.DEACTIVATE, route: ENUM_ROUTES.ERROR },
	JOBPROFILES: { label: 'Stellenprofile', icon: ENUM_ICONS.JOBPROFILE, route: ENUM_ROUTES.JOBPROFILES, service: ENUM_SERVICES.JOBPROFILES, labelNew: '' },
	STATISTICS: { label: 'Statistik', icon: ENUM_ICONS.STATISTICS, route: ENUM_ROUTES.STATISTICS },
	PROVIDERACTIVITIES: { label: 'Trägeraktivitäten', awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, icon: ENUM_ICONS.PROVIDERACTIVITIES, route: ENUM_ROUTES.PROVIDERACTIVITIES, service: 'provideractivities' },
	COMPANIES: { label: 'Unternehmen', icon: ENUM_ICONS.COMPANY, route: ENUM_ROUTES.COMPANIES, service: ENUM_SERVICES.COMPANIES },
	EAACASES: { label: 'Fälle', icon: ENUM_ICONS.EAACASE, route: ENUM_ROUTES.EAACASES, service: ENUM_SERVICES.EAACASES, labelNew: 'Fall anlegen' },
	DASHBOARD: { label: 'Dashboard', icon: ENUM_ICONS.DASHBOARD, route: ENUM_ROUTES.DASHBOARD },
	ADDRESSES: { label: 'Adressen', icon: '', route: ENUM_ROUTES.ADDRESSES },
	USERADMINISTRATION: { label: 'Nutzer*innen', awesomeIcon: ENUM_AWESOMEICON.USERADMINISTRATION, icon: ENUM_ICONS.USERADMINISTRATION, route: ENUM_ROUTES.USERADMINISTRATION },
	SETTINGS: { label: 'Stammdaten', awesomeIcon: ENUM_AWESOMEICON.SETTINGS, icon: ENUM_ICONS.SETTINGS, route: ENUM_ROUTES.SETTINGS },
	USERPROFILE: { label: 'Mein Profil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.USERPROFILE },
	REQUESTDISTRIBUTION: { label: 'Aufgabenverteilung', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.REQUESTDISTRIBUTION, service: ENUM_SERVICES.REQUESTDISTRIBUTIONS },
	REQUESTDISTRIBUTIONARCHIVE: { label: 'Aufgabenverteilung Archiv', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.REQUESTDISTRIBUTIONARCHIVE, service: ENUM_SERVICES.REQUESTDISTRIBUTIONS },
	REQUESTDISTRIBUTIONUSERRELATIONS: { label: 'Aufgabenverteilung Verwaltung Bearbeiter*innen', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.REQUESTDISTRIBUTIONUSERRELATIONS },
	ASSOCIATEDCONTACTS: { label: 'Netzwerkkontakte', awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, icon: ENUM_ICONS.ASSOCIATEDCONTACTS, route: ENUM_ROUTES.ASSOCIATEDCONTACTS, service: ENUM_SERVICES.ASSOCIATEDCONTACTS },
	ASSOCIATEDORGANIZATIONS: { label: 'Netzwerkkontakte', awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, icon: ENUM_ICONS.ASSOCIATEDCONTACTS, route: ENUM_ROUTES.ASSOCIATEDORGANIZATIONS, service: ENUM_SERVICES.ASSOCIATEDORGANIZATIONS },
	OVERVIEWS: { label: 'Übersichten', awesomeIcon: ENUM_AWESOMEICON.OVERVIEWS, route: ENUM_ROUTES.OVERVIEWS },

	FBIB_DASHBOARD: { label: 'Dashboard', icon: ENUM_ICONS.DASHBOARD, route: ENUM_ROUTES.FBIB_DASHBOARD },
	FBIB_CASES: { label: 'FBiB Fälle', icon: ENUM_ICONS.EAACASE, route: ENUM_ROUTES.FBIB_CASES, service: ENUM_SERVICES.FBIBCASES },
	FBIB_COMPANIES: { label: 'Unternehmen', icon: ENUM_ICONS.COMPANY, route: ENUM_ROUTES.FBIB_COMPANIES, service: ENUM_SERVICES.COMPANIES },
	FBIB_PROVIDERACTIVITIES: { label: 'FBiB Trägeraktivitäten', awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, icon: ENUM_ICONS.PROVIDERACTIVITIES, route: ENUM_ROUTES.FBIB_PROVIDERACTIVITIES, service: 'fbib-provideractivities' },
	FBIB_ASSOCIATEDCONTACTS: { label: 'Netzwerkkontakte', awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, icon: ENUM_ICONS.ASSOCIATEDCONTACTS, route: ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS, service: ENUM_SERVICES.ASSOCIATEDCONTACTS },
	FBIB_ASSOCIATEDORGANIZATIONS: { label: 'Netzwerkkontakte', awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, icon: ENUM_ICONS.ASSOCIATEDCONTACTS, route: ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS, service: ENUM_SERVICES.ASSOCIATEDORGANIZATIONS },
	FBIB_JOBPROFILES: { label: 'Stellenprofile', icon: ENUM_ICONS.JOBPROFILE, route: ENUM_ROUTES.FBIB_JOBPROFILES, service: ENUM_SERVICES.FBIBJOBPROFILES, labelNew: '' },
	FBIB_TRAININGCENTERS: { label: 'Ausbildungsstätten', awesomeIcon: ENUM_AWESOMEICON.TRAININGCENTERS, route: ENUM_ROUTES.FBIB_TRAININGCENTERS, service: ENUM_SERVICES.TRAININGCENTERS },
	FBIB_TRAININGCOURSES: { label: 'Ausbildungsgänge', awesomeIcon: ENUM_AWESOMEICON.TRAININGCOURSES, route: ENUM_ROUTES.FBIB_TRAININGCOURSES, service: ENUM_SERVICES.TRAININGCOURSE },
	FBIB_STATISTICS: { label: 'FBiB Statistik', icon: ENUM_ICONS.STATISTICS, route: ENUM_ROUTES.FBIB_STATISTICS },
	FBIB_USERPROFILE: { label: 'Mein Profil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.FBIB_USERPROFILE },
}