import { Card } from "primereact/card"
import { useEffect, useState } from "react";
import { CaseRequestForm, emptyCaseRequest, getEmptyCaseRequest, validateCaseRequest } from "./CaseRequestForm";
import { deleteData, getData, patchData } from "../feathers";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { useCurrentUser, useCurrentUserIsReaderEAA } from "../Persistence/CurrentUserContext";
import { ENUM_ICONS } from "../Components/Icons";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { format } from "date-fns";
import { getSortedList } from "../snippets";
import { useFormatDate } from "../Persistence/ConfigurationsContext";

export const CaseRequestSheet = ({ eaaCase, updateEaaCase, readonly }) => {
	const formatDate = useFormatDate();
	const isReader = useCurrentUserIsReaderEAA();
	const currentUser = useCurrentUser();
	const [displayCaseRequestDialog, setDisplayCaseRequestDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [caseRequests, setCaseRequests] = useState([]);
	const [sortRequestsAsc, setSortRequetsAsc] = useState(true);

	useEffect(() => {
		if (eaaCase) {
			setCaseRequests(eaaCase.caseRequests)
		}
	}, [eaaCase])

	const editCaseRequest = async (id) => {
		await getData(ENUM_SERVICES.CASEREQUESTS, id).then(caseRequest => {
			caseRequest.requestDate = caseRequest.requestDate ? new Date(caseRequest.requestDate) : null;
			caseRequest.completionDate = caseRequest.completionDate ? new Date(caseRequest.completionDate) : null
			formikCaseRequest.setValues(caseRequest);
			setDisplayCaseRequestDialog(true)
		})

	}

	const patchCaseRequest = async () => {
		await patchData(ENUM_SERVICES.CASEREQUESTS, formikCaseRequest.values).then(async (caseRequest) => {
			await updateEaaCase()
			setDisplayCaseRequestDialog(false)
		})
	}

	const deleteCaseRequest = async () => {
		await deleteData(ENUM_SERVICES.CASEREQUESTS, formikCaseRequest.values.id).then(async (caseRequest) => {
			await updateEaaCase()
			setDisplayConfirmDialog(false)
		})
	}

	const formikCaseRequest = useFormik({
		initialValues: emptyCaseRequest,
		validate: validateCaseRequest,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Anträge</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>Anträge</div>
				<div className={(isReader || readonly) ? 'hidden' : 'flex justify-content-end mb-2 gap-2 cursor-pointer'} onClick={() => { setSortRequetsAsc(!sortRequestsAsc) }} ><i className="pi pi-sort-alt" /><span>{(!sortRequestsAsc ? 'Aufsteigend' : 'Absteigend') + ' nach Datum'}</span></div>
				<div className={(isReader || readonly) ? 'hidden' : 'flex gap-2'}>
					<div className='flex w-1rem' onClick={() => { formikCaseRequest.setValues(getEmptyCaseRequest(eaaCase.id, currentUser.id)); setDisplayCaseRequestDialog(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	return (
		<>
			<ConfirmDialog title='Antrag löschen' message={`<span>Sind Sie sicher, Antrag <br/><b>${formikCaseRequest.values.name}</b><br/>zu löschen?</span>`} labelOk='Ja'
				handleOnClick={deleteCaseRequest} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<EditDialog
				form={<CaseRequestForm formik={formikCaseRequest} />}
				formik={formikCaseRequest}
				patchForm={patchCaseRequest}
				header={formikCaseRequest.values.id ? 'Antrag ändern' : 'Antrag neu anlegen'}
				message={formikCaseRequest.values.id ? 'Hier können Sie den Antrag ' + formikCaseRequest.values.name + ' ändern.' : 'Hier können Sie einen neuen Antrag zum Fall erstellen'}
				setDisplayDialog={setDisplayCaseRequestDialog}
				displayDialog={displayCaseRequestDialog}
			/>

			<Card className="caserequestsheet card-sheet" header={renderHeader}>
				{!caseRequests || caseRequests.length === 0 ? 'keine Anträge vorhanden' :
					getSortedList(caseRequests, 'requestDate', sortRequestsAsc).map((caseRequest) => {
						const status = caseRequest.status//configurationsList.CaseRequestStatusList.find((e) => e.alias === caseRequest.status)
						return <div key={'caseRequest_' + caseRequest.id}>
							<div className='flex justify-content-between border-bottom-1 mt-4 mb-2 grid'>
								<div className='col-10 pl-0 font-medium '>{'AN' + caseRequest.id.toString().padStart(5, "0") + ' - ' + caseRequest.name}</div>
								<div className={(isReader || readonly) ? 'hidden' : 'col-2 gap-2 flex justify-content-end'}>
									<div className='flex' onClick={() => { editCaseRequest(caseRequest.id) }}><i className={ENUM_ICONS.PENCIL} /></div>
									<div className='flex' onClick={() => { formikCaseRequest.setValues(caseRequest); setDisplayConfirmDialog(true) }}><i className={ENUM_ICONS.TRASH} /></div>
								</div>
							</div>
							<div className="grid row">
								<div className="col-fixed">Status</div>
								<div className="col">{status.display}</div>
							</div>
							<div className="grid row">
								<div className="col-fixed">Art</div>
								<div className="col">{caseRequest.caseRequestType.name}</div>
							</div>
							<div className="grid row">
								<div className="col-fixed">Datum</div>
								<div className="col">{formatDate(caseRequest.requestDate)}</div>
							</div>
							<div className="grid row">
								<div className="col-fixed">Erstellt von</div>
								<div className="col">{caseRequest.creator.displayname}</div>
							</div>
							{status.alias === 'INPROGRESS' ? '' : <div className="grid row">
								<div className="col-fixed">{status.alias === 'RETRACTED' ? 'Datum der Rücknahme' : 'Datum des Bescheids'}</div>
								<div className="col">{!caseRequest.completionDate ? 'offen' : formatDate(caseRequest.completionDate)}</div>
							</div>}
							<div className="grid row mt-2 ">
								<div className="col" style={{ lineBreak: 'anywhere' }}>{caseRequest.description}</div>
							</div>
						</div>
					})

				}
			</Card>
		</>
	)

}