import { useRef } from 'react';
import { Menu } from 'primereact/menu';

export const OverlayPanelMenu = ({ items }) => {
	const menu = useRef(null);

	return (<>
		<Menu model={items} popup ref={menu} id="popup_menu" />
		<div className='flex w-full justify-content-center ellipsis-icon cursor-pointer' onClick={(e) => menu.current.toggle(e)}>
			<i className='fa fa-ellipsis-v fa-lg ' aria-controls="popup_menu" aria-haspopup />
		</div>

	</>
	)
}