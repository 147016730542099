import { InputField } from "../Components/InputField"

export const CaseRequestTypeForm = ({ formik }) => {
	return (<>
		<div className="formgrid grid  m-0 p-0">
			<InputField autoFocus={true} formik={formik} id='name' label='Name' type='text' />
		</div>
		<div className="formgrid grid  m-0 p-0">
			<InputField formik={formik} id='listentry' label='Listeneintrag' type='text' />
		</div>
		<div className="formgrid grid  m-0 p-0">
			<InputField formik={formik} id='description' label='Beschreibung' type='text' />
		</div>
	</>
	)
}
export const getEmptyCaseRequestType = () => { return { id: null, active: 1, version: 1, name: '', desciption: '', listentry: '' } }
export const validateCaseRequestType = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	return errors;
}
