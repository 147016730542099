import { Multiselect } from "../Components/FormElements/Multiselect";
import { findData } from "../feathers";
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { useTaskareasList, useTaskareasSelectionList } from "../Persistence/TaskareasContext";
import { logDefault } from "../Utils/logger";
import { useState, useEffect } from 'react'

const CLASSNAME = 'RequestDistributionRegionAndTaskareaForm';
export const RequestDistributionRegionAndTaskareaForm = ({ formik, setPossibleUsers }) => {
	const regionsListSelection = useRegionsSelectionList();
	const regionsListAll = useRegionsList();
	const taskareaListSelection = useTaskareasSelectionList();
	const taskareaListAll = useTaskareasList();
	const [message, setMessage] = useState();

	const [regionOptions, setRegionOptions] = useState(regionsListAll)
	const [taskareaOptions, setTaskareaOptions] = useState(taskareaListAll)

	useEffect(() => {
		if (regionsListAll && regionsListSelection) {
			const regionOptionsList = JSON.parse(JSON.stringify(regionsListSelection));
			const missingEntries = []
			formik.values.regions.forEach(entry => {
				const listEntry = regionsListAll.find(e => e.id === entry.id);
				if (!regionOptionsList.find(e => e.id === entry.id)) {
					missingEntries.push(listEntry)
				}
			})
			setRegionOptions(regionOptionsList.concat(missingEntries))
			logDefault(CLASSNAME, 'info', 'regionOptionsList:' + regionOptionsList.length);
		}
	}, [regionsListSelection, regionsListAll, formik.values.regions])

	useEffect(() => {
		if (taskareaListAll && taskareaListSelection) {
			const optionsList = JSON.parse(JSON.stringify(taskareaListSelection));
			formik.values.taskareas.forEach(entry => {
				const listEntry = taskareaListAll.find(e => e.id === entry.id);
				if (!optionsList.find(e => e.id === entry.id)) {
					optionsList.push(listEntry);
				}
			})
			setTaskareaOptions(optionsList)
			logDefault(CLASSNAME, 'info', 'taskareaoptionsList:' + optionsList.length);
		}
	}, [taskareaListSelection, taskareaListAll, formik.values.taskareas])

	const getPossibleUsers = async () => {
		const { regions, taskareas } = formik.values;
		if (taskareas.length === 0 && regions.length === 0) {
			setPossibleUsers([]);
			setMessage('Keine Regionen und Aufgabenbereiche zugeordnet');
			return;
		}
		const conditions = taskareas.length > 0 ? [{ '$taskareas.id$': { $in: taskareas.map((t => t.id)) } }] : [];
		if (regions.length > 0) {
			conditions.push({ '$regions.id$': { $in: regions.map((r => r.id)) } })
		}
		const queryUsers = conditions.length > 0 ? { $and: conditions } : {}
		const messageText = await findData('users', queryUsers).then((users) => {
			logDefault(CLASSNAME, 'info', 'users: ' + users.length, queryUsers)
			setPossibleUsers(users)
			return users.length !== 1 ? `<p>Es wurden <b>${users.length} Berater*innen</b> gefunden.</p>` : `<p>Diese Zuordnung trifft auf <b>${users[0].firstname + ' ' + users[0].lastname}</b> zu.</p>`
		})
		setMessage(messageText);
	}

	useEffect(() => {
		getPossibleUsers()
	}, [formik.values.regions, formik.values.taskareas])

	return (<>
		<div className="formgrid grid">
			<Multiselect id='regions' options={regionOptions} filter={true} editmode={true} placeholder="Regionen wählen" itemLabel="name" showClear={false} label='Regionen' formik={formik} display='chip' />
		</div>
		<div className="formgrid grid">
			<Multiselect id='taskareas' options={taskareaOptions} filter={true} editmode={true} placeholder="Aufgabenbereiche wählen" itemLabel="name" showClear={false} label='Aufgabenbereiche' formik={formik} display='chip' />
		</div>
		<div className="formgrid grid">
			<div className="field col mr-3" style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: message }} />
		</div>
	</>)
}

export const emptyRegionsAndTaskareas = { id: null, active: 1, version: 1, regions: [], taskareas: [] }
export const validateRegionsAndTaskareas = (data) => {
	let errors = {};

	return errors;
}