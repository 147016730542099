import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FieldInfo } from "../../Components/FieldInfo";
import { OverlayPanelMenu } from "../../Components/OverlayPanelMenu";
import { useCurrentUser, useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext";
import { ReminderDialog } from "../../Reminders/ReminderDialog";
import { FBiBJobOpeningForm, emptyFBiBJobOpening, validateFBiBJobOpening } from "./FBiBJobOpeningForm";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { Tag } from "primereact/tag";
import { ENUM_ROUTES } from "../../Navigation/Routes";
import { useConfigurationsList, useFormatDate } from "../../Persistence/ConfigurationsContext";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../../Components/Icons";
import { CompanySheet } from "../../Companies/CompanySheet";
import exportToPDF from "../../Utils/exportToPDF";
import { ToastError } from "../../Utils/ToastError";
import { CommentsModule } from "../../Components/CommentsModule";
import { BasicSheetLayout } from "../../Components/Layouts/BasicSheetLayout";
import { useErrorUpdate } from "../../Persistence/ErrorContext";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { TRAININGCOURSE_SERVICECONFIG, TrainingCourseSheet } from "../../FBiB/TrainingCourses/TrainingCourseSheet";
import { FBiBJobOpeningSheet } from "./FBiBJobOpeningSheet";
import { getFBiBJobOpening, patchFBiBJobOpening, toggleFBiBJobOpeningStatus } from "./FBiBJobOpeningManager";
import { useFormik } from "formik";
import { copyObject } from "../../snippets";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { FBiBJobOpeningCaseSheet } from "./FBiBJobOpeningCaseSheet";

const CLASSNAME = 'FBiBJobOpeningData';
const SERVICENAME = ENUM_SERVICES.FBIBJOBPROFILES;
export const FBiBJobOpeningData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate()
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB();
	const configurationsList = useConfigurationsList();
	const { jobOpeningId } = useParams();
	const [jobOpening, setJobOpening] = useState();
	const [readonly, setReadonly] = useState(true);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.FBIB !== '1') {
			errorUpdate('Modul Stellenprofile', 'Das Modul Stellenprofile ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	}, [configurationsList, errorUpdate, navigate])

	useEffect(() => {
		if (jobOpeningId) {
			getFBiBJobOpening(jobOpeningId, isReader, setJobOpening, setReadonly, setToastParams, configurationsList)
		}
	}, [jobOpeningId])

	const patchJobOpening = async () => {
		await patchFBiBJobOpening(formik.values, currentUser, isReader, setJobOpening, setReadonly, setToastParams, configurationsList)
	}

	const handleExportJobOpening = () => {
		exportToPDF('JOBOPENING', jobOpening, null, configurationsList.eaaModules);
	}

	const handleGetJobOpening = () => {
		getFBiBJobOpening(jobOpeningId, isReader, setJobOpening, setReadonly, setToastParams, configurationsList)
	}

	const handleEditJobOpening = () => {
		formik.setValues({ ...copyObject(jobOpening), openingDate: new Date(jobOpening.openingDate), postcodeAndCity: jobOpening.postcode + ' | ' + jobOpening.city })
		setDisplayDialog(true);
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const formik = useFormik({
		initialValues: copyObject(emptyFBiBJobOpening),
		validate: validateFBiBJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const items = [
		{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: handleEditJobOpening, disabled: readonly },
		{ label: 'Exportieren', icon: ENUM_ICONS.EXPORT, command: handleExportJobOpening },
		{ separator: true },
		{
			label: (readonly ? 'Stelle wieder öffnen' : 'Stelle schliessen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.DEACTIVATE), command: () => { setDisplayConfirmDialog(true) }, disabled: isReader || (jobOpening && jobOpening.fbibCaseId !== null)
		}
	]

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Stellenprofil' name={jobOpening.company.name} serviceId={jobOpening.id} serviceName={ENUM_SERVICES.FBIBJOBPROFILES} userId={currentUser.id} />
			<EditDialog
				form={<FBiBJobOpeningForm formik={formik} key='jobopening-form' />}
				formik={formik}
				patchForm={patchJobOpening}
				header={'Stellenprofil bearbeiten'}
				message={'Hier können sie das Stellenprofil bearbeiten'}
				setDisplayDialog={setDisplayDialog}
				displayDialog={displayDialog}
			/>
			<ConfirmDialog title={'Stellenprofil ' + (readonly ? 'reaktivieren' : 'inaktivieren')} message={`Sind Sie sicher, das Stellenprofil ${(readonly ? 'wieder zu öffnen' : 'zu schließen')}`} labelOk='Ja'
				handleOnClick={async () => await toggleFBiBJobOpeningStatus(jobOpening, currentUser, isReader, setJobOpening, setReadonly, setToastParams)} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

		</>
	}

	const renderContent = () => {
		const status = configurationsList.FBiBJobOpeningStatusList.find(e => e.alias === jobOpening.status)
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				{!jobOpening ? '' : <div className='mx-auto eaa-maincontent flex flex-column'>
					<div className="flex flex-column">
						<div className='flex justify-content-end mb-4 gap-2'>
							<Button onClick={handleEditJobOpening} disabled={isReader || readonly} icon={ENUM_ICONS.PENCIL} className='flex text-white ' style={{ backgroundColor: '#494C4F' }} label='Bearbeiten' />
							<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} disabled={isReader} className='flex text-white color-background-lessblack' label='Wiedervorlage' />
						</div>
						<div className='flex grid'>
							<div className='col flex flex-column text-4xl '>
								<div className='flex text-center justify-content-center font-semibold'>
									{jobOpening.description}
								</div>
								<div className='flex text-lg justify-content-center my-2 gap-2'>
									<div>{jobOpening ? `${jobOpening.displayId} erstellt am ${formatDate(parseISO(jobOpening.createdAt))} ${(jobOpening.creator ? (' von ' + jobOpening.creator.displayname) : '')}` : ''}</div>
									<Tag key='tagStatus' className={'mr-2 text-white color-tag-' + status.color} rounded value={status.display} />
								</div>
							</div>
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo label='Unternehmen  ' content={jobOpening.company.name} className='col-4' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.COMPANY} size={ENUM_AWESOMEICON_SIZE.X1} />} />
							<FieldInfo label='Fall' content={jobOpening.fbibCase ? jobOpening.fbibCase.name : 'keinem Fall zugeordnet'} className='col-4' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.EAACASE} size={ENUM_AWESOMEICON_SIZE.X1} />} />
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo label='Startdatum' content={jobOpening.openingDate ? formatDate(jobOpening.openingDate) : ''} className='col-3' />
							<FieldInfo label='Typ' content={configurationsList.FBiBJobOpeningTypeList.find(e => e.alias === jobOpening.jobType).display} className='col-3' />
							{!jobOpening.trainingCourse ? '' : <FieldInfo label='Ausbildungsgang' content={jobOpening.trainingCourse.name} icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TRAININGCOURSES} size={ENUM_AWESOMEICON_SIZE.X1} />} className='col-4' />}
						</div>
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Erläuterungen zur Stelle
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: jobOpening.explanation }} />
					</div>

					<div className="flex flex-column mt-4 mr-3">
						<CommentsModule
							onUpdateComment={handleGetJobOpening}
							key='jobopeningCommentsModule'
							servicename={ENUM_SERVICES.FBIBJOBPROFILECOMMENTS}
							readonly={readonly}
							parentId={jobOpening.id}
							idFieldname='jobOpeningId'
							reminderParentService={ENUM_SERVICES.FBIBJOBPROFILES}
							comments={jobOpening.comments} />
					</div>

				</div>}
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, icon: ENUM_ICONS.JOBPROFILE + "fa-lg", component: <FBiBJobOpeningSheet jobOpening={jobOpening} /> },
			{
				index: 1, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg",
				component: <CompanySheet
					readonly={readonly}
					company={jobOpening.company}
					onUpdateCompany={handleGetJobOpening} />
			},
			{
				index: 2, visible: false, awesomeIcon: ENUM_AWESOMEICON.TRAININGCOURSES,
				component: <div className="flex flex-column row-gap-4">
					<TrainingCourseSheet
						serviceConfig={TRAININGCOURSE_SERVICECONFIG.FBIBJOBOPENING}
						readonly={readonly}
						parentId={jobOpening.id}
						trainingCoursesList={jobOpening.courses}
						updateParent={handleGetJobOpening}
					/>
				</div>
			},
			{
				index: 3, visible: true, icon: ENUM_ICONS.EAACASE + "fa-lg",
				component: <FBiBJobOpeningCaseSheet
					jobOpening={jobOpening} />
			},
		]
		return sideSheets;
	}

	return (!jobOpening ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!jobOpening ? '' : renderContent()}{!jobOpening ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={jobOpening.id}
		/>
	)

}