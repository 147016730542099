import { useTranslation } from "react-i18next";
import { Icon } from "../Components/Icons";
import { useBreadcrumbs } from "../Persistence/Breadcrumbs";
import { useEffect, useState } from "react";

const navitemClassName = 'p-ripple flex flex-row  gap-2 align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors'

export const NavigationEntry = ({ icon, route, label, translation, onSidebarClick, showLabel, awesomeIcon, badge, tabindex }) => {
	const breadcrumbs = useBreadcrumbs();
	const { t } = useTranslation(['sidebar']);


	const renderEntry = () => {
		const translationLabel = translation ? t(`sidebar.${translation}.label`) : label
		const translationAriaLabel = translation ? t(`sidebar.${translation}.aria-label`) : label
		const className = " flex mr-2 lg:mr-0 lg:text-2xl w-2rem justify-content-center p-overlay-badge navigation-entry " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === icon ? ' navigation-entry-active' : 'text-base');
		return <div aria-label={translation ? translationAriaLabel : label} tabIndex={tabindex || 0}>
			<a className={navitemClassName} onClick={() => (onSidebarClick(route, icon, translation ? translationLabel : label))}>
				{awesomeIcon ? <Icon ENUM_AWESOMEICON={awesomeIcon} className={className} /> : <i className={icon + className} >{badge}</i>}
				<div className={(showLabel ? 'flex inline' : 'hidden') + " font-semibold " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === icon ? 'navigation-entry-active' : 'text-base')}>{translation ? translationLabel : label}</div>
			</a>
		</div>
	}

	return (
		<>
			<li>
				{renderEntry()}
			</li>
		</>)
}