import { format } from "date-fns";
import { Column } from 'primereact/column';
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Badge } from 'primereact/badge';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useConfigurationsList, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useDataViewFilter, useDataViewFilterUpdate, useDataViewMounted, useDataViewPagination, useDataViewPaginationUpdate, useDataViewSort, useDataViewSortUpdate } from "../Persistence/DataViewFilterContext";
import { useRegionsList } from "../Persistence/RegionsContext";
import { useTaskareasList } from "../Persistence/TaskareasContext";
import { logDefault } from "../Utils/logger";
import { DateFilterElement } from "./FilterTemplates/DateFilterElement";
import { FilterTemplateMultiSelect } from "./FilterTemplates/FilterTemplateMultiSelect";
import { MultiSelectFilterElement } from "./FilterTemplates/MultiSelectFilterElement";
import { ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "./Icons";
import { OpenEye } from "./OpenEye";
import { ResponsibleMultiSelect } from "./FilterTemplates/ResponsibleMultiSelect";
import { Button } from "primereact/button";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog";
import { patchData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { InputText } from "primereact/inputtext";
import { useStrgPressed } from "../Persistence/StrgPressed";
import { ENUM_ROUTES } from "../Navigation/Routes";

const CLASSNAME = 'TemplateDataView'
const columnLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'];
export const TemplateDataView = ({ dataTableList, columns, showOpenLinkColumn, DATAVIEW_CONFIG, ROUTE, routeIdField, classNameDataTable, scrollHeight, loadingMessage, dataTableWidth, exportFunction, exportColumns, buttons, onDoubleClick }) => {
	const formatDate = useFormatDate();
	const ctlPressed = useStrgPressed();
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const dataViewMounted = useDataViewMounted();
	const dataViewFilter = useDataViewFilter();
	const setDataViewFilter = useDataViewFilterUpdate();
	const dataViewSort = useDataViewSort();
	const setDataViewSort = useDataViewSortUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const regionsList = useRegionsList();
	const taskareasList = useTaskareasList();
	const currentUser = useCurrentUser();

	const [paginationFirst, setPaginationFirst] = useState(dataViewPagination[DATAVIEW_CONFIG.alias].paginationFirst);
	const [paginationRows, setPaginationRows] = useState(dataViewPagination[DATAVIEW_CONFIG.alias].paginationRows);
	const [currentPage, setCurrentPage] = useState(dataViewPagination[DATAVIEW_CONFIG.alias].currentPage);
	const [selectedRow, setSelectedRow] = useState();
	const [sortFunctions, setSortFunctions] = useState();
	const [multiSortMeta, setMultiSortMeta] = useState(dataViewSort[DATAVIEW_CONFIG.alias]);
	const [dataviewFilters, setDataviewFilters] = useState(dataViewFilter[DATAVIEW_CONFIG.alias]);
	const [filters, setFilters] = useState(dataViewFilter[DATAVIEW_CONFIG.alias]);
	const [filterValues, setFilterValues] = useState();
	const [mounted, setMounted] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [initPagination, setInitPagination] = useState(false);
	const [renderFunctions, setRenderFunctions] = useState();
	const [listFilterElements, setListFilterElements] = useState();
	const [filteredData, setFilteredData] = useState();
	const [showExportButton, setShowExportButton] = useState(false);
	const [filtersActive, setFiltersActive] = useState(false);
	const [displayExportConfirmDialog, setDisplayExportConfirmDialog] = useState(false);
	const cm = useRef(null);
	const dt = useRef(null);

	useEffect(() => {
		if (exportFunction && dt.current && dataLoaded) {
			const keys = Object.getOwnPropertyNames(filters)
			const filter = filters[keys[0]]
			dt.current.filter(filter.value, filter, filter.matchMode)
		}
	}, [dataLoaded])

	useEffect(() => {
		setFilters(dataViewFilter[DATAVIEW_CONFIG.alias])
	}, [dataViewMounted])

	useEffect(() => {
		if (dataLoaded) {
			setFilteredData(dataTableList)
		}
	}, [dataLoaded, dataTableList])

	useEffect(() => {
		if (dataTableList && dt.current) {
			if (exportFunction && configurationsList) {
				const EXPORTROLES_CONFIG = configurationsList.eaaModules.OVERVIEWS_TEST[DATAVIEW_CONFIG.alias] || configurationsList.eaaModules.OVERVIEWS[DATAVIEW_CONFIG.alias] || { EXPORTROLES: ['NOBODY'] }
				setShowExportButton(EXPORTROLES_CONFIG.EXPORTROLES.includes(currentUser.permissions))
			}
			setDataLoaded(true);
		}
	}, [dataTableList, dt.current, configurationsList])

	useEffect(() => {
		setListFilterElements({ 'listFilter': listFilterElement, 'dateFilter': dateFilterElement, 'singleTagFilter': singleTagFilterElement, 'multiTagFilterElement': multiTagFilterElement, 'responsibleFilterElement': responsibleFilterElement, 'multiSelectFilterElement': multiSelectFilterElement })
		setRenderFunctions({ 'renderDate': renderDate, 'renderList': renderList, 'renderListIcon': renderListIcon, 'renderTag': renderTag })
		setSortFunctions({ 'sortResponsible': sortResponsible })
	}, [])

	useEffect(() => {
		if (!mounted && dataViewMounted && configurationsList && configurationsList.RegionsList && configurationsList.TaskareasList) {
			setPaginationFirst(dataViewPagination[DATAVIEW_CONFIG.alias].paginationFirst);
			setPaginationRows(dataViewPagination[DATAVIEW_CONFIG.alias].paginationRows);
			setCurrentPage(dataViewPagination[DATAVIEW_CONFIG.alias].currentPage);
			setMultiSortMeta(dataViewSort[DATAVIEW_CONFIG.alias]);
			setMounted(true)
		}
	}, [configurationsList, taskareasList, regionsList, dataViewMounted])

	useEffect(() => {
		let filterActive = false
		for (let key of Object.getOwnPropertyNames(filters || {})) {
			if (filters[key].value !== null && ('' + filters[key].value !== '')) {
				filterActive = true
			}
		}
		setFiltersActive(filterActive)
	}, [filters])

	const renderOpenLink = (rowData) => {
		return <OpenEye ROUTE={ROUTE} id={routeIdField ? rowData[routeIdField] : rowData.id} />
	}

	const onChangeFilter = (e) => {
		logDefault(CLASSNAME, 'info', 'onChangeFilter ', e.filters)
		const filters = e.filters;
		setDataViewFilter(DATAVIEW_CONFIG, filters);
		setFilters(filters);
	}
	const onChangeSort = (e) => {
		try {
			setMultiSortMeta(e.multiSortMeta)
			setDataViewSort(DATAVIEW_CONFIG, e.multiSortMeta)
		} catch (error) {
			console.error('Filtererror:', error)
		}
	}
	const onChangePaginator = (event) => {
		if (!initPagination) {
			event.first = DATAVIEW_CONFIG.pagination.paginationFirst
		}
		setInitPagination(true);
		setPaginationFirst(event.first);
		setPaginationRows(event.rows);
		setCurrentPage(event.page);
		logDefault(CLASSNAME, 'info', DATAVIEW_CONFIG.alias + ' onChangePaginator: paginationFirst:' + paginationFirst, event)

		setDataViewPagination(DATAVIEW_CONFIG, { paginationFirst: event.first, paginationRows: event.rows, currentPage: event.page })
	}



	const sortResponsible = (event) => {
		let data = [...dataTableList];
		return data;
	}

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, format(new Date().getTime(), 'yyyy-MM-dd_HH-mm-ss') + '_EAA-DOQ_' + fileName + EXCEL_EXTENSION);
			}
		});
	}
	const generateExport = useCallback(() => {
		patchData(ENUM_SERVICES.DATAEXPORTS, { name: DATAVIEW_CONFIG.alias, permissions: currentUser.permissions, filter: filters, count: filteredData.length })
		const data = filteredData.map(exportFunction)
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(data);
			worksheet['!autofilter'] = { ref: `A1:${columnLetters[exportColumns ? exportColumns - 1 : 6]}1` };
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			saveAsExcelFile(excelBuffer, DATAVIEW_CONFIG.exportFilename);
		});
	}, [filteredData])

	const removeAllFilter = () => {
		const emptyFilter = {}
		for (let key of Object.getOwnPropertyNames(filters)) {
			const filter = filters[key]
			filter.value = null
			emptyFilter[key] = filter
		}
		setDataViewFilter(DATAVIEW_CONFIG.filtername, emptyFilter);
		setFilters(emptyFilter)
	}

	const menuModel = [
		{ label: 'Öffnen', icon: ENUM_ICONS.EYE, command: () => addBreadcrumb(``, `${ROUTE}/` + selectedRow.id) },
		{ label: 'In neuem Tab öffnen', icon: ENUM_ICONS.EYE, command: () => window.open(window.location.href + '/' + selectedRow.id, '_blank') },
	];

	const setFilterValue = (field, value) => {
		const _filterValues = { ...filterValues };
		_filterValues[field] = value;
		setFilterValues(_filterValues);
	}

	const listValueTemplate = (option, placeholder) => {
		return <div style={{ minHeight: '21px' }}>{option && option.icon ? <i className={option.icon + ' w-2rem '} /> : (option && option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : ' ')}{option && option.onlyIcon ? ' ' : (option ? option.datatable : placeholder)}</div>
		//return <div style={{ minHeight: '21px' }}>{option && option.icon ? <i className={option.icon + ' px-1 w-2rem '} /> : ' '}{option && option.onlyIcon ? ' ' : (option ? option.datatable : placeholder)}</div>
	}

	const listItemTemplate = (option) => {
		return <div className='flex w-full  align-items-center'>{option.icon ? <i className={option.icon + ' pr-1 w-2rem'} /> : (option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : '')}{option.datatable}</div>
	}

	const textFilterElement = (options) => {
		if (!options.value) {
			options.value = ''
		}
		return <InputText
			className={options.value ? 'eaa-filter-active' : ''}
			value={options.value}
			placeholder={options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null}
			onChange={(e) => { options.filterApplyCallback(e.target.value) }} />
	}

	const listFilterElement = useCallback((options) => {
		const listOptions = options.filterModel ? configurationsList[options.filterModel.filterListName] : [];
		const className = options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '';
		const placeholder = options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null;
		logDefault(CLASSNAME, 'info', DATAVIEW_CONFIG.alias + ' listFilterElement placeholdeer' + placeholder + ' options:', options)
		return !listOptions ? null : <Dropdown
			value={options.value}
			className={className + (options.value ? 'eaa-filter-active' : '')}
			panelClassName='p-2'
			optionLabel="datatable"
			optionValue="alias"
			options={listOptions}
			onChange={(e) => { options.filterApplyCallback(e.value) }}
			itemTemplate={listItemTemplate}
			valueTemplate={(option) => listValueTemplate(option, placeholder)}
			placeholder={placeholder} />;

	}, [configurationsList])

	const renderTagFilter = (option) => { return <Tag key={'tag-' + option.id} className={"mr-2 text-white px-3 color-tag-" + option.color} rounded value={option.datatable} /> }
	const singleTagFilterElement = (options) => {
		const listOptions = options.filterModel ? configurationsList[options.filterModel.filterListName] : [];
		const className = options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '';
		const placeholder = options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null;
		logDefault(CLASSNAME, 'info', DATAVIEW_CONFIG.alias + ' singleTagFilterElement listOptions' + className, listOptions)
		return <Dropdown value={options.value} className={className} optionLabel="datatable" optionValue="alias" options={listOptions} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={renderTagFilter} placeholder={placeholder} />;
	}
	const multiTagFilterElement = (options) => {
		return <FilterTemplateMultiSelect options={options} key={'FilterTemplateMultiSelect_' + options.field} />
	}
	const multiSelectFilterElement = (options) => {
		return <MultiSelectFilterElement options={options} key={'MultiSelectFilter_' + options.field} />
	}
	const responsibleFilterElement = (options) => {
		return <ResponsibleMultiSelect value={options.value} onChange={(value) => { options.filterApplyCallback(value) }} />
	}
	const dateFilterElement = (options) => {
		return <DateFilterElement options={options} maxDate={filters[options.field].maxDate ? new Date() : undefined} minDate={filters[options.field].minDate ? new Date() : undefined} />
	}

	const renderTag = (rowData, column) => {
		const filterField = column.column.props.filterField
		const values = Array.isArray(rowData[column.field]) ? rowData[column.field] : [rowData[column.field]]
		const list = configurationsList[dataviewFilters[filterField].filterListName] ? configurationsList[dataviewFilters[filterField].filterListName] : [];
		return values.map(value => {
			const alias = value.constructor === Object ? value.alias : value;
			const option = list.find(entry => entry.alias === alias);
			const style = option && option.backcolor ? { color: '#' + option.color, backgroundColor: '#' + option.backcolor, borderColor: '#' + option.color, borderStyle: 'solid', borderWidth: option.backcolor.toLowerCase() === 'ffffff' ? '1px' : '0px' } : {};
			const className = 'px-3 mx-1' + (option && option.backcolor ? '' : (option ? 'text-white color-tag-' + option.color : ''));
			return <Tag key={'tag_' + rowData.id + '_' + alias} style={style} className={className} rounded value={option ? (option.datatable || option.alias) : '---'} />
		})
	}

	const renderDate = (rowData, column) => { return (rowData[column.field] ? formatDate(rowData[column.field]) : '---') }
	const renderListIcon = (rowData, column) => {
		const filterField = column.column.props.filterField
		const columnConfig = dataviewFilters[filterField]
		const list = configurationsList[columnConfig.filterListName] ? configurationsList[columnConfig.filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias);
		const badge = (entry && entry.functionBadge && entry.functionBadge(rowData)) ? <Badge severity='danger' className='fa-badge' /> : '';
		return entry ? (entry.icon ? <i className={'flex w-full justify-content-center ' + entry.icon} /> : <Icon ENUM_AWESOMEICON={entry.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className={'flex w-full justify-content-center '} badge={badge} />) : rowData[column.field];
	}
	const renderList = (rowData, column) => {
		const filterField = column.column.props.filterField
		const list = configurationsList[dataviewFilters[filterField].filterListName] ? configurationsList[dataviewFilters[filterField].filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias)
		return entry ? entry.datatable : rowData[column.field];
	}

	const getDoubleClickCommand = (e) => {
		if (onDoubleClick) {
			onDoubleClick(e.data)
		} else if (!showOpenLinkColumn) {
			console.log('KEINE AKTION')
		} else {
			const id = routeIdField ? e.data[routeIdField] : e.data.id;
			if (id) {
				if (ctlPressed) {
					const url = window.location.href.replace(ENUM_ROUTES.OVERVIEWS, ROUTE)
					window.open(url + '/' + id, '_blank', { rel: "noopener noreferrer" });
				} else {
					addBreadcrumb(``, `${ROUTE}/` + (routeIdField ? e.data[routeIdField] : e.data.id))
				}
			}
		}
	}

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 },
				{ label: 100, value: 100 },
				{ label: 200, value: 200 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (<div style={{ marginLeft: '20px', color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
				<span >
					{options.first} - {options.last} von {options.totalRecords}
				</span>
				<span style={{ display: 'none', marginLeft: '20px', marginRight: '20px', color: 'var(--text-color)', userSelect: 'none', width: '30px', textAlign: 'center' }}>
					Seite {currentPage + 1}
				</span>
			</div>
			)
		}
	};

	const onValueChanged = (data) => {
		setFilteredData(data)
	}

	return (
		<div className={'flex flex-column overflow-x-auto justify-content-center select-none ' + (classNameDataTable || 'eaa-dataView')} >
			<div className={"flex justify-content-end flex-wrap card-container gap-2 my-2"}>
				<div className={"flex flex-grow-1 mb-2"}>
					<div className={dataTableList === null ? 'hidden' : "flex align-self-center justify-content-left"}>
						<Button disabled={!filtersActive} icon={'pi pi-filter-slash'} label="Alle Filter zurücksetzen" onClick={removeAllFilter} />
					</div>
				</div>
				<div className={!showExportButton || dataTableList === null ? 'hidden' : "flex align-self-center justify-content-left mb-2"}>
					<Button icon={ENUM_ICONS.EXPORT} label="Als Excel-File exportieren" onClick={() => setDisplayExportConfirmDialog(true)} />
				</div>
				{(buttons || []).map((b) => <div className="flex align-self-center justify-content-left mb-2" key={'div-' + b.key}>{b}</div>)}
			</div>
			<div className={loadingMessage && !dataTableList ? "flex justify-content-end flex-wrap card-container" : 'hidden'}>
				{loadingMessage}
			</div>
			<div className={!dataTableList ? 'hidden' : "flex card flex md:inline-flex overflow-x-auto "} style={{ width: dataTableWidth || '1480px' }} >
				<ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRow(null)} />
				<ConfirmDialog
					message={`<span>Bitte beachten Sie beim Herunterladen von Daten folgende Punkte:
					<ol>
						<li><b>Exportzeitpunkt und -details:</b> Der Zeitpunkt des Daten-Exports, die Datenmenge und die angewendeten Filter werden zusammen mit Ihrem Nutzer*innen-Zugang dauerhaft und manipulationssicher gespeichert. Im Falle einer unberechtigten Datennutzung oder eines Datenverlustes können diese Informationen für disziplinarische Maßnahmen genutzt werden.</li>
						<li><b>Verantwortungsbewusster Umgang:</b> Gehen Sie verantwortungsbewusst mit den exportierten Daten um. Löschen Sie die exportierten Daten direkt nach ihrer Verarbeitung, um unbefugten Zugriff und unbefugte Weiternutzung zu verhindern.</li>
						<li><b>Abstimmung vor Nutzung:</b> Koordinieren Sie die Nutzung der exportierten Daten im Voraus mit den EAA Koordinator*innen und dokumentieren Sie dies nachvollziehbar. Stellen Sie sicher, dass die Nutzung den geltenden Datenschutzbestimmungen entspricht.</li>
						<li><b>Lokale Datennutzung:</b> Verwenden Sie die exportierten Daten ausschließlich auf Ihrem lokalen Gerät. Löschen Sie die Daten nach der Nutzung.</li>
						<li><b>Keine Weitergabe:</b> Teilen Sie die exportierten Daten niemals mit anderen Benutzern oder Dritten, es sei denn, dies wurde von den EAA Koordinator*innen ausdrücklich genehmigt.</li>
					</ol>Durch die Beachtung dieser Richtlinien tragen Sie dazu bei, die Sicherheit und Integrität der exportierten Daten zu gewährleisten.
					</span>`}
					displayConfirmDialog={displayExportConfirmDialog}
					setDisplayConfirmDialog={setDisplayExportConfirmDialog}
					title='Daten exportieren'
					key='display-export-confirm-dialog'
					style={{ width: '800px' }}
					className={' '}
					handleOnClick={generateExport} />

				{!mounted ? '' :
					<DataTable emptyMessage={!dataTableList && !loadingMessage ? 'Daten werden geladen.' : (loadingMessage ? '' : 'keine Einträge gefunden')}
						scrollHeight={scrollHeight || 'calc(100vh - 220px)'}
						scrollable scrollDirection="both"
						className='w-full template-dataview'
						rowHover
						showGridlines
						size="small"
						responsiveLayout="scroll"
						dataKey="id"
						stripedRows
						value={dataTableList}
						selection={selectedRow}
						removableSort
						onSort={onChangeSort}
						sortMode="multiple"
						multiSortMeta={multiSortMeta}
						filters={filters}
						filterDisplay='row'
						onFilter={onChangeFilter}
						paginator
						paginatorTemplate={paginatorTemplate}
						first={paginationFirst}
						rows={paginationRows}
						onPage={onChangePaginator}
						contextMenuSelection={selectedRow}
						ref={dt}
						onValueChange={onValueChanged}
						onContextMenuSelectionChange={e => setSelectedRow(e.value)}
						onRowDoubleClick={getDoubleClickCommand}
						onContextMenu={e => { if (showOpenLinkColumn) { cm.current.show(e.originalEvent) } }}>

						{/** Tabellenspalten */}
						{columns.map((column) => {
							const filterField = column.filterAlias
							return (<Column
								style={column.style}
								key={DATAVIEW_CONFIG.alias + '-' + (column.key || column.field)}
								className={column.className}
								sortable={column.sortable}
								sortField={column.sortField ? column.sortField : null}
								filter={column.filter}
								filterField={filterField}
								field={column.field}
								filterPlaceholder={dataviewFilters[filterField] ? dataviewFilters[filterField].filterPlaceholder : null}
								filterElement={dataviewFilters[filterField] && dataviewFilters[filterField].filterElement ? listFilterElements[dataviewFilters[filterField].filterElement] : textFilterElement}
								onFilterClear={() => setFilterValue(column.filter, null)}
								body={renderFunctions[column.body] ? renderFunctions[column.body] : column.body}
								header={column.header}
								align={column.align}
								showFilterMenu={false}>
							</Column>)
						})}
						{showOpenLinkColumn ? <Column style={{ width: '40px' }} className='open-icon justify-content-center p-0' body={renderOpenLink}></Column> : null}
					</DataTable>}
			</div>
		</div>
	)
}
