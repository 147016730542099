import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { ENUM_DATAVIEWS_CONFIG } from '../../Enums/ENUM_DATAVIEWS_CONFIG';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { Button } from 'primereact/button';
import { TemplateDataViewPagination } from '../../Components/TemplateDataViewPagination';
import { useConfigurationsList, useDataviewsConfig } from '../../Persistence/ConfigurationsContext';
import { TrainingCenterForm, emptyTrainingCenter, validateTrainingCenter } from './TrainingCenterForm';
import { openTrainingCenter, patchTrainingCenter } from './TrainingCenterManager';
import { useFormik } from 'formik';
import { copyObject } from '../../snippets';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { useBreadcrumbsAdd } from '../../Persistence/Breadcrumbs';

const CLASSNAME = 'TrainingCentersDataview'
const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.TRAININGCENTERS;
export const TrainingCentersDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const addBreadcrumb = useBreadcrumbsAdd();
	const isReader = useCurrentUserIsReaderFBiB();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const [displayTrainingCenterDialog, setDisplayTrainingCenterDialog] = useState(false);
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));

	/** formik Training Center */
	const formikTrainingCenter = useFormik({
		initialValues: copyObject(emptyTrainingCenter),
		validate: validateTrainingCenter,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "id", sortable: true, filter: true, header: "ID", style: { width: '200px' }, body: (rowData) => rowData.displayId },
		{ filterAlias: 'col_02', field: "name", sortable: true, filter: true, filterPlaceholder: "Name suchen", header: "Name der Ausbildungsstätte", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ filterAlias: 'col_03', field: "type", sortable: true, filter: true, header: "Typ", style: { width: '300px' }, body: (rowData) => configurationsList.TrainingCenterTypeList.find(e => e.alias === rowData.type).display },
		{ filterAlias: 'col_04', field: "active", sortable: true, filter: true, header: "Status", style: { width: '150px' }, body: 'renderTag' }

	]

	return <div className='mx-auto eaa-dataView select-none ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
		<div className='' >
			<EditDialog
				displayDialog={displayTrainingCenterDialog}
				formik={formikTrainingCenter}
				labelButtonOk='Anlegen'
				header='Ausbildungsstätte anlegen'
				message='Lege eine neue Ausbildungsstätte an'
				patchForm={async () => {
					await patchTrainingCenter(formikTrainingCenter.values, currentUser).then((trainingCenter) => {
						addBreadcrumb(`${trainingCenter.name}`, `${ENUM_ROUTES.FBIB_TRAININGCENTERS}/` + trainingCenter.id);
					})
				}}
				setDisplayDialog={setDisplayTrainingCenterDialog}
				form={<TrainingCenterForm
					formik={formikTrainingCenter}
					validateForm={async () => await formikTrainingCenter.validateForm()}
					readOnly={false}
				/>}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-trainingCenter'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.FBIB_TRAININGCENTERS}
				buttons={[<Button key={'button-new-trainingcenter'} disabled={isReader} onClick={() => { formikTrainingCenter.setValues(copyObject(emptyTrainingCenter)); setDisplayTrainingCenterDialog(true) }} className='flex  text-white' label='Ausbildungsstätte anlegen' />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	</div>
}