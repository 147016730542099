import React, { useContext, useState, useEffect } from "react";
import { getData, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";

const listenernames = ['created', 'patched'];
const providername = 'RegionsProvider';
const servicename = ENUM_SERVICES.REGIONS;
const RegionsContext = React.createContext();
const RegionsSelectionContext = React.createContext();

export function useRegionsSelectionList() {
	return useContext(RegionsSelectionContext);
}

export function useRegionsList() {
	return useContext(RegionsContext);
}

export function RegionsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	const getListData = () => {
		getData(servicename).then((list) => {
			setList(list);
			const sortedList = getSortedList(list, 'name')
			setListSelection(sortedList.filter((entry) => entry.active))
			logContext(providername, 'info', `########### ${providername} -> getListData found:${list.length}`)
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', `########### ${providername} -> listitemchanged:` + listItemChanged.id)
			getListData();
		}
	}, [listItemChanged])

	useEffect(() => {
		if (mounted) {
			updateServiceListener(servicename, listenernames, (itemChanged) => setListItemChanged(itemChanged));
			logContext(providername, 'info', `########### ${providername} -> init regions`);
			setTimeout(async () => getListData(), 1)
			return () => { logContext(providername, 'info', `########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])


	useEffect(() => {
		setMounted(true)
	}, [])

	return (

		<RegionsContext.Provider value={list}>
			<RegionsSelectionContext.Provider value={listSelection}>
				{children}
			</RegionsSelectionContext.Provider>
		</RegionsContext.Provider>
	)
}