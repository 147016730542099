import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils"
import { useEffect } from 'react'
import { logDefault } from "../../Utils/logger";

const CLASSNAME = 'Multiselect'
export const Multiselect = ({ id, label, editmode, formik, className, itemLabel, itemValue, options, listAll, placeholder, filter, onChange, showClear, tabIndex, display, maxSelectedLabels, filterSelectedLabel }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'Multiselect list id=' + id + " ", formik.values[id], options)
	}, [formik.values[id], options])

	const handleChange = (e) => {
		if (onChange) {
			onChange(e.value)
		}
		formik.handleBlur(e)
		formik.handleChange(e);
	}

	return (
		<div className="field col mr-3">
			<label htmlFor={id} className=''>{label}</label>
			<MultiSelect
				panelClassName='p-2 flex;'
				key={'multiselect-' + id}
				value={formik.values[id]}
				options={options}
				filter={filter}
				id={id}
				tabIndex={tabIndex}
				disabled={!editmode}
				showClear={showClear}
				placeholder={placeholder}
				onChange={handleChange}
				optionLabel={itemLabel}
				optionValue={itemValue}
				display={display}
				resetFilterOnHide={true}
				selectedItemsLabel={(formik.values[id] ? formik.values[id].length : 0) + ' ' + filterSelectedLabel + ' ausgewählt'}
				maxSelectedLabels={maxSelectedLabels || 3}
				className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full eaa-multiselect ' + (className ? className : '')} />
			{getFormErrorMessage(id)}
		</div>
	)

}

