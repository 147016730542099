import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { ENUM_ROUTES } from "../../Navigation/Routes";
import { logDefault } from "../../Utils/logger";
import { deleteData, getData, patchData } from "../../feathers";

const CLASSNAME = 'FBiBCasemanager';
const servicename = ENUM_SERVICES.FBIBCASES;


export const openFBiBCase = async (id: number, addBreadcrumb: Function) => {
	logDefault(CLASSNAME, 'info', 'openFBiBCase id:' + id, null)
	await getData(servicename, id).then((fbibcase) => {
		addBreadcrumb(`${fbibcase.name}`, `${ENUM_ROUTES.FBIB_CASES}/` + id);
	})
}

export const deleteFBiBCase = async (fbibCase: any, minusBreadcrumbs: Function, setToastParams: Function) => {
	await deleteData(servicename, fbibCase.id).then(async () => {
		minusBreadcrumbs();
	}).catch((error) => {
		setToastParams({ title: 'FBiB Fall konnte nicht gelöscht werden', message: error.message })
	})
}

export const toggleFBiBCaseStatus = async (fbibCase: any, currentUser: any, isReader: any, setFBiBCase: Function, setReadonly: Function, setToastParams: Function, configurationsList?: any) => {
	fbibCase.active = fbibCase.active ? 0 : 1;
	return await patchFBiBCase(fbibCase, currentUser, isReader, setFBiBCase, setReadonly, setToastParams, configurationsList)
}
export const patchFBiBCase = async (fbibCase: any, currentUser: any, isReader: any, setFBiBCase: Function, setReadonly: Function, setToastParams: Function, configurationsList?: any) => {

	logDefault(CLASSNAME, 'info', 'fbibCase:', fbibCase)
	return await patchData(servicename, fbibCase, currentUser).then(async (fbibCase) => {
		fbibCase.date = new Date(fbibCase.date)
		if (setFBiBCase) {
			setFBiBCase(fbibCase);
		}
		if (setReadonly) {
			setReadonly(!fbibCase.active || isReader)
		}
		return await getFBiBCase(fbibCase.id, isReader, setFBiBCase, setReadonly, setToastParams, configurationsList);
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'FBiB Fall mit der Nummer "' + fbibCase.id + '" konnte nicht aktualisiert werden', message: error.message })
		} else {
			throw error
		}
	});
}

export const getFBiBCase = async (id: any, isReader: any, setFBiBCase: Function, setReadonly: Function, setToastParams: Function, configurationsList: any) => {
	return await getData(servicename, id).then((fbibCase) => {
		fbibCase.date = new Date(fbibCase.date)
		if (configurationsList) {
			fbibCase.caseEvents = fbibCase.caseEvents.map((caseEvent: any) => {
				caseEvent.eventType = caseEvent.eventType ? configurationsList.FBiBEventTypeList.find((e: any) => e.alias === caseEvent.eventType) : null;
				return caseEvent;
			});
			if (fbibCase.company && fbibCase.company.contactPersons) {
				fbibCase.company.contactPersons = fbibCase.company.contactPersons.map((contactPerson: any) => {
					contactPerson.contactType = configurationsList.ContactTypeList.find((e: any) => e.alias === contactPerson.contactType);
					return contactPerson;
				})
			}
			fbibCase.caseUsers = fbibCase.relatedUsers.map((caseUser: any) => {
				caseUser.roleType = caseUser.roleType ? configurationsList.CaseRoleList.find((e: any) => e.alias === caseUser.roleType) : null;
				return caseUser;
			});
			if (fbibCase.casePerson) {
				fbibCase.casePerson.gender = configurationsList.GenderTypeList.find((e: any) => e.alias === fbibCase.casePerson.gender)
			}
			fbibCase.caseRequests = fbibCase.caseRequests.map((caseRequest: any) => {
				caseRequest.requestDate = new Date(caseRequest.requestDate);
				caseRequest.completionDate = caseRequest.completionDate ? new Date(caseRequest.completionDate) : null;
				return caseRequest;
			})
		}
		if (setFBiBCase) {
			setFBiBCase(fbibCase);
		}
		if (setReadonly) {
			setReadonly(!fbibCase.active || isReader)
		}
		return fbibCase;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'FBiB Fall mit der Nummer "' + id + '" konnte nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}
