import { useState, useEffect } from "react";
import { InputField } from "../Components/InputField"
import { Chips } from 'primereact/chips';
import { ToastError } from "../Utils/ToastError";
import { useRegionTagsList } from "../Persistence/RegionTagsContext";
import { usePostcodesList } from "../Persistence/PostcodesContext";
import { logDefault } from "../Utils/logger";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

const CLASSNAME = 'RegionForm';
export const RegionForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	const regionTagsList = useRegionTagsList();
	const postCodesList = usePostcodesList();
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik test ' + configurationsList.eaaModules.REQUESTDISTRIBUTION)
	}, [formik.values, configurationsList])

	const addTag = (item) => {
		/** Wenn das Tag bereits existiert und einer anderen Region zugewiesen ist -> message und Eintrag löschen */
		item.value = item.value.toLowerCase()
		const existingTag = regionTagsList.find(e => (e.alias === item.value && e.regionId !== formik.values.id))
		if (existingTag && existingTag.regionId) {
			/** Toast */
			setToastParams({ title: 'Abkürzung kann nicht verwendet werden', message: 'Diese Abkürzung wird bereits in der Region ' + existingTag.region.name + ' verwendet!' })
			return false;
		}
	}

	const addPostcode = (item) => {
		/** Wenn die Postleitzahl nicht 5-stellig is -> message und Eintrag löschen */
		item.value = item.value.trim()
		logDefault(CLASSNAME, 'info', 'addPostcode <' + item.value + '>', /^\d{5}$/.test(item.value))
		if (!(/^\d{5}$/.test(item.value))) {
			setToastParams({ title: 'Falsche Postleitzahl', message: 'Postleitzahlen müssen 5-stellig Nummern sein!' })
			return false;
		}
		/** Wenn die Postleitzahl bereits existiert und einer anderen Region zugewiesen ist -> message und Eintrag löschen */
		const existingPostcode = postCodesList.find(e => (e.postcode === item.value && e.regionId !== formik.values.id))
		if (existingPostcode && existingPostcode.regionId) {
			/** Toast */
			setToastParams({ title: 'Postleitzahl kann nicht verwendet werden', message: 'Diese Postleitzahl wird bereits in der Region ' + existingPostcode.region.name + ' verwendet!' })
			return false;
		}
	}

	return (<div className='mr-3'>
		<ToastError toastParams={toastParams} />
		<div className="formgrid grid">
			<InputField formik={formik} id='name' label='Name' type='text' />
		</div>
		<div className={!configurationsList.eaaModules.REQUESTDISTRIBUTION ? 'hidden' : 'formgrid grid'}>
			<div className='field  col  p-fluid'>
				<label htmlFor='tags' className='block'>Abkürzungen der Region</label>
				<Chips onAdd={addTag} allowDuplicate={false} name='tags' value={formik.values.tags} onChange={(e) => { formik.handleBlur(e); formik.handleChange(e); }} />
			</div>
		</div>
		<div className={!configurationsList.eaaModules.REQUESTDISTRIBUTION ? 'hidden' : 'formgrid grid'}>
			<div className='field col p-fluid'>
				<label htmlFor='postcodes' className='block'>Postleitzahlen</label>
				<Chips onAdd={addPostcode} allowDuplicate={false} name='postcodes' value={formik.values.postcodes} onChange={(e) => { formik.handleBlur(e); formik.handleChange(e); }} />
			</div>
		</div>
	</div>
	)
}
export const emptyRegion = { id: null, active: 1, version: 1, name: '', postcodes: [], tags: [] }
export const validateRegion = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	return errors;
}
