import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderEAA } from '../Persistence/CurrentUserContext';
import { JobOpeningForm, emptyJobOpening, validateJobOpening } from './JobOpeningForm';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { EditDialog } from '../Components/Dialogs/EditDialog';


export const JobOpeningDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.JOBOPENINGS));
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const configurationsList = useConfigurationsList();

	const [jobOpening, setJobOpening] = useState();
	const [displayDialog, setDisplayDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();
	const [FBiBMode, setFBiBMode] = useState(false);
	const location = useLocation()

	useEffect(() => {
		setFBiBMode(configurationsList.eaaModules.FBIB === '1' && location.pathname.startsWith('/FBiB/'))
	}, [])

	const navigate = useNavigate();
	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationsList, navigate])

	const newJobOpening = () => {
		formik.setValues(copyObject(emptyJobOpening))
		setDisplayDialog(true)
	}

	const patchJobOpening = async () => {
		const jobOpening = formik.values
		await patchData(ENUM_SERVICES.JOBPROFILES, jobOpening, currentUser).then((jobOpening) => {
			const label = jobOpening.company.name + ' | ' + jobOpening.description;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) : label}`, `${FBiBMode ? ENUM_ROUTES.FBIB_JOBPROFILES : ENUM_ROUTES.JOBPROFILES}/` + jobOpening.id);
		})
	}
	const formik = useFormik({
		initialValues: copyObject(emptyJobOpening),
		validate: validateJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '110px' }, body: null },
		{ filterAlias: 'col_02', field: "company.name", style: { flexGrow: 1, flexBasis: '200px' }, body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ filterAlias: 'col_03', field: "location", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ filterAlias: 'col_04', field: "description", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ filterAlias: 'col_05', field: "jobType", style: { width: '200px' }, body: 'renderList' },
		{ filterAlias: 'col_06', field: "openingDate", style: { width: '180px' }, body: 'renderDate' },
		{ filterAlias: 'col_07', field: "status", style: { width: '210px' }, body: 'renderTag' }
	]

	return (
		<div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				displayDialog={displayDialog}
				formik={formik}
				header={'Stellenprofil anlegen'}
				form={<JobOpeningForm
					formik={formik}
				/>}
				labelButtonOk={'Neu anlegen'}
				patchForm={patchJobOpening}
				setDisplayDialog={setDisplayDialog}
			/>
			<TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-eaacases'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.JOBPROFILES}
				buttons={[<Button
					key='button-new-jobprofile'
					onClick={newJobOpening}
					disabled={isReader}
					className='flex  text-white'
					label='Stellenprofil anlegen' />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>

		</div>
	)

}