
import { useEffect, useState } from "react";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { getData } from "../../feathers";
import { DropdownChangesChecked } from "../../Components/DropdownChangesChecked";
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";

export const emptyTrainingCenterSelect = { trainingCenterId: null }

export const TrainingCenterSelectForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	const [trainingCenterList, setTrainingCenterList] = useState([])

	useEffect(() => {
		getData(ENUM_SERVICES.TRAININGCENTERS).then((list) => {

			const options = list.map((e) => {
				return { id: e.id, display: e.name + ' (' + configurationsList.TrainingCenterTypeList.find((tt) => tt.alias === e.type).display + ')' }
			})
			setTrainingCenterList(options)
		})
	}, [formik.values])

	return <>
		<DropdownChangesChecked
			tabIndex={101}
			label={'Ausbildungsstätte'}
			id={'trainingCenterId'}
			optionValue={'id'}
			itemLabel={'display'}
			formik={formik}
			options={trainingCenterList} />
	</>

}

export const validateTrainingCenterSelect = (data) => {
	let errors = {};

	return errors;
}