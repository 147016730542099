import { Link } from "react-router-dom";
import { ENUM_ICONS } from "../Components/Icons";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

export const RelatedUserCard = ({ relatedUser, onEditHandler, onDeleteHandler, readonly }) => {
	const configurationsList = useConfigurationsList();
	const renderContent = () => {
		const { phonenumber, mobilenumber, email } = relatedUser
		return <div className="flex align-content-end flex-column h-5rem px-2 py-1">
			<div className={phonenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col '>{phonenumber}</div></div>
			<div className={mobilenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col '>{mobilenumber}</div></div>
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col '><a href={`mailto:${email}`}>{email}</a></div></div>
		</div>
	}
	const renderTitle = (relatedUser) => {
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{relatedUser.lastname + ', ' + relatedUser.firstname}
				</div>
				<div>
					{typeof relatedUser.roleType === 'object' && relatedUser.roleType.constructor === Object ? relatedUser.roleType.display : configurationsList.CaseRoleList.find(e => e.alias === relatedUser.roleType).display}
				</div>
			</div>
			<div className={readonly ? 'hidden' : "flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(relatedUser) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(relatedUser) }} />
			</div>
		</div>
	}

	return (<>
		{!relatedUser || relatedUser.active === 0 ? '' :
			<div id='relateduser-card' className="eaa-card relateduser-card shadow-2">
				<div>{renderTitle(relatedUser)}</div>
				{renderContent(relatedUser)}
			</div>
		}
	</>)
}