export enum ENUM_SERVICES {
	ADDRESSES = 'addresses',
	ASSOCIATEDCONTACTS = 'associated-contacts',
	ASSOCIATEDCONTACTCOMMENTS = 'associated-contact-comments',
	ASSOCIATEDORGANIZATIONS = 'associated-organizations',
	ASSOCIATEDORGANIZATIONCOMMENTS = 'associated-organization-comments',
	COMPANIES = 'companies',
	COMPANYCOMMENTS = 'company-comments',
	COMPANYUSERS = 'company-users',
	CONTACTPERSONS = 'contact-persons',
	DATAEXPORTS = 'dataexports',
	EAACASES = 'eaa-cases',
	CASEUSERS = 'case-users',
	CASEEVENTS = 'case-events',
	CASEPERSONS = 'case-persons',
	CASEREQUESTTYPES = 'case-request-types',
	CASEREQUESTS = 'case-requests',
	JOBPROFILES = 'job-openings',
	JOBPROFILECOMMENTS = 'job-opening-comments',
	ORGANIZATIONS = 'organizations',
	PROVIDERACTIVITIES = 'provideractivities',
	PROVIDERACTIVITYUSERS = 'provideractivity-users',
	PROVIDERACTIVITYCOMMENTS = 'provideractivity-comments',
	REQUESTDISTRIBUTIONS = 'request-distributions',
	USERS = 'users',
	USERSETTINGS = 'user-settings',
	REMINDERS = 'reminders',
	UTILS = 'utils',
	TASKAREAS = 'taskareas',
	TASKAREATAGS = 'taskarea-tags',
	REGIONSTAGS = 'region-tags',
	REGIONS = 'regions',
	COMPANYCONTACTPERSONS = 'company-contact-persons',
	TRAININGCENTERS = 'training-center',
	TRAININGCENTERCOMMENTS = 'training-center-comments',
	TRAININGCENTERCONTACTPERSONS = 'trainingcenter-contact-persons',
	TRAININGCOURSE = 'training-course',
	COMPANYCOURSES = 'company-courses',
	FBIBCASEPERSONS = 'fbibcase-persons',
	FBIBCASEPERSONCOURSES = 'fbibcase-person-courses',
	FBIBCASES = 'fbib-cases',
	FBIBCASEUSERS = 'fbibcase-users',
	FBIBCASEEVENTS = 'fbibcase-events',
	FBIBCASEREQUESTS = 'fbibcase-requests',
	FBIBPROVIDERACTIVITIES = 'fbib-provideractivities',
	FBIBPROVIDERACTIVITYUSERS = 'fbib-provideractivity-users',
	FBIBPROVIDERACTIVITYCOMMENTS = 'fbib-provideractivity-comments',
	FBIBJOBPROFILES = 'fbib-jobopenings',
	FBIBJOBPROFILECOMMENTS = 'fbib-jobopening-comments',
	TRAININGCOURSECOMMENTS = 'training-course-comments',
};