import { Card } from "primereact/card"
import { useCallback, useEffect, useState } from "react";
import { ENUM_ICONS } from "../../Components/Icons";
import { useCurrentUser, useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext";
import { TrainingCenterForm, emptyTrainingCenter, validateTrainingCenter } from "./TrainingCenterForm";
import { useFormik } from "formik";
import { copyObject } from "../../snippets";
import { patchTrainingCenter } from "./TrainingCenterManager";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { TrainingCenterSelectForm, emptyTrainingCenterSelect, validateTrainingCenterSelect } from "./TrainingCenterSelectForm";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { ContactPersonsSheet } from "../../ContactPersons/ContactPersonsSheet";
import { CONTACTPERSON_SERVICECONFIGLIST } from "../../ContactPersons/ContactPersonsForm";
import { findData } from "../../feathers";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { TRAININGCOURSE_SERVICECONFIG, TrainingCourseSheet } from "../TrainingCourses/TrainingCourseSheet";

export const TrainingCenterSheet = ({ trainingCenter, updateParentSheet, readonly, hideSpecialFields, onSelectTrainingCenter, trainingCourseSelected, hideTraningCourses }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB();
	const [contactPersons, setContactPerson] = useState([]);
	const [displayTrainingCenterDialog, setDisplayTrainingCenterDialog] = useState(false);
	const [displayTrainingCenterSelectDialog, setDisplayTrainingCenterSelectDialog] = useState(false);
	const [displayTrainingCenterDeleteDialog, setDisplayTrainingCenterDeleteDialog] = useState(false);

	const getContactPersons = useCallback(async () => {
		if (trainingCenter) {
			setContactPerson(await findData(ENUM_SERVICES.CONTACTPERSONS, { trainingCenterId: trainingCenter.id, active: 1 }));
		}
	}, [])


	useEffect(() => {
		formikTrainingCenterSelect.setFieldValue('trainingCenterId', (trainingCenter ? trainingCenter.id : null))
		getContactPersons()
	}, [trainingCenter])


	const onPatchTrainingCenter = async () => {
		await patchTrainingCenter(formik.values, currentUser).then(async (trainingCenter) => {
			setDisplayTrainingCenterDialog(false)
			await updateParentSheet(trainingCenter)
		})
	}

	const onTrainingCenterSelect = async () => {
		onSelectTrainingCenter(formikTrainingCenterSelect.values.trainingCenterId)
	}

	const formik = useFormik({
		initialValues: copyObject(emptyTrainingCenter),
		validate: validateTrainingCenter,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const formikTrainingCenterSelect = useFormik({
		initialValues: copyObject(emptyTrainingCenterSelect),
		validate: validateTrainingCenterSelect,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleEditTrainingCenter = () => {
		formik.setValues(trainingCenter || copyObject(emptyTrainingCenter))
		setDisplayTrainingCenterDialog(true)
	}

	const handleRemoveTrainingCenter = () => {
		setDisplayTrainingCenterDeleteDialog(false);
		onSelectTrainingCenter(null);
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Ausbildungsstätte</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>{trainingCenter ? trainingCenter.name : 'nicht gesetzt'} </div>
				<div className='flex gap-2'>
					<div className={isReader || readonly || !trainingCenter ? 'hidden' : 'flex w-1rem'} onClick={handleEditTrainingCenter}><i className={ENUM_ICONS.PENCIL} /></div>
					<div className={isReader || readonly || !trainingCenter || !trainingCenter.id || !onSelectTrainingCenter ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayTrainingCenterDeleteDialog(true)}><i className={ENUM_ICONS.TRASH} /></div>
					<div className={isReader || readonly || trainingCenter || !onSelectTrainingCenter ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayTrainingCenterSelectDialog(true)}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>

			</div>
		</div>
	}

	return (
		<>
			<EditDialog
				form={<TrainingCenterForm
					formik={formik}
					validateForm={async () => await formik.validateForm()}
					key='trainingcenter-form' />}
				formik={formik}
				patchForm={onPatchTrainingCenter}
				header={'Ausbildungsstätte bearbeiten'}
				message={'Hier können sie die Ausbildungsstätte bearbeiten'}
				setDisplayDialog={setDisplayTrainingCenterDialog}
				displayDialog={displayTrainingCenterDialog}
			/>
			<EditDialog
				form={<TrainingCenterSelectForm formik={formikTrainingCenterSelect} />}
				formik={formikTrainingCenterSelect}
				patchForm={onTrainingCenterSelect}
				header={'Ausbildungsstätte auswählen'}
				message={'Hier können sie die Ausbildungsstätte wählen'}
				labelButtonOk={!trainingCenter ? 'auswählen' : 'ändern'}
				setDisplayDialog={setDisplayTrainingCenterSelectDialog}
				displayDialog={displayTrainingCenterSelectDialog}
			/>
			<ConfirmDialog title='Ausbildungsstätte aus FBiB Fall entfernen' message={`<span>Wollen Sie die Ausbildungsstätte <br/><b>${trainingCenter ? trainingCenter.name : ''}</b><br/>aus dem FBiB Fall<br/>löschen?<span>`} labelOk='Ja'
				handleOnClick={() => handleRemoveTrainingCenter()} displayConfirmDialog={displayTrainingCenterDeleteDialog} setDisplayConfirmDialog={setDisplayTrainingCenterDeleteDialog} />

			<Card className={'trainingcentersheet card-sheet border-none mb-1'} header={renderHeader}>
				{!trainingCenter ? '' : <>
					<div className={trainingCenter.phone && !hideSpecialFields ? 'grid' : 'hidden'}>
						<div className="col-fixed">Telefon</div>
						<div className="col">{trainingCenter.phone}</div>
					</div>
					<div className={trainingCenter.fax ? 'grid' : 'hidden'}>
						<div className="col-fixed">Fax</div>
						<div className="col">{trainingCenter.fax}</div>
					</div>
					<div className={trainingCenter.mobile && !hideSpecialFields ? 'grid' : 'hidden'}>
						<div className="col-fixed">Mobil</div>
						<div className="col">{trainingCenter.mobile}</div>
					</div>
					<div className={trainingCenter.mail && !hideSpecialFields ? 'grid' : 'hidden'}>
						<div className="col-fixed">E-Mail</div>
						<div className="col">{trainingCenter.mail}</div>
					</div>
					<div className="grid">
						<div className="col-fixed">Adresse</div>
						<div className="col" style={{ whiteSpace: 'pre-wrap' }}>{
							[trainingCenter.line1, trainingCenter.line2, trainingCenter.line3, (trainingCenter.postcode + ' ' + trainingCenter.city).trim(), trainingCenter.country].filter(e => e !== '').join('\n')
						}
						</div>
					</div>
				</>}
			</Card>
			{!trainingCenter ? '' : <>
				<ContactPersonsSheet
					parentId={trainingCenter.id}
					contactPersons={contactPersons}
					numCarousel={1}
					readonly={readonly}
					handleUpdateParent={getContactPersons}
					CONTACTPERSON_SERVICECONFIG={CONTACTPERSON_SERVICECONFIGLIST.TRAININGCENTER} />
				{hideTraningCourses ? '' : <TrainingCourseSheet
					serviceConfig={TRAININGCOURSE_SERVICECONFIG.TRAINIGCENTERS}
					readonly={readonly}
					parentId={trainingCenter.id}
					trainingCoursesList={trainingCenter.courses}
					trainingCourseSelected={trainingCourseSelected}
					updateParent={updateParentSheet}
				/>}
			</>
			}
		</>
	)

}