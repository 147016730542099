import React, { useContext, useState, useEffect } from "react";
import { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { useCurrentUser } from "./CurrentUserContext";
import { readOverviewData } from "../Overviews/OverviewManager";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'ProviderActivitiesProvider';
const servicename = 'provideractivities';
const ProviderActivitiesContext = React.createContext();
const ProviderActivitiesInitContext = React.createContext();
const CLASSNAME = 'ProviderActivitiesProvider';

export function useProviderActivitiesList() {
	return useContext(ProviderActivitiesContext);
}
export function useProviderActivitiesInit() {
	return useContext(ProviderActivitiesInitContext);
}

export function ProviderActivitiesProvider({ children }) {
	const currentUser = useCurrentUser();
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	const initProvider = () => {
		if (!mounted) {
			setMounted(true)
		}
	}

	useEffect(() => {
		if (listItemChanged) {
			logContext(CLASSNAME, 'info', 'ListItemChanged', listItemChanged)
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		logContext(CLASSNAME, 'info', '########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => await readOverviewData(ENUM_DATAVIEWS_CONFIG.PROVIDERACTIVITIES, 100, setList, null), 1)
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted, currentUser])

	return (
		<ProviderActivitiesInitContext.Provider value={initProvider}>
			<ProviderActivitiesContext.Provider value={list}>
				{children}
			</ProviderActivitiesContext.Provider>
		</ProviderActivitiesInitContext.Provider>
	)
}