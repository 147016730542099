import { Card } from "primereact/card"
import { useEffect, useState } from "react";
import { CasePersonsProvider } from "../Persistence/CasePersonsContext";
import { CasePersonForm, emptyCasePerson, validateCasePerson } from "./CasePersonForm";
import { patchData } from "../feathers";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { useCurrentUserIsReaderEAA } from "../Persistence/CurrentUserContext";
import { ENUM_ICONS } from "../Components/Icons";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { useFormik } from "formik";
import { copyObject } from "../snippets";

export const CasePersonSheet = ({ eaaCase, patchEaaCase, readonly }) => {
	const isReader = useCurrentUserIsReaderEAA();
	const [displayCasePersonDialog, setDisplayCasePersonDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [casePerson, setCasePerson] = useState();

	useEffect(() => {
		if (eaaCase) {
			setCasePerson(eaaCase.casePerson)
		}
	}, [eaaCase])

	const patchCasePerson = async (casePerson) => {
		await patchData('case-persons', casePerson).then(async (casePerson) => {
			eaaCase.casePersonId = casePerson.id
			await patchEaaCase(eaaCase)
		})
	}

	const editCasePerson = () => {
		formikCasePerson.setValues(casePerson ? copyObject(casePerson) : emptyCasePerson)
		setDisplayCasePersonDialog(true)
	}

	/** formik CasePerson */
	const formikCasePerson = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyCasePerson)),
		validate: validateCasePerson,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});


	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Fallperson</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>Person</div>
				<div className={isReader || readonly ? 'hidden' : 'flex gap-2'}>
					<div className='flex w-1rem' onClick={editCasePerson}><i className={eaaCase.casePerson && eaaCase.casePerson.id ? ENUM_ICONS.PENCIL : ENUM_ICONS.PLUS_CIRCLE} /></div>
					<div className={!casePerson ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayConfirmDialog(true)}><i className={ENUM_ICONS.TRASH} /></div>
				</div>

			</div>
		</div>
	}

	return (
		<>
			<ConfirmDialog title='Person entfernen' message={`Sind Sie sicher,\nPerson ${casePerson ? casePerson.personId : ''}\nvon dem Fall zu entfernen?'`} labelOk='Ja'
				handleOnClick={async () => { eaaCase.casePersonId = null; await patchEaaCase(eaaCase) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<EditDialog
				displayDialog={displayCasePersonDialog}
				form={<CasePersonsProvider><CasePersonForm formik={formikCasePerson} /></CasePersonsProvider>}
				formik={formikCasePerson}
				header='Details zur Fallperson ändern'
				labelButtonOk={eaaCase.casePerson && eaaCase.casePerson.id ? 'Ändern' : 'Neu hinzufügen'}
				message={casePerson && casePerson.personId ? 'Hier können Sie die Details der Person ' + casePerson.personId + ' ändern.' : 'Hier können Sie eine neue Person zum Fall auswählen oder erstellen'}
				patchForm={() => { patchCasePerson(formikCasePerson.values) }}
				setDisplayDialog={setDisplayCasePersonDialog}
			/>
			<Card className="casepersonsheet card-sheet" header={renderHeader}>
				{!casePerson ? 'keine Person zugeordnet' :
					<>
						<div className="grid row">
							<div className="col-fixed">Person ID</div>
							<div className="col">{casePerson.personId}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Grad der Behinderung Merkzeichen</div>
							<div className="col">{casePerson.GdBMark}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Grad der Behinderung</div>
							<div className="col">{casePerson.GdB}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Art der Behinderung</div>
							<div className="col">{casePerson.disablityKind}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Geschlecht</div>
							<div className="col">{casePerson.gender ? casePerson.gender.display : ''}</div>
						</div>
					</>
				}
			</Card>
		</>
	)

}