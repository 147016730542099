import {useState, useLayoutEffect } from "react";

export function useMediaQuery() {;
  const [screenSize, setScreenSize] = useState([window.innerWidth, window.innerHeight])

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);
  
  return [screenSize];
}