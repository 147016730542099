
import { useEffect, useState } from "react";
import { EditorChangesChecked } from '../../Components/EditorChangesChecked';
import { InputChangesChecked } from '../../Components/InputChangesChecked';
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { logDefault } from '../../Utils/logger';
import { DropdownChangesChecked } from "../../Components/DropdownChangesChecked";
import { CalendarChangesChecked } from "../../Components/CalendarChangesChecked";
import { TrainingCourseDropdown } from "../Components/TrainingCourseDropdown";
import { RadioButtonChecked } from "../../Components/FormElements/RadioButtonChecked";

const CLASSNAME = 'FBiBCaseForm'
export const FBiBCaseForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values:', formik.values)
		setMounted(true)
	}, [])



	return (
		<div className=' col' >
			{!formik ? '' :
				<div className="formgrid grid">
					<div className="field col  mb-0">
						{!formik.values.wizardMode ? '' : <>
							<div className="formgrid grid">
								<div className="field col mr-3">
									Für einen FBiB Fall wird entweder ein Unternehmen oder eine Fallperson benötigt
								</div>
							</div>
							<div className="formgrid grid">
								<RadioButtonChecked
									formik={formik}
									id='wizardMode'
									checked={1}
									className='field col-fixed '
									label='Fallperson anlegen'
									key='fbibCase-wizardmode-1' />
								<RadioButtonChecked
									formik={formik}
									id='wizardMode'
									checked={2}
									className='field col-fixed ml-4'
									label='Unternehmen anlegen'
									key='fbibCase-wizardmode-1' />
							</div>
						</>

						}
						<div className="formgrid grid">
							<InputChangesChecked id='name' type="text" formik={formik} label='Kurzbeschreibung / Titel' />
							<TrainingCourseDropdown formik={formik} />
						</div>
						<div className="formgrid grid">
							<CalendarChangesChecked id='date' formik={formik} label='Datum' maxDate={new Date()} />
							<DropdownChangesChecked
								tabIndex={102}
								label={'Status'}
								id={'status'}
								optionValue={'alias'}
								itemLabel={'display'}
								formik={formik}
								className='mr-3 '
								options={configurationsList.FBiBCaseStatusList} />
						</div>

						<div className="formgrid grid">
							{!mounted ? '' : <EditorChangesChecked id='description' value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Erläuterung des FBiB Falls' required={false} />}
						</div>
					</div>
				</div>}
		</div>

	)
}

export const emptyFBiBCase = { id: null, version: 1, active: 1, name: '', description: '', type: '', date: '', userId: null, status: 'OPEN', companyId: null, casePersonId: null, trainingCenterId: null, trainingCourseId: null, wizardMode: false }

export const validateFBiBCase = (data) => {
	let errors = {};

	if (!data.name) {
		errors.name = 'Der Name des FBiB Falls muss gesetzt sein!';
	}
	if (!data.status) {
		errors.status = 'Der Status des FBiB Falls muss gesetzt sein!';
	}
	if (!data.date) {
		errors.date = 'Das Datum des FBiB Falls muss gesetzt sein!';
	}
	return errors;
}