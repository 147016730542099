import { Checkbox } from "primereact/checkbox";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked"
import { InputField } from "../Components/InputField"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

export const OrganizationForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	return (
		<div className="formgrid grid  m-0 p-0">
			<InputField autoFocus={true} formik={formik} id='name' label='Name' type='text' />
			<DropdownChangesChecked editmode={true} formik={formik} id='orgtype' itemLabel='display' label='Typ' options={configurationsList.OrganizationTypesList} key='orgtype' />
			<div className="field col-fixed w-2">
				<label htmlFor='htmlfor_ignoreInStatistics' className='block'>In Statistik</label>
				<div className="field-checkbox pt-1">
					<Checkbox inputId="ignoreInStatistics" name='ignoreInStatistics' checked={formik.values.ignoreInStatistics} onChange={e => { formik.handleBlur(e); formik.setFieldValue('ignoreInStatistics', e.checked) }} />
					<label htmlFor="ignoreInStatistics">ignorieren</label>
				</div>
			</div>
		</div>
	)
}
export const emptyOrganization = { id: null, active: 1, version: 1, name: '', type: '', orgtype: '', ignoreInStatistics: false }
export const validateOrganization = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	if (!data.orgtype) {
		errors.orgtype = 'Typ ist ein Pflichtfeld';
	}
	return errors;
}
