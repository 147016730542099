import { parseISO } from "date-fns"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { InputTextarea } from "primereact/inputtextarea"
import { useEffect, useState } from "react"
import { getData, patchData } from "../feathers"
import logger from "../Utils/logger"
import { useFBiBMode } from "../Persistence/ConfigurationsContext"

export const ReminderDialog = ({ displayDialog, setDisplayDialog, header, name, serviceName, serviceId, userId, reminder, onEditReminder }) => {

	const fbibMode = useFBiBMode();
	const [date, setDate] = useState(new Date())
	const [description, setDescription] = useState('')

	useEffect(() => {
		if (reminder && reminder.id) {
			getData('reminders', reminder.id).then((reminder) => {
				setDate(parseISO(reminder.date));
				setDescription(reminder.description ? reminder.description : '')
			})
		} else {
			setDate(new Date());
			setDescription('');
		}

	}, [reminder])

	const renderHeaderDialog = () => {
		return <div className='flex flex-column'>
			<div className='flex font-normal'>{'Neue Wiedervorlage: ' + (header ? header : '')}</div>
			<div className='flex'>{name}</div>
		</div>
	}

	const clickSaveReminder = async () => {
		const r = { id: reminder ? reminder.id : null, description, date, serviceName, serviceId, userId, name, reminded: 0 }
		await patchData('reminders', r).then(() => {
			if (onEditReminder) {
				onEditReminder()
			}
			setDate(new Date())
			setDescription('')
			setDisplayDialog(false);
		}).catch((error) => {
			logger.error("Error clickSaveReminder while patch reminder: " + error.message)
		})
	}

	return (
		<Dialog header={renderHeaderDialog()} visible={displayDialog} onHide={() => setDisplayDialog(false)} className={'reminder-dialog ' + (fbibMode ? ' fbib' : '')} >
			<div className='flex flex-column mx-4'>
				<div className='field font-bold'>
					Legen Sie einen Zeitpunkt fest
				</div>
				<div className='field flex justify-content-center'>
					<Calendar inline value={date} onChange={(e) => setDate(e.value)} />
				</div>
				<div className="field">
					<label htmlFor={'description'} className='block font-bold'>Notiz der Wiedervorlage</label>
					<InputTextarea autoFocus rows={3} className='w-full' type='text' value={description} onChange={(e) => { setDescription(e.target.value); }} autoComplete="off" />
				</div>
				<div className="flex justify-content-end gap-2 ">
					<Button className='button-cancel' label="Abbrechen" onClick={() => { setDisplayDialog(false) }} />
					<Button className='' label="Speichern" onClick={clickSaveReminder} />
				</div>
			</div>
		</Dialog>
	)
}