import { useEffect, useState } from "react";

export const UnloadComponent = ({ unmountFunction }) => {

	useEffect(() => {
		return unmountFunction || (
			() => {
				console.log('no unmountFunction defined')
			}
		)
	}, [])


}