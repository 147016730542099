import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils"
import { useEffect } from "react";
import { useFBiBMode } from "../Persistence/ConfigurationsContext";

export const DropdownChangesChecked = ({ id, label, disabled, formik, className, optionValue, itemLabel, itemTemplate, valueTemplate, options, placeholder, filter, filterBy, onChange, handleChange, showClear, tabIndex, idError, emptyFilterMessage, filterTemplate, classNameDropdown, virtualScrollerOptions, editable, rowStyle, colLabels, multiple }) => {
	const fbibMode = useFBiBMode();
	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block mt-1">{formik.errors[id]}</small>;
	};

	useEffect(() => {

	}, [])

	const handleOnChange = (e) => {
		if (onChange) {
			onChange(e.value)
		}
		if (handleChange) {
			handleChange(e.value)
		}
		formik.handleBlur(e)
		formik.handleChange(e);
	}

	return (
		<div className={rowStyle ? 'flex w-full' : 'field col ' + className}>
			<label htmlFor={id} className={rowStyle ? (colLabels || 'col-3') : 'block'}>{label}</label>
			<div className={rowStyle ? 'col' : ''}>
				<Dropdown key={'dropdown-' + id}
					editable={editable}
					tabIndex={tabIndex}
					disabled={disabled}
					type='text'
					filter={filter}
					filterBy={filterBy}
					emptyFilterMessage={emptyFilterMessage || 'Kein Eintrag gefunden'}
					filterTemplate={filterTemplate}
					itemTemplate={itemTemplate}
					valueTemplate={valueTemplate}
					multiple={multiple}
					optionLabel={itemLabel}
					optionValue={optionValue}
					id={id}
					value={formik.values[id]}
					options={options}
					onChange={handleOnChange}
					placeholder={placeholder}
					autoComplete="off"
					virtualScrollerOptions={virtualScrollerOptions}
					onFocus={(event) => { event.target.setAttribute('autocomplete', 'new-password') }}
					className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + classNameDropdown}
					panelClassName={fbibMode ? 'fbib ' : ''}
					showClear={showClear}
					emptyMessage='Keine Auswahl vorhanden' />
				{getFormErrorMessage(idError ? idError : id)}
			</div>
		</div>
	)

}

