import { useFormik } from "formik";
import { useState } from 'react'
import { CompanyForm, emptyCompany, validateCompany } from "../../Companies/CompanyForm";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext";
import { Button } from "primereact/button";
import { getCompany } from "../../Companies/CompanyManager";
import { ENUM_ROUTES, ROUTES } from "../../Navigation/Routes";
import { useBreadcrumbsUpdate } from "../../Persistence/Breadcrumbs";
import { copyObject } from "../../snippets";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../../Components/Icons";
import { FBiBCaseForm, emptyFBiBCase, validateFBiBCase } from "./FBiBCaseForm";
import { patchFBiBCase } from "./FBiBCasesManager";
import { FBiBCasePersonForm, emptyFBiBCasePerson, validateFBiBCasePerson } from "../FBiBCasePersons/FBiBCasePersonForm";
import { patchData } from "../../feathers";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";


const CLASSNMAE = 'FBiBCaseCreateWizard'
export const FBiBCaseCreateWizard = ({ ENUM_AWESOMEICON: icon, disabled, parentCompanyId }) => {
	const isReader = useCurrentUserIsReaderFBiB()
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [step, setStep] = useState(0)

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	/** formik FBiBCase */
	const formikFBiBCase = useFormik({
		initialValues: { ...copyObject(emptyFBiBCase), wizardMode: (parentCompanyId ? undefined : 1) },
		validate: validateFBiBCase,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	/** formik FBiBCasePerson */
	const formikFBiBCasePerson = useFormik({
		initialValues: copyObject(emptyFBiBCasePerson),
		validate: validateFBiBCasePerson,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const startWizard = () => {
		formikCompany.setValues(copyObject(emptyCompany));
		formikFBiBCase.setValues({ ...copyObject(emptyFBiBCase), wizardMode: (parentCompanyId ? undefined : 1) })
		setStep(1)
	}

	const createFBiBCase = async () => {
		let companyId = parentCompanyId;
		let fbibCasePersonId = null;
		if (formikFBiBCase.values.wizardMode === 1) {
			/** Die Anlage der Fall-Person wurde gewählt */
			fbibCasePersonId = await patchData(ENUM_SERVICES.FBIBCASEPERSONS, formikFBiBCasePerson.values).then((result) => {
				return result.id
			})
		} else if (!companyId) {
			/** Die Anlage des Unternemens  wurde gewählt */
			const company = { ...formikCompany.values, origin: 'FBIB' }
			companyId = await patchData(ENUM_SERVICES.COMPANIES, company).then((result) => {
				return result.id
			})
		}
		const fbibCase = { ...formikFBiBCase.values, companyId, fbibCasePersonId }
		await patchFBiBCase(fbibCase).then(async (fbibCase) => {
			/** öffne neu erstellten Fall */
			updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: ROUTES.FBIB_CASES.label, route: `${ENUM_ROUTES.FBIB_CASES}/` + fbibCase.id }] })
		})
	}

	const handleSelectExistingCompany = async (companyId) => {
		formikCompany.setTouched({})
		if (companyId) {
			await getCompany(companyId).then(company => {
				formikCompany.setValues(company)
			})
		} else {
			formikCompany.setValues(copyObject(emptyCompany))
		}
	}

	return <>
		{step !== 1 ? '' : <EditDialog
			header={<div className='mt-2'>
				<div>{'FBiB Fall anlegen \u2192 Falldetails erfassen'}</div>
			</div>}
			form={
				<FBiBCaseForm
					formik={formikFBiBCase}
					editmode={true}
					key={'EaaCaseForm'} />
			}
			formik={formikFBiBCase}
			displayDialog={true}
			setDisplayDialog={setStep}
			labelButtonOk='Weiter'
			patchForm={async () => {
				if (parentCompanyId) {
					await createFBiBCase()
				} else {
					setStep(formikFBiBCase.values.wizardMode === 1 ? 3 : 2)
				}

			}}
		/>}

		{step !== 2 ? '' : <EditDialog
			header={<div className='mt-2'>
				<div>{'FBiB Fall anlegen \u2192 Unternehmen anlegen/auswählen'}</div>
			</div>}
			form={
				<CompanyForm
					readOnly={false}
					formik={formikCompany}
					showTrash={true}
					handleSelectExistingCompany={handleSelectExistingCompany}
				/>
			}
			formik={formikCompany}
			displayDialog={step === 2}
			setDisplayDialog={setStep}
			labelButtonOk='Übernehmen und Fall anlegen'
			patchForm={async () => { await createFBiBCase() }}
		/>}
		{step !== 3 ? '' : <EditDialog
			header={<div className='mt-2'>
				<div>{'Fall anlegen \u2192 Fallperson anlegen/auswählen'}</div>
			</div>}
			form={
				<FBiBCasePersonForm
					formik={formikFBiBCasePerson}
					handleSelectExistingCompany={handleSelectExistingCompany}
				/>
			}
			formik={formikFBiBCasePerson}
			displayDialog={step === 3}
			setDisplayDialog={setStep}
			labelButtonOk='Übernehmen und Fall anlegen'
			patchForm={async () => { await createFBiBCase() }}
		/>}

		<Button disabled={isReader || disabled} className={'flex text-white ' + (disabled ? 'color-background-lessblack' : '')} onClick={startWizard} >
			{!icon ? '' : <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.FOLDER_PLUS} size={ENUM_AWESOMEICON_SIZE.X1} className='mr-2' />}
			<div>FBiB Fall anlegen</div>
		</Button>
	</>

}