import { CompanyForm, emptyCompany, validateCompany } from "./CompanyForm"
import { useEffect } from "react";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { getCompany, patchCompany } from "./CompanyManager";
import { copyObject } from "../snippets";

export const CompanyChangeDialog = ({ companyId, displayCompanyDialog, setDisplayCompanyDialog, handleOnCompanyPatched, changeCompanyId }) => {

	const currentUser = useCurrentUser();

	useEffect(() => {
		if (companyId) {
			getCompany(companyId).then((company) => {
				formikCompany.setValues({ ...company, mobile: company.mobile || '', changeCompany: changeCompanyId !== undefined })
			});
		} else {
			formikCompany.setValues(copyObject(emptyCompany))
		}
	}, [companyId, displayCompanyDialog])

	useEffect(() => {
		formikCompany.setFieldValue('changeCompany', (changeCompanyId !== undefined && changeCompanyId !== null))
		formikCompany.setFieldValue('oldCompanyId', companyId)
	}, [changeCompanyId, companyId])

	const onChangeCompanyId = async (id) => {
		await changeCompanyId(id).then(() => {
			formikCompany.setTouched({})
		});

	}

	/** formik Company */
	const formikCompany = useFormik({
		//initialValues: JSON.parse(JSON.stringify({ ...emptyCompany, changeCompany: changeCompanyId !== undefined })),
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});


	return (<>
		<EditDialog
			displayDialog={displayCompanyDialog}
			formik={formikCompany}
			labelButtonOk={changeCompanyId ? 'Unternehmen im Fall ändern' : 'Ändern'}
			header={changeCompanyId ? 'Fall zu einem anderen Unternehmen wechseln' : 'Unternehmensdetails ändern'}
			message={changeCompanyId ? 'Hier können Sie den Fall zu einem anderen Unternehmen wechseln' : 'Hier können Sie die Details des Unternehmens ' + formikCompany.values.name + ' ändern.'}
			patchForm={async () => {
				if (changeCompanyId) {
					await changeCompanyId(formikCompany.values.id)
				} else {
					await patchCompany(formikCompany.values, currentUser).then((company) => {
						handleOnCompanyPatched(company)
					})
				}
			}}
			setDisplayDialog={setDisplayCompanyDialog}
			form={<CompanyForm
				formik={formikCompany}
				readOnly={changeCompanyId !== undefined}
				validateForm={() => formikCompany.validateForm()}
				handleSelectExistingCompany={onChangeCompanyId} />}
		/>
	</>
	)
}