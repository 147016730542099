
import { Dropdown } from "primereact/dropdown";
import { useAssociatedContactsSelectionList } from "../Persistence/AssociatedContactsContext";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";

export const AssociatedContactChooseDialog = ({ formik, associatedContacts, readonly, className }) => {

	const associatedContactsSelectionList = useAssociatedContactsSelectionList();
	const associatedContactOptionTemplate = (option) => {
		return (
			<div className="flex m-0 p-0">
				<div className='col-7 m-0 p-0 white-space-nowrap overflow-hidden text-overflow-ellipsis pr-2'>{option.organization}</div>
				<div className='col-5 m-0 p-0 white-space-nowrap overflow-hidden text-overflow-ellipsis '>{option.name}</div>
			</div>
		);
	}

	return <>
		<DropdownChangesChecked
			id={'associatedContactId'}
			label={'Netzwerkkontakt'}
			formik={formik}
			key={'dropdown'}
			panelClassName='p-2  dropdown-associated-contact-select'
			disabled={readonly}
			type='text'
			filter={true}
			filterBy="name,organization"
			itemTemplate={associatedContactOptionTemplate}
			valueTemplate={(option, props) => !option ? <span>{props.placeholder}</span> : ((option.organization ? option.organization + ' - ' : null) + option.name)}
			optionLabel='name'
			itemLabel='name'
			optionValue='id'
			options={associatedContactsSelectionList.filter((e) => { return !associatedContacts.find((f) => f.id === e.id); })}
			placeholder='Netzwerkkontakt wählen'
			className={(className ? className : 'w-full')}
			showClear={true} emptyMessage='Keine Auswahl vorhanden' />
	</>
}