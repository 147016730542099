import React, { useContext, useState, useEffect, useCallback } from "react";
import client, { getData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList, printStopwatchTime } from "../snippets";

const listenernames = ['created', 'patched', 'removed'];
const providername = 'AssociatedOrganisationsProvider';
const servicename = ENUM_SERVICES.ASSOCIATEDORGANIZATIONS;
const AssociatedOrganisationsContext = React.createContext();
const AssociatedOrganisationsSelectionContext = React.createContext();

export function useAssociatedOrganisationsSelectionList() {
	return useContext(AssociatedOrganisationsSelectionContext);
}

export function useAssociatedOrganisationsList() {
	return useContext(AssociatedOrganisationsContext);
}

export function AssociatedOrganisationsProvider({ children }) {
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [mounted, setMounted] = useState(false)

	const fetchData = useCallback(async () => {
		new Promise(async () => {
			const startTime = new Date()
			const list = await getData(ENUM_SERVICES.ASSOCIATEDORGANIZATIONS)
			setList(list)
			setListSelection(list)
			printStopwatchTime(`get associated organizations found:${list.length}`, startTime, true)
		})
	}, [])

	const handleListener = useCallback(async (setMode) => {
		const service = client.service(servicename);
		const serviceOrg = client.service(ENUM_SERVICES.ASSOCIATEDORGANIZATIONS);
		listenernames.forEach((listener) => {
			service.removeListener(listener);
			if (setMode) { service.on(listener, fetchData) };
			serviceOrg.removeListener(listener);
			if (setMode) { serviceOrg.on(listener, fetchData) };
		})
	}, [])

	useEffect(() => {
		handleListener(true)
		if (!mounted) {
			fetchData()
			return () => { LoggerContext.info(`########### ${providername} ---- unload`); handleListener(false); }
		}
		setMounted(true)
	}, [])

	return (
		<AssociatedOrganisationsContext.Provider value={list}>
			{children}
		</AssociatedOrganisationsContext.Provider>
	)
}