import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../../Components/ConfirmDialog';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { FieldInfo } from "../../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_ICONS, Icon } from '../../Components/Icons';
import { BasicSheetTitleLayout } from '../../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../../feathers';
import { useBreadcrumbsMinus } from '../../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { ToastError } from '../../Utils/ToastError';
import { BasicSheetLayout } from '../../Components/Layouts/BasicSheetLayout';
import { ENUM_SERVICES } from '../../Enums/ENUM_SERVICES';
import { CommentsModule } from '../../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { ReminderDialog } from '../../Reminders/ReminderDialog';
import { copyObject } from '../../snippets';

import { TrainingCourseForm, emptyTrainingCourse, validateTrainingCourse } from './TrainingCourseForm';
import { deleteTrainingCourse, getChamberTrainingCourseObject, getTrainingCourse, getTypeTrainingCourseObject, patchTrainingCourse, toggleTrainingCourseStatus } from './TrainingCourseManager';
import { listStatus } from '../../Companies/CompaniesData';
import { Tag } from 'primereact/tag';


const SERVICENAME = ENUM_SERVICES.TRAININGCOURSE
const CLASSNAME = 'TrainingCourseData'
export const TrainingCourseData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB();
	const configurationsList = useConfigurationsList();
	const { trainingCourseId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [trainingCourse, setTrainingCourse] = useState();
	const [displayTrainingEditDialog, setDisplayEditDialog] = useState(false);
	const [displayToggleStatusConfirmDialog, setDisplayToggleStatusConfirmDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	const trainingCourseUpdate = async () => {
		if (trainingCourseId) {
			return await getTrainingCourse(trainingCourseId, isReader, setTrainingCourse, setReadonly, setToastParams)
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await trainingCourseUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [errorUpdate, navigate])

	const onPatchTrainingCenter = async () => {
		await patchTrainingCourse(formik.values, currentUser, isReader, setTrainingCourse, setReadonly, setToastParams)
	}

	const onEditTrainingCourse = () => {
		formik.setValues(trainingCourse)
		setDisplayEditDialog(true)
	}

	const onDeleteTrainingCourse = async () => {
		await deleteTrainingCourse(trainingCourse, minusBreadcrumbs, setToastParams)
		setDisplayDeleteConfirmDialog(false);
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const formik = useFormik({
		initialValues: copyObject(emptyTrainingCourse),
		validate: validateTrainingCourse,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditTrainingCourse, disabled: readonly },
		{ key: 'reminder', label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditTrainingCourse, disabled: readonly }]
		if (!isReader && trainingCourse) {
			items.push({ separator: true })
			if (trainingCourse.active) {
				items.push({ label: 'Inaktiv setzen', icon: ENUM_ICONS.DEACTIVATE, command: () => setDisplayToggleStatusConfirmDialog(true) })
			} else {
				items.push({ label: 'Aktiv setzen', icon: ENUM_ICONS.REACTIVATE, command: () => setDisplayToggleStatusConfirmDialog(true) })
			}
		}
		if (currentUser.permissions === 'ADMIN' && trainingCourse) {
			items.push({ disabled: true, label: 'Ausbildungsgang unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}

	const renderDocLink = () => {
		let docLink = '';
		if (trainingCourse && trainingCourse.documentLocation) {

			try {
				const url = new URL(trainingCourse.documentLocation);
				if (url.protocol === "http:" || url.protocol === "https:") {
					docLink = trainingCourse.documentLocation
				} else {
					docLink = 'file:///' + trainingCourse.documentLocation;
				}
			} catch (error) { }
		}
		return <a href={docLink} target="_blank" rel="noreferrer" >{docLink}</a>

	}

	const renderContent = () => {
		const status = listStatus.find(entry => entry.alias === trainingCourse.active);
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						listButtons={listButtons}
						title={trainingCourse.name}
						subtitle={<div className='flex'>
							<div>{trainingCourse.displayId + ' erstellt am ' + (trainingCourse.createdAt ? formatDate(parseISO(trainingCourse.createdAt)) : '') + (trainingCourse.creator ? (' von ' + trainingCourse.creator.displayname) : '')}</div>
							<Tag className={'ml-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
						</div>
						}
					/>
					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo label='Typ' content={getTypeTrainingCourseObject(configurationsList, trainingCourse.type).display} />
						<FieldInfo label='Kammer' content={getChamberTrainingCourseObject(configurationsList, trainingCourse.chamberId).display} />
					</div>
					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo label='Dauer in Monaten' content={trainingCourse.duration} className='col-2' />
						<FieldInfo label='Dateipfad Dokumente' content={renderDocLink()} className='col-10' />
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Beschreibung des Ausbildungsgangs
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: trainingCourse.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule
							onUpdateComment={trainingCourseUpdate}
							key='trainingCourseCommentsModule'
							servicename={ENUM_SERVICES.TRAININGCOURSECOMMENTS}
							readonly={readonly}
							parentId={trainingCourse.id}
							idFieldname='trainingCourseId'
							reminderParentService={SERVICENAME}
							comments={trainingCourse.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title='Ausbildungsgang deaktivieren' message={`<span>Wollen Sie die Ausbildungsgang<br/><b>${trainingCourse.name}</b><br/>deaktivieren?<span>`} labelOk='Ja'
				handleOnClick={() => onDeleteTrainingCourse(trainingCourse, minusBreadcrumbs, setToastParams)} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Ausbildungsgang' name={trainingCourse.name} serviceId={trainingCourse.id} serviceName={SERVICENAME} userId={currentUser.id} />
			<EditDialog
				form={<TrainingCourseForm formik={formik} key='trainingcourse-form' />}
				formik={formik}
				patchForm={onPatchTrainingCenter}
				header={'Ausbildungsgang bearbeiten'}
				message={'Hier können sie den Ausbildungsgang bearbeiten'}
				setDisplayDialog={setDisplayEditDialog}
				displayDialog={displayTrainingEditDialog}
			/>
			<ConfirmDialog title={'Ausbildungsgang ' + (trainingCourse.active ? 'deaktivieren' : 'reaktivieren')} message={'Wollen Sie den Ausbildungsgang ' + (trainingCourse.active ? 'deaktivieren?' : 'reaktivieren?')} labelOk='Ja'
				handleOnClick={async () => await toggleTrainingCourseStatus(trainingCourse, currentUser, isReader, setTrainingCourse, setReadonly, setToastParams)}
				displayConfirmDialog={displayToggleStatusConfirmDialog} setDisplayConfirmDialog={setDisplayToggleStatusConfirmDialog} />

		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, awesomeIcon: ENUM_AWESOMEICON.TRAININGCOURSES,
				component: <div className="flex flex-column row-gap-4">

				</div>
			},
		]
		return sideSheets;
	}

	return (!trainingCourse ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!trainingCourse ? '' : renderContent()}{!trainingCourse ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={trainingCourse.id}
		/>
	)
}