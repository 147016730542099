import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from "primereact/utils"

export const InputTextareaChangesChecked = ({ id, label, formik, className, setShowDialog, rows, tabIndex, autoResize }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};

	return (
		<div className="field col mr-3">
			<label htmlFor={id} className='block'>{label}</label>
			<InputTextarea key={'inputtextarea-' + id}
				tabIndex={tabIndex}
				id={id}
				name={id}
				rows={rows ? rows : 5}
				type='text'
				value={formik.values[id]}
				onChange={(e) => { formik.handleChange(e) }}
				className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + className}
				autoResize={autoResize}
				autoComplete="off" />
			{getFormErrorMessage(id)}
		</div>
	)

}

