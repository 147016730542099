import { useEffect, useState } from "react";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked"
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";

export const AssociatedContactForm = ({ formik }) => {
	const [regionsSelection, setRegionsSelection] = useState([]);
	const [editmode, setEditmode] = useState(true);
	const configurationsList = useConfigurationsList();
	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();

	const createRegionSelection = () => {
		const regionSelection = regionsSelectionList ? [...regionsSelectionList] : [];
		if (formik.values.regionId) {
			const region = regionsList.find(entry => entry.id === formik.values.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		setRegionsSelection(regionSelection)
	}
	useEffect(() => {
		createRegionSelection();
	}, [regionsSelectionList])

	return (<>
		<div className="formgrid grid ">
			<InputChangesChecked autoFocus={true} formik={formik} id='name' label='Name' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={2} formik={formik} id='phone' label='Telefon' type='text' />
			<InputChangesChecked tabIndex={3} formik={formik} id='mobile' label='Mobil' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={4} formik={formik} id='fax' label='Fax' type='text' />
			<InputChangesChecked tabIndex={5} formik={formik} id='email' label='E-Mail' type='text' />
		</div>
		<div className="formgrid grid">

			<DropdownChangesChecked
				showClear={true}
				className='mr-3'
				tabIndex={6}
				label='Region'
				id='regionId'
				editmode={true}
				itemLabel={'name'}
				optionValue='id'
				formik={formik}
				options={regionsSelection} />
			<DropdownChangesChecked
				tabIndex={6}
				className='mr-3'
				label={'Status'}
				id='active'
				editmode={true}
				itemLabel={'display'}
				optionValue='alias'
				formik={formik}
				options={configurationsList.StatusActiveList} />
		</div>
		<div className="formgrid grid ">
			<InputChangesChecked tabIndex={14} formik={formik} id='description' label='Kurznotiz' type='text' />
		</div>
	</>
	)
}
export const getEmptyAssociatedContact = () => { return { id: null, active: 1, version: 1, name: '', description: '', email: '', mobile: '', phone: '', origin: 'EAA', regionId: null } }
export const validateAssociatedContact = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	return errors;
}
