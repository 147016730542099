import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { patchData } from '../../feathers';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { useBreadcrumbsAdd } from '../../Persistence/Breadcrumbs';
import { useConfigurationsList, useDataviewsConfig } from '../../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS_CONFIG } from '../../Enums/ENUM_DATAVIEWS_CONFIG';
import { FBiBJobOpeningForm, emptyFBiBJobOpening, validateFBiBJobOpening } from './FBiBJobOpeningForm';
import { TemplateDataViewPagination } from '../../Components/TemplateDataViewPagination';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { useFormik } from 'formik';
import { patchFBiBJobOpening } from './FBiBJobOpeningManager';
import { copyObject } from '../../snippets';
import { ENUM_SERVICES } from '../../Enums/ENUM_SERVICES';
import { FBiBJobOpeningCreateWizard } from './FBiBJobOpeningCreateWizard';

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.FBIBJOBOPENINGS;
export const FBiBJobOpeningDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB();
	const configurationsList = useConfigurationsList();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));

	const [jobOpening, setJobOpening] = useState();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();

	const navigate = useNavigate();
	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.FBIB !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationsList, navigate])

	const patchJobOpening = async () => {
		await patchData(ENUM_SERVICES.FBIBJOBPROFILES, jobOpening, currentUser).then((jobOpening) => {
			const label = jobOpening.company.name + ' | ' + jobOpening.description;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) : label}`, `${ENUM_ROUTES.FBIB_JOBPROFILES}/` + jobOpening.id);
		})
	}

	/** formik FBiB Jobopening */
	const formik = useFormik({
		initialValues: copyObject(emptyFBiBJobOpening),
		validate: validateFBiBJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '140px' }, body: null },
		{ filterAlias: 'col_02', field: "company.name", style: { flexGrow: 1, flexBasis: '200px' }, body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ filterAlias: 'col_03', field: "postcode", style: { width: '100px' } },
		{ filterAlias: 'col_04', field: "city", style: { width: '150px' } },
		{ filterAlias: 'col_05', field: "description", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ filterAlias: 'col_06', field: "jobType", style: { width: '200px' }, body: 'renderList' },
		{ filterAlias: 'col_07', field: "date", style: { width: '180px' }, body: 'renderDate' },
		{ filterAlias: 'col_08', field: "status", style: { width: '210px' }, body: 'renderTag' }
	]

	return (
		<div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				displayDialog={displayEditDialog}
				formik={formik}
				labelButtonOk='Anlegen'
				header='FBiB Stellenprofil anlegen'
				message='Lege ein neues FBiB Stellenprofil an'
				patchForm={async () => {
					await patchFBiBJobOpening(formik.values, currentUser).then((fbibJobopening) => {
						addBreadcrumb(`${fbibJobopening.name}`, `${ENUM_ROUTES.FBIB_JOBPROFILES}/` + fbibJobopening.id);
					})
				}}
				setDisplayDialog={setDisplayEditDialog}
				form={<FBiBJobOpeningForm
					formik={formik}
				/>}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-fbibjobopenings'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.FBIB_JOBPROFILES}
				buttons={[<FBiBJobOpeningCreateWizard disabled={isReader} />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}

		</div>
	)

}