import React, { useContext, useState, useEffect } from "react";
import client, { getData, socket, updateServiceListener } from '../feathers'
import { logContext, LoggerContext } from "../Utils/logger";
import { useCurrentUser, useCurrentUserUpdate } from "./CurrentUserContext";
import { getSortedList } from "../snippets";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

const listenernames = ['created', 'patched'];
const providername = 'OrganizationsProvider';
const servicename = ENUM_SERVICES.ORGANIZATIONS;
const OrganizationsContext = React.createContext();
const OrganizationsSelectionContext = React.createContext();

export function useOrganizationsSelectionList() {
	return useContext(OrganizationsSelectionContext);
}

export function useOrganizationsList() {
	return useContext(OrganizationsContext);
}

export function OrganizationsProvider({ children }) {
	const currentUser = useCurrentUser();
	const currentUserUpdate = useCurrentUserUpdate();
	const [list, setList] = useState([]);
	const [listSelection, setListSelection] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	const getListData = () => {
		getData(servicename).then((list) => {
			setList(list);
			const sortedList = getSortedList(list, 'name')
			setListSelection(sortedList.filter((entry) => entry.active))
			logContext(providername, 'info', `########### ${providername} -> getListData found:${list.length}`)
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}

	useEffect(() => {
		if (mounted) {
			logContext(providername, 'info', `########### ${providername} -> listitemchanged:`, listItemChanged)
			if (listItemChanged && listItemChanged.id === currentUser.organizationId) {
				currentUserUpdate({ ...currentUser, organization: listItemChanged })
				logContext(providername, 'info', ' update organization of currentUser ');
			}
			getListData();
		}
	}, [listItemChanged])

	useEffect(() => {
		if (mounted) {
			updateServiceListener(servicename, listenernames, (itemChanged) => setListItemChanged(itemChanged));
			logContext(providername, 'info', `########### ${providername} -> init organizations`);
			setTimeout(async () => getListData(), 1)
			return () => { logContext(providername, 'info', `########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true);
	}, [])

	return (
		<OrganizationsContext.Provider value={list}>
			<OrganizationsSelectionContext.Provider value={listSelection}>
				{children}
			</OrganizationsSelectionContext.Provider>
		</OrganizationsContext.Provider>
	)
}