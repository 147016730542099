import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import client, { getData, patchData } from "../feathers";

export type TYPE_ASSOCIATEDCONTACTCONFIG = {
	servicename: ENUM_SERVICES, idFieldname: string, displayname: string, deleteMessage: string, editMessage: string
}

export async function getAssociatedContact(associatedContactId: any) {
	return await getData(ENUM_SERVICES.ASSOCIATEDCONTACTS, associatedContactId).then((associatedContact) => {
		return associatedContact
	})
}

export async function getAssociatedContactsFromParent(associatedContactServiceConfig: TYPE_ASSOCIATEDCONTACTCONFIG, parentId: any) {
	return await client.service(ENUM_SERVICES.UTILS).create({ method: 'findAssociatedContacts', service: associatedContactServiceConfig.servicename, serviceId: parentId }).then((associatedContacts: any) => {
		return associatedContacts
	});
}

async function saveAssociatedContacts(associatedContactServiceConfig: TYPE_ASSOCIATEDCONTACTCONFIG, parentId: number, associatedContacts: any[]) {
	return await client.service(ENUM_SERVICES.UTILS).create({
		method: 'setAssociatedContacts',
		serviceId: parentId,
		tablename: associatedContactServiceConfig.servicename,
		tableIdField: associatedContactServiceConfig.idFieldname,
		associatedContacts
	}).then(async () => {
		return await getAssociatedContactsFromParent(associatedContactServiceConfig, parentId)
	});
}

export async function addAssociatedContactId(associatedContactId: number, associatedContactServiceConfig: TYPE_ASSOCIATEDCONTACTCONFIG, parentId: number) {
	return await getAssociatedContact(associatedContactId).then(async (associatedContact) => {
		return await addAssociatedContact(associatedContact, associatedContactServiceConfig, parentId).then(() => {
			return associatedContact;
		});
	})
}

export async function addAssociatedContact(associatedContact: any, associatedContactServiceConfig: TYPE_ASSOCIATEDCONTACTCONFIG, parentId: any) {
	return await getAssociatedContactsFromParent(associatedContactServiceConfig, parentId).then(async (associatedContacts) => {
		associatedContacts.push(associatedContact)
		return await saveAssociatedContacts(associatedContactServiceConfig, parentId, associatedContacts)
	});
}

export async function deleteAssociatedContact(associatedContactId: number, associatedContactServiceConfig: TYPE_ASSOCIATEDCONTACTCONFIG, parentId: number) {
	return await getAssociatedContactsFromParent(associatedContactServiceConfig, parentId).then(async (list: any) => {
		const associatedContacts = list.filter((e: any) => e.id !== associatedContactId)
		return await saveAssociatedContacts(associatedContactServiceConfig, parentId, associatedContacts)
	});

}