import { MultiSelect } from "primereact/multiselect";
import { useConfigurationsList, useFBiBMode } from "../../Persistence/ConfigurationsContext";
import { useEffect, useState } from 'react';
import { Tag } from "primereact/tag";


export const FilterTemplateMultiSelect = ({ options }) => {
	const configurationsList = useConfigurationsList();
	const fbibMode = useFBiBMode();
	const [listOptions, setListOptions] = useState([]);
	const [placeholder, setPlaceholder] = useState();
	const [filterElementClassName, setFilterElementClassName] = useState();
	const [maxSelectedLabels, setMaxSelectedLabels] = useState();
	const [filterActiveClassName, setFilterActiveClassName] = useState('');
	const [filterSelectedLabel, setFilterSelectedLabel] = useState();

	useEffect(() => {
		if (options.filterModel) {
			setListOptions(configurationsList[options.filterModel.filterListName]);
			setPlaceholder(options.filterModel.filterPlaceholder);
			setFilterElementClassName(options.filterModel.filterElementClassName);
			setMaxSelectedLabels(options.filterModel.maxSelectedLabels);
			setFilterActiveClassName(options.value && options.value.length > 0 ? 'eaa-filter-active' : '');
			setFilterSelectedLabel(options.filterModel.filterSelectedLabel);
		}
	}, [options])

	const selectedTemplate = (option) => {
		const item = listOptions.find(entry => entry.alias === option)
		return !item ? '' :
			<div className={"flex text-xm" + (' color-text-' + item.color)}>
				{item.datatable}
			</div>
	}

	const renderTagFilter = (option) => { return <Tag className={"mr-2 text-white px-3" + (' color-tag-' + option.color)} rounded value={option.datatable} /> }
	return (<div className={filterElementClassName}>
		<MultiSelect
			id='statusRowFilterTemplate'
			panelClassName={fbibMode ? 'fbib' : ''}
			className={filterActiveClassName + ' flex w-full'}
			value={options.value}
			optionValue='alias'
			optionLabel="datatable"
			options={listOptions}
			onChange={(e) => options.filterApplyCallback(e.value)}
			itemTemplate={renderTagFilter}
			selectedItemTemplate={selectedTemplate}
			placeholder={placeholder}
			selectedItemsLabel={(options.value ? options.value.length : 0) + ' ' + filterSelectedLabel + ' ausgewählt'}
			scrollHeight='250px'
			maxSelectedLabels={maxSelectedLabels} />
	</div>
	)

}