import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';


const CLASSNAME = 'OverviewCaseRequestsDataview'

export const OverviewCaseRequestsDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEREQUESTS));
	const isAdmin = useCurrentUserIsAdmin();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true)
	}, [])

	const renderCreator = (rowData) => {
		return (rowData.creator ? rowData.creator.lastname + ', ' + rowData.creator.firstname : 'n.n.')
	}
	const columns = [
		{ filterAlias: 'col_01', field: "eaaCase.displayId", style: { width: '100px' }, },
		{ filterAlias: 'col_02', field: "eaaCase.name", style: { flexGrow: 1, flexBasis: '150px' } },
		{ filterAlias: 'col_03', field: "company.name", style: { flexGrow: 1, flexBasis: '150px' }, className: '' },
		{ filterAlias: 'col_04', field: "creator", className: (isAdmin ? '' : 'hidden'), style: { width: '250px' }, body: renderCreator },
		{ filterAlias: 'col_05', field: "caseRequestType.name", style: { width: '350px' } },
		{ filterAlias: 'col_06', field: "caseRequestStatus", style: { width: '160px' }, body: 'renderTag' },
		{ filterAlias: 'col_07', field: "requestDate", style: { width: '180px' }, body: 'renderDate' },
	]

	return <div className='flex flex-column gap-2'>
		<TemplateDataViewPagination
			showOpenLinkColumn={true}
			key='datatable-overviews-caserequests'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			ROUTE={ENUM_ROUTES.EAACASES}
			columns={columns}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig}
		/>
	</div>
}