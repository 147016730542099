import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { Address } from '../Components/FormElements/Adddress';
import { useTranslation } from 'react-i18next';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';

const CLASSNAME = 'AddressesForm'
export const AddressesForm = ({ formik, readonly, validateForm }) => {
	const configurationsList = useConfigurationsList();
	const { t } = useTranslation(['formelements']);
	return (
		!formik ? '' :
			<div className=''>
				<div className="formgrid mr-3 mb-1">
					<DropdownChangesChecked
						label={t('formelements.address.addresstype.label')}
						id={'addressType'}
						formik={formik}
						optionValue='alias'
						itemLabel={'display'}
						handleChange={() => { }}
						className={'m-0'}
						options={configurationsList.AddressTypeList} />
				</div>

				<Address readonly={readonly} formik={formik} validateForm={validateForm} className='field col mr-3' />
			</div>
	)

}

export const emptyAddress = { id: null, version: 1, line1: '', line2: '', line3: '', city: '', postcode: '', country: 'Deutschland', addressType: 'HEADQUARTER', postcodeAndCity: '' }
export const validateAddress = (data) => {
	let errors = {};
	if (!data.line1) {
		errors.line1 = 'Strasse / Hausnummer müssen angegeben werden';
	}
	if (!data.postcode) {
		errors.postcode = 'Postleitzahl';
	}
	if (data.country === 'Deutschland' && ('' + data.postcodeAndCity) === '') {
		errors.postcodeAndCity = 'PLZ / Ort ist ein Pflichtfeld';
	}
	if (!data.city) {
		errors.city = 'Ort ist ein Pflichtfeld';
	}
	if (!data.country) {
		errors.country = 'Land ist ein Pflichtfeld';
	}
	if (!data.addressType) {
		errors.addressType = 'Adresstyp  ist ein Pflichtfeld';
	}
	return errors;
}
