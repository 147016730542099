import { useError } from "../Persistence/ErrorContext";
import { useEffect } from 'react'
import { ENUM_ROUTES } from "./Routes";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const CLASSNAME = 'ErrorPage';
export const ErrorPage = () => {
	const navigate = useNavigate();
	const error = useError();

	useEffect(() => {
		if (error && !error.message) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [error, navigate])

	const handleBackButton = () => {
		navigate('/' + ENUM_ROUTES.DASHBOARD)
	}

	return <div className='flex align-content-center justify-content-center w-full pt-8'>
		<div className='flex align-items-center justify-content-center w-full'>
			<div className='flex flex-column' style={{ minWidth: '300px', maxWidth: '800px' }}>
				<div className='flex  text-3xl my-3 p-0 '>{error.title}</div>
				<div className='flex flex-column font-light my-3 p-0 ' style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: error.message }}>{ }</div>
				<Button className='flex flex-column font-light my-3 w-8rem' label='Dashboard' onClick={handleBackButton} />
			</div>
		</div>
	</div>
}