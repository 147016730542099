import { useEffect, useState } from 'react';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useConfigurationsList, useDataviewsConfig } from "../Persistence/ConfigurationsContext";
import { useRequestDistributionsAssignedList } from '../Persistence/RequestDistributionsContext';
import { logDefault } from '../Utils/logger';
import { Tag } from 'primereact/tag';
import { useErrorUpdate } from '../Persistence/ErrorContext';
import { useNavigate } from 'react-router-dom';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';

const CLASSNAME = 'RequestDistributionsArchivDataview';
export const RequestDistributionsArchivDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONSARCHIV);
	const errorUpdate = useErrorUpdate();
	const dataTableList = useRequestDistributionsAssignedList();
	const configurationsList = useConfigurationsList();
	const [mounted, setMounted] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (!configurationsList.eaaModules.REQUESTDISTRIBUTION) {
			errorUpdate('Modul Aufgabenverteilung', 'Das Modul Aufgabenverteilung ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		} else if (dataTableList && configurationsList.TaskareasList && configurationsList.RegionsList) {
			logDefault(CLASSNAME, 'info', 'dataTableList: ' + dataTableList.length, dataTableList, configurationsList.length)
			setMounted(true)
		}
	}, [dataTableList, configurationsList])

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", style: { width: '110px' }, body: null },
		{ field: "subject", sortable: true, filter: true, header: "Betreff", style: { width: '200px' } },
		{ field: "email", sortable: true, filter: true, header: "Absender", style: { flexGrow: 1, flexBasis: '100px' } },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Anfragedatum", style: { width: '180px' }, body: 'renderDate' },
		{ field: "regions", sortable: true, filter: 'regions', header: "Regionen", style: { flexGrow: 1, flexBasis: '80px' }, body: (row) => <div className='flex flex-wrap'>{(row.regions || []).map(e => <Tag className={"mb-2 mr-2 px-3"} key={'tag-region-' + e.id} rounded value={e.name} />)}</div> },
		{ field: "taskareas", sortable: true, filter: "taskareas", header: "Aufgabenbereiche", style: { flexGrow: 1, flexBasis: '80px' }, body: (row) => <div className='flex flex-wrap'>{(row.taskareas || []).map(e => <Tag className={"mb-2 mr-2 px-3"} key={'tag-taskarea-' + e.id} style={{ color: '#' + e.color, backgroundColor: '#' + e.backcolor }} rounded value={e.name} />)}</div> },
		{ field: "assigned", sortable: true, filter: true, filterField: 'assigned.id', header: "Zugewiesen an", style: { flexGrow: 1, flexBasis: '150px' }, body: (row) => row.assigned.lastname + ', ' + row.assigned.firstname }
	]

	return (!mounted ? <></> :
		<div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<TemplateDataView
				columns={columns}
				dataTableList={dataTableList}
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				ROUTE={ENUM_ROUTES.REQUESTDISTRIBUTIONARCHIVE} />
		</div>
	)
}