import { Button } from "primereact/button";
import { StyleClass } from 'primereact/styleclass';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { ENUM_ICONS } from "../../Components/Icons";
import { getData } from "../../feathers";
import { useBreadcrumbs, useBreadcrumbsUpdate } from '../../Persistence/Breadcrumbs';
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { useUserSwitchMode, useUserSwitchModeUpdate } from "../../Persistence/CurrentUserContext";
import { updateLastAction } from '../../Persistence/LoginContext';
import logger from '../../Utils/logger';
import { Breadcrumbs } from '../../Navigation/Breadcrumbs';
import { ENUM_ROUTES, ROUTES } from "../../Navigation/Routes";
import { FBiBSidebar } from './FBiBSidebar'

export const FBiBNavigationPage = () => {
	const breadcrumbs = useBreadcrumbs();
	const userSwitchMode = useUserSwitchMode();
	const setUserSwitchUpdate = useUserSwitchModeUpdate();
	const configurationsList = useConfigurationsList();
	const btnRef6 = useRef(null);
	const location = useLocation();
	const [showSidebarLabel, setShowSidebarLabel] = useState(false);
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		const configurationComplete = configurationsList && configurationsList.StatusActiveList
		setMounted(configurationComplete)
	}, [configurationsList])

	useEffect(() => {
		var handlekeyDown = window.addEventListener('keydown', (event) => { updateLastAction(); });
		var handleClick = window.addEventListener('click', (event) => { updateLastAction(); });

		//set breadcrumbs
		let route = null;
		const paths = location.pathname.split('/');
		if (paths.length > 2) {
			for (let entry in ROUTES) {
				if (ROUTES[entry].route === (paths[1] + '/' + paths[2])) {
					const items = [{ label: ROUTES[entry].label, route: ROUTES[entry].route }];

					if (paths.length > 3 && /^\d+$/.test(paths[3])) {
						if (ROUTES[entry].service) {
							getData(ROUTES[entry].service, paths[3]).then((result) => {
								if (result) {
									let label = result.name + ''
									if (ROUTES[entry].route === ENUM_ROUTES.JOBPROFILES) {
										label = result.company.name + ' | ' + result.description;
									} else if (ROUTES[entry].route === ENUM_ROUTES.FBIB_CASES) {
										label = result.name;
									}
									label = label.length > 50 ? label.substring(0, 49) + '...' : label;
									items.push({ label, route: ROUTES[entry].route + '/' + paths[3] })
								}
								updateBreadcrumbs({ home: { icon: ROUTES[entry].icon }, items })
							})
						}
					} else {
						updateBreadcrumbs({ home: { icon: ROUTES[entry].icon }, items })
					}
					break;
				}
			}
		}

		return () => { logger.debug('clear Inteval timeIntervalExpire & eventListener'); window.removeEventListener('click', handleClick); window.removeEventListener('keydown', handlekeyDown); }
	}, []);

	const onSidebarClick = (route, icon, label) => {
		updateBreadcrumbs({ home: { icon }, items: [{ label, route }] })
	}

	return (
		!mounted ? <></> : <>
			<div className="min-h-screen flex relative lg:static overflow-hidden fbib">
				<div id="app-sidebar" className="h-screen surface-section hidden lg:block flex-shrink-0  left-0 top-0 z-1 border-right-1 surface-border w-18rem lg:w-auto select-none">
					<div className={'hidden lg:block h-full'} onMouseEnter={() => { setShowSidebarLabel(true) }} onMouseLeave={() => { setShowSidebarLabel(false) }}>
						<FBiBSidebar onSidebarClick={onSidebarClick} showSidebarLabel={showSidebarLabel} />
					</div>
					<div className={'block lg:hidden h-full'} >
						<FBiBSidebar onSidebarClick={onSidebarClick} showSidebarLabel={true} />
					</div>
				</div>
				<div className="min-h-screen flex flex-column relative flex-auto">
					<div className={"flex align-items-center px-3 relative lg:static border-bottom-1 surface-border " + (userSwitchMode ? 'bg-red-100' : 'surface-section ')} style={{ height: '60px' }}>
						<div className="flex ">
							<StyleClass nodeRef={btnRef6} selector="#app-sidebar" enterClassName="hidden" enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft" hideOnOutsideClick>
								<a ref={btnRef6} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
									<i className="fa fa-bars text-4xl"></i>
								</a>
							</StyleClass>
						</div>
						<div className='flex flex-grow-1'>
							<div>
								<Breadcrumbs />
							</div>
						</div>
						<div>
							<div className="flex flex-row justify-content-center">
								<Button onClick={() => { setUserSwitchUpdate(null) }} icon={ENUM_ICONS.CASE_DELETE} className={!userSwitchMode ? 'hidden' : "p-button-rounded p-button-outlined color-lessblack mx-4"} />
								<Button onClick={() => { onSidebarClick(ENUM_ROUTES.FBIB_USERPROFILE, ENUM_ICONS.USER, 'Nutzer*innenprofil') }} icon={ENUM_ICONS.USER} className={"p-button-rounded p-button-outlined user-profile " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === ENUM_ICONS.USER ? 'color-text-blue' : 'color-lessblack')} />
							</div>
						</div>
					</div>
					<div className="flex justify-content-center  m-0 flex-auto surface-ground w-full" >
						<div className="pl-2 m-0 surface-border overflow-auto w-full" style={{ maxWidth: 'calc(100vw - 60px)', height: 'calc(100vh - 60px)' }}>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}