import { useEffect, useState } from "react";
import { AutoCompleteChangesChecked } from "../../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from '../../Components/DropdownChangesChecked';
import { InputChangesChecked } from "../../Components/InputChangesChecked";
import { InputText } from 'primereact/inputtext';
import client, { getData } from '../../feathers';
import { useCompaniesInit, useCompaniesList } from "../../Persistence/CompaniesContext";
import { useConfigurationsList, useFBiBMode } from '../../Persistence/ConfigurationsContext';
import { parseISO } from 'date-fns';
import { CalendarChangesChecked } from '../../Components/CalendarChangesChecked';
import { EditorChangesChecked } from '../../Components/EditorChangesChecked';
import { ENUM_ICONS } from '../../Components/Icons';
import { logDefault } from '../../Utils/logger';
import { TrainingCourseDropdown } from '../../FBiB/Components/TrainingCourseDropdown';
import { getCompany } from "../../Companies/CompanyManager";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { Address } from "../../Components/FormElements/Adddress";


const CLASSNAME = 'FBiBJobOpeningForm'
export const FBiBJobOpeningForm = ({ formik, validateForm, readonly }) => {

	const configurationsList = useConfigurationsList();
	const useCompanyList = useCompaniesList();
	const companiesInit = useCompaniesInit();
	const fbibMode = useFBiBMode();
	const [companiesList, setCompanyList] = useState([])
	const [company, setCompany] = useState();
	const [fbibCase, setFbibCase] = useState();
	const [fbibCaseList, setFbibCaseList] = useState();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		companiesInit();
		logDefault(CLASSNAME, 'info', 'formik values:', formik.values)
		setMounted(true)
	}, [])

	useEffect(() => {
		if (useCompanyList) {
			setCompanyList(useCompanyList.filter((entry) => entry.active))
		}
	}, [useCompanyList])

	useEffect(() => {
		if (formik.values.companyId) {
			client.service(ENUM_SERVICES.FBIBCASES).find({ query: { companyId: formik.values.companyId } }).then((list) => {
				const fbibCaseId = fbibCase ? fbibCase.id : null;
				setFbibCaseList(list.filter(entry => (entry.jobOpening === undefined || entry.jobOpening.fbibCaseId === fbibCaseId)));
			})
		} else {
			setFbibCaseList([])
		}
	}, [formik.values.companyId, fbibCase])

	return (
		<div>{!formik ? <div></div> :
			< div className='col' >
				<form onSubmit={formik.handleSubmit}>
					<div className="formgrid grid">
						<InputChangesChecked id='description' type="text" formik={formik} label='Stellenbeschreibung' disabled={false} />
					</div>
					<div className="formgrid grid">
						{!mounted ? '' : <EditorChangesChecked id='explanation' value={formik.values.explanation} setValue={(value) => formik.setFieldValue('explanation', value)} label='Erläuterungen' disabled={false} />}
					</div>
					<div className="formgrid grid">
						<div className="field col mr-3">
							<label htmlFor='input-companyName' className='block'>Unternehmen</label>
							<div className='flex'>
								<InputText key='input-companyName' id='input-companyName' disabled={true} value={formik.values.company.name} className={'w-full'} />
							</div>
						</div>
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked
							editmode={true}
							label='Stellenart'
							id='jobType'
							itemLabel='display'
							optionValue='alias'
							className='mr-3 '
							formik={formik}
							options={fbibMode ? configurationsList.FBiBJobOpeningTypeList : configurationsList.JobOpeningTypeList} />
						<CalendarChangesChecked className='mr-3' id='openingDate' formik={formik} label='Startdatum' />
					</div>
					<Address readonly={readonly} formik={formik} validateForm={validateForm} className='field col mr-3' />

					<div className="formgrid grid">
						<InputChangesChecked className='mr-6' id='workingHours' label='Arbeitsstunden' type="text" formik={formik} disabled={false} />
						<InputChangesChecked className='mr-3' id='salary' label='Lohn / Gehalt' type="text" formik={formik} disabled={false} />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked className={'w-6 mr-3'} editmode={true} label='Mobilität erforderlich' id={'mobilityRequired'} itemLabel='label' formik={formik} options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]} />
						<InputChangesChecked id='mobilityType' label='Mobilitätstyp' type="text" formik={formik} disabled={false} />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked className={'w-6 mr-3'} editmode={true} label='Geschäftsreise erforderlich' id={'businessTravelRequired'} itemLabel='label' formik={formik} options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]} />
						<InputChangesChecked id='excludedDisabilities' label='Ausgeschlossene Behinderung' type="text" formik={formik} disabled={false} />
					</div>
				</form>
			</div >
		}</div >
	)
}

export const emptyFBiBJobOpening = {
	id: null, version: 1, active: 1, description: '', explanation: '', jobType: null, openingDate: null, mobilityRequired: 0, businessTravelRequired: 0, status: 'OPEN', excludedDisabilities: '', mobilityType: '', salary: '', workingHours: '', companyId: null, company: null, fbibCaseId: null, fbibCase: null, trainingCourseId: null, postcodeAndCity: '', line1: '', line2: '', line3: '', city: '', postcode: '', country: 'Deutschland'
}

export const validateFBiBJobOpening = (data) => {
	let errors = {};
	if (!data.description) {
		errors.description = 'Beschreibung ist ein Pflichtfeld';
	}
	if (!data.jobType) {
		errors.jobType = 'Typ ist ein Pflichtfeld';
	}
	if (!data.openingDate) {
		errors.openingDate = 'Datum ist ein Pflichtfeld';
	}
	if (!data.status) {
		errors.status = 'Status ist ein Pflichtfeld';
	}
	return errors;
}