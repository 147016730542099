import { useEffect, useState } from 'react'
import { ENUM_ICONS } from '../Components/Icons';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext'
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';


export const AddressesCard = ({ address, onEditHandler, onDeleteHandler, readonly }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const configurationList = useConfigurationsList();
	const [addressType, setAddressType] = useState()

	useEffect(() => {
		if (address && typeof address.addressType === 'object' && address.addressType.constructor === Object) {
			setAddressType(address.addressType)
		} else if (address && configurationList.AddressTypeList) {
			setAddressType(configurationList.AddressTypeList.find(entry => entry.alias === address.addressType))
		}

	}, [address, configurationList])

	const renderTitle = () => {
		return <div className="flex title">
			<div className="flex flex-grow-1 font-bold">
				{addressType ? addressType.display : ''}
			</div>
			<div className={isReader || readonly ? 'hidden' : "flex title-icons justify-content-end font-light"}>
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(address) }} />
				{onDeleteHandler ? <i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(address) }} /> : ''}
			</div>
		</div>
	}

	const renderContent = () => {
		const { line1, line2, line3, postcode, city, country } = address
		return <div className="flex flex-column h-9rem py-1 px-2 overflow-x-auto">
			<div ><label className='w-full block'>{line1}</label></div>
			<div><label className=' block'>{!line2 ? '' : line2}</label></div>
			<div><label className=' block'>{!line3 ? '' : line3}</label></div>
			<div className="mt-3"><label className=' block'>{postcode + ' / ' + city}</label></div>
			<div ><label className=' block'>{country}</label></div>
		</div>
	}
	return (<>
		<div className="eaa-card address-card shadow-2">
			{address ? renderTitle() : ''}
			{address ? renderContent() : ''}
		</div>
	</>)
}