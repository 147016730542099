import { Carousel } from 'primereact/carousel';
import { useState } from "react";
import { ContactPersonCard } from '../ContactPersons/ContactPersonCard';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { ContactPersonsForm, emptyContactPerson, validateContactPerson } from './ContactPersonsForm';
import { ENUM_ICONS } from '../Components/Icons';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { Card } from 'primereact/card';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { getData, patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { useFBiBMode } from '../Persistence/ConfigurationsContext';

const CLASSNAME = 'ContactPersonsSheet'
export const ContactPersonsSheet = ({ contactPersons, addressList, CONTACTPERSON_SERVICECONFIG, parentId, numCarousel, handleUpdateParent, readonly }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const fbib = useFBiBMode();
	const [displayDialog, setDisplayDialog] = useState(false);

	const handlerEditContactPerson = async (contactPersonId) => {
		await getData(ENUM_SERVICES.CONTACTPERSONS, contactPersonId).then(async (contactPerson) => {
			//contactPerson.addressId = contactPerson.addressId === null ? '' : contactPerson.addressId
			formik.setValues(contactPerson);
		})
		setDisplayDialog(true)
	}

	const handlerDeleteContactPerson = async (contactPerson) => {
		await getData(ENUM_SERVICES.CONTACTPERSONS, contactPerson.id).then(async (contactPerson) => {
			await patchData(ENUM_SERVICES.CONTACTPERSONS, { ...contactPerson, active: 0 }).then(async () => {
				await handleUpdateParent();
			})
		})
	}

	const onPatchContactPerson = async () => {
		const contactPerson = { ...formik.values, [CONTACTPERSON_SERVICECONFIG.refFieldname]: parentId }
		await patchData(ENUM_SERVICES.CONTACTPERSONS, contactPerson).then(async () => {
			await handleUpdateParent();
		})
	}

	const onAddContactPerson = async () => {
		formik.setValues({ ...copyObject(emptyContactPerson), origin: (fbib ? 'FBIB' : 'EAA') })
		setDisplayDialog(true)
	}

	const renderContactPerson = (contactPerson) => {
		return <ContactPersonCard
			key={'contactPerson_' + contactPerson.id}
			contactPerson={contactPerson}
			onEditContactPerson={handlerEditContactPerson}
			onDeleteContactPerson={handlerDeleteContactPerson}
			readonly={readonly}
			CONTACTPERSON_SERVICECONFIG={CONTACTPERSON_SERVICECONFIG} />
	}

	const renderHeaderContactPersons = () => {
		return <div className='flex justify-content-between border-bottom-1'>
			<div className='flex font-medium  mb-1 '>Ansprechpartner*innen ({contactPersons.length}) </div>
			<div className={isReader || readonly ? 'hidden' : 'flex w-1rem'} onClick={onAddContactPerson}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
		</div>
	}

	const formik = useFormik({
		initialValues: copyObject(emptyContactPerson),
		validate: validateContactPerson,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	return (
		<>
			<div className="flex flex-wrap">
				<Card className={contactPersons ? 'companysheet card-sheet mt-2 h-19rem' : 'hidden'} header={renderHeaderContactPersons}>
					{!contactPersons || contactPersons.length === 0 ?
						<div className='m-1'>keine Ansprechpartner*innen gefunden</div> :
						<Carousel className='w-full mt-1' showIndicators={false} value={contactPersons || []} numVisible={numCarousel} numScroll={numCarousel} itemTemplate={renderContactPerson} responsiveOptions={responsiveOptions} />}
				</Card>
				<EditDialog
					form={<ContactPersonsForm
						formik={formik}
						addressList={addressList}
						key='dialog-contact-person' CONTACTPERSON_SERVICECONFIG={CONTACTPERSON_SERVICECONFIG}
					/>}
					formik={formik}
					patchForm={onPatchContactPerson}
					header={formik.values.id ? 'Ansprechparter*in ändern' : 'Neuen Ansprechparter*in hinzufügen'}
					message={'Hier können sie den Ausbildungsgang bearbeiten'}
					setDisplayDialog={setDisplayDialog}
					labelButtonOk={formik.values.id ? 'ändern' : 'Neu hinzufügen'}
					displayDialog={displayDialog} />
			</div>
		</>
	)
}