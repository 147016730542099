import { useCallback, useEffect, useState } from 'react';
import { ENUM_ROUTES, ROUTES } from '../Navigation/Routes';
import { ENUM_ICONS } from '../Components/Icons';
import { useBreadcrumbsUpdate } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { useOrganizationsList } from '../Persistence/OrganizationsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';


const CLASSNAME = 'OverviewRelatedUsersDataview'

export const OverviewRelatedUsersDataview = () => {
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const configurationsList = useConfigurationsList();
	const organizationsList = useOrganizationsList();
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_RELATEDUSERS));



	const handleOpenRelatedUserObject = async (serviceName, id) => {
		const route = serviceName === 'companies' ? ROUTES['COMPANIES'] : (serviceName === 'eaa-cases' ? ROUTES['EAACASES'] : ROUTES['PROVIDERACTIVITIES'])
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + id }] })
	}

	const handleDoubleClick = useCallback((rowData) => {
		const serviceName = rowData.serviceName
		const id = rowData.id
		handleOpenRelatedUserObject(serviceName, id)
	}, [])

	const renderContextMenu = (rowData) => {
		return <div className={'open-icon flex w-full h-1rem ' + (rowData.hideEdit ? 'justify-content-end' : 'justify-content-between ')}>
			{rowData.hideEdit ? '' : <i className={ENUM_ICONS.EYE} onClick={() => handleOpenRelatedUserObject(rowData.serviceName, rowData.serviceId)} />}
		</div>
	}

	const renderRoleType = (rowData) => {
		const roleType = configurationsList.CaseRoleList.find(e => e.alias === rowData.roleType)
		return roleType ? roleType.display : 'n.n.'
	}
	const renderOrganization = (rowData) => {
		const organization = organizationsList ? organizationsList.find(e => e.id === rowData.relatedUser.organizationId) : undefined;
		return organization ? organization.name : 'n.n.'
	}

	const columns = [
		{ filterAlias: 'col_01', field: "serviceName", style: { width: '110px' }, body: 'renderListIcon' },
		{ filterAlias: 'col_02', field: "displayId", style: { width: '150px' }, },
		{ filterAlias: 'col_03', field: "name", style: { flexGrow: 1, flexBasis: '150px' } },
		{ filterAlias: 'col_04', field: "millisecondsDate", style: { width: '180px' }, body: 'renderDate' },
		{ filterAlias: 'col_05', field: "relatedUser.id", style: { width: '250px' }, body: (rowData) => rowData.relatedUser.displayname },
		{ filterAlias: 'col_06', field: "roleType", style: { width: '200px' }, body: renderRoleType },
		{ filterAlias: 'col_07', field: "relatedUser.organizationId", style: { flexGrow: 1, flexBasis: '150px' }, body: renderOrganization },
	]

	return <div className='flex flex-column gap-2'>
		{!dataviewConfig ? '' : <TemplateDataViewPagination
			key='datatable-overviews-relatedUsers'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'

			columns={columns}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig}
		/>}
	</div>
}