import { Button } from "primereact/button";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";

export const RequestDistributionUserCard = ({ user, onAssignUser }) => {
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

	const renderTitle = () => {
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{user.lastname + ', ' + user.firstname}
				</div>
				<div>
					{user.organization ? user.organization.name : 'nicht angegeben'}
				</div>
			</div>
		</div>

	}
	const renderContent = () => {
		const { email, regions, taskareas } = user
		return <div className="flex align-content-end flex-column h-5rem px-2 py-1">
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col '><a href={`mailto:${email}`}>{email}</a></div></div>
			<div className={regions ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'> <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.REGIONS} size={ENUM_AWESOMEICON_SIZE.X1} className='text-gray-700' /></div> <div className='col '>{regions.map(e => e.name).join(': ')}</div></div>
			<div className={taskareas ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TASKAREAS} size={ENUM_AWESOMEICON_SIZE.X1} className='text-gray-700' /></div><div className='col '>{taskareas.map(e => e.name).join(', ')}</div></div>
		</div>
	}

	const renderButton = () => {
		return <div className="flex flex flex-column">
			<Button key={uuidv4()} type="button" className="border-noround w-full " label='Zuweisen' onClick={() => { setDisplayConfirmDialog(true) }} />
		</div>
	}

	return <div className="eaa-card contactperson-card shadow-2">
		<ConfirmDialog title='Aufgabe zuweisen' message={`<span>Wollen Sie diese Aufgabe<br/><b>${user.lastname + ', ' + user.firstname}</b><br/>zuzuweisen?</span>`} labelOk='Ja'
			handleOnClick={() => onAssignUser(user)} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

		{renderTitle(user)}
		{renderContent(user)}
		{renderButton(user)}
	</div>

}

