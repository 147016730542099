import { ENUM_ICONS } from "../../Components/Icons";
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";

export const TrainingCourseCard = ({ trainingCourse, onEditHandler, onDeleteHandler, readonly, highlightCard }) => {
	const configurationsList = useConfigurationsList();
	const renderContent = () => {
		const { type, duration } = trainingCourse
		const trainingCourseType = typeof trainingCourse.type === 'object' && trainingCourse.type.constructor === Object ? trainingCourse.type.display : configurationsList.TrainingCourseTypeList.find(e => e.alias === trainingCourse.type).display
		return <div className="align-content-end flex-column h-4rem px-2 py-1">
			<div className={trainingCourseType ? 'grid' : 'hidden'}><div className='col-fixed w-4rem'>Typ:</div><div className='col '>{trainingCourseType}</div></div>
			<div className={duration ? 'grid' : 'hidden'}><div className='col-fixed w-4rem'>Dauer:</div><div className='col '>{duration} Monate</div></div>
		</div>
	}
	const renderTitle = (trainingCourse) => {
		const { chamberId } = trainingCourse
		return <div className={'flex title ' + (highlightCard ? 'color-background-fbib' : '')}>
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{trainingCourse.name}
				</div>
				<div>
					{configurationsList.ChamberList.find(e => e.id === chamberId).display}
				</div>
			</div>
			<div className={readonly ? 'hidden' : "flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(trainingCourse) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(trainingCourse) }} />
			</div>
		</div>
	}

	return (<>
		{!trainingCourse || trainingCourse.active === 0 ? '' :
			<div id='relateduser-card' className={'eaa-card relateduser-card shadow-2 '}>
				<div>{renderTitle(trainingCourse)}</div>
				{renderContent(trainingCourse)}
			</div>
		}
	</>)
}