import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { ENUM_ROUTES } from "../../Navigation/Routes";
import { logDefault } from "../../Utils/logger";
import { deleteData, getData, patchData } from "../../feathers";

const CLASSNAME = 'TrainingCenter';
const servicename = ENUM_SERVICES.TRAININGCENTERS;

export function getTypeTrainingCenterObject(configurationsList: any, type: any) {
	return configurationsList.TrainingCenterTypeList.find((e: any) => e.alias === type) || { display: 'nicht besetzt' }
}


export const openTrainingCenter = async (id: number, addBreadcrumb: Function) => {
	logDefault(CLASSNAME, 'info', 'openTrainingCenter id:' + id, null)
	await getData(servicename, id).then((trainingCenter) => {
		addBreadcrumb(`${trainingCenter.name}`, `${ENUM_ROUTES.FBIB_TRAININGCENTERS}/` + id);
	})
}

export const deleteTrainingCenter = async (trainingCenter: any, minusBreadcrumbs: Function, setToastParams: Function) => {
	await deleteData(servicename, trainingCenter.id).then(async () => {
		minusBreadcrumbs();
	}).catch((error) => {
		setToastParams({ title: 'Ausbildungsstätte konnte nicht gelöscht werden', message: error.message })
	})
}

export const toggleTrainingCenterStatus = async (trainingCenter: any, currentUser: any, isReader: any, setTrainingCenter: Function, setReadonly: Function, setToastParams: Function) => {
	trainingCenter.active = trainingCenter.active ? 0 : 1;
	return await patchTrainingCenter(trainingCenter, currentUser, isReader, setTrainingCenter, setReadonly, setToastParams)
}
export const patchTrainingCenter = async (trainingCenter: any, currentUser: any, isReader: any, setTrainingCenter: Function, setReadonly: Function, setToastParams: Function) => {

	logDefault(CLASSNAME, 'info', 'trainingCenter:', trainingCenter)
	return await patchData(servicename, trainingCenter, currentUser).then((trainingCenter) => {
		if (setTrainingCenter) {
			setTrainingCenter(trainingCenter);
		}
		if (setReadonly) {
			setReadonly(!trainingCenter.active || isReader)
		}
		return trainingCenter;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Ausbildungsstätte mit der Nummer "' + trainingCenter.id + '" konnte nicht aktualisiert werden', message: error.message })
		} else {
			throw error
		}
	});
}

export const getTrainingCenter = async (id: any, isReader: any, setTrainingCenter: Function, setReadonly: Function, setToastParams: Function) => {
	return await getData(servicename, id).then((trainingCenter) => {
		if (setTrainingCenter) {
			setTrainingCenter(trainingCenter);
		}
		if (setReadonly) {
			setReadonly(!trainingCenter.active || isReader)
		}
		return trainingCenter;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Ausbildungsstätte mit der Nummer "' + id + '" konnte nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}
