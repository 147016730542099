import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";
import { useCallbackPrompt } from "../../Utils/useCallbackPrompt";
import UnsafedChangesDialog from "../UnsafedChangesDialog";
import { useFBiBMode } from "../../Persistence/ConfigurationsContext";

export const EditDialog = ({ form, formik, header, message, patchForm, displayDialog, setDisplayDialog, labelButtonOk, className, onCancel, additionalButton, refCancel }) => {
	const fbibMode = useFBiBMode();
	const [hasErrors, setHasErrors] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)

	useEffect(() => {
		setHasErrors(Object.keys(formik.errors).length !== 0)
	}, [formik.errors])

	useEffect(() => {
		const touched = Object.keys(formik.touched)
		setShowDialog(touched.length !== 0 && touched[0] !== 'undefined')

	}, [formik.touched])

	const onClickSave = async () => {
		setShowDialog(false);
		setDisplayDialog(false);
		await patchForm();
	}
	const onClickCancel = () => {
		setShowDialog(false);
		setDisplayDialog(false);
		if (onCancel) {
			onCancel()
		}
	}

	const renderFooter = () => {
		return <div className={'flex flex-row-reverse  mx-4 my-2'}>
			<Button disabled={hasErrors} onClick={onClickSave} className=" ml-3 " label={labelButtonOk ? labelButtonOk : formik.values.id ? 'Ändern' : 'Neu anlegen'} />
			{!additionalButton ? '' : <Button type="button" className={' ml-3 '} label={additionalButton.label} onClick={() => { setShowDialog(false); additionalButton.onClick() }} />}
			<Button type="button" ref={refCancel} className={'button-cancel'} label='Abbrechen' onClick={onClickCancel} />
		</div>
	}

	return (<>
		<UnsafedChangesDialog
			// @ts-ignore
			showDialog={showPrompt}
			confirmNavigation={confirmNavigation}
			cancelNavigation={cancelNavigation}
		/>
		<Dialog header={header}
			footer={renderFooter}
			visible={displayDialog}
			onHide={onClickCancel}
			id="dialog-editdialog" className={(className || 'eaa-inputdialog') + (fbibMode ? ' fbib' : '')} >
			<div className='flex font-light mb-3 p-0 ' style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: message }}></div>
			{form}
		</Dialog>
	</>
	)
}