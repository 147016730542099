import { Card } from "primereact/card";
import { ENUM_ICONS } from "../Components/Icons";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

export const JobOpeningSheet = ({ jobOpening }) => {
	const configurationsList = useConfigurationsList()
	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Stellenprofil</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>Weitere Stelleninformationen</div>
				<div className='hidden'>
					<div className='flex w-1rem' onClick={() => { }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	return (
		<>
			<Card className="jobopeningsheet card-sheet" header={renderHeader}>
				{!jobOpening ? '' :
					<>
						<div className={jobOpening.salary ? 'grid' : 'hidden'}>
							<div className="col-fixed">Gehalt</div>
							<div className="col">{jobOpening.salary}</div>
						</div>
						<div className={jobOpening.workingHours ? 'grid' : 'hidden'}>
							<div className="col-fixed">Arbeitsstunden</div>
							<div className="col">{jobOpening.workingHours}</div>
						</div>
						<div className={jobOpening.businessTravelRequired !== null ? 'grid' : 'hidden'}>
							<div className="col-fixed">Geschäftsreisen</div>
							<div className="col">{jobOpening.businessTravelRequired ? 'Ja' : 'Nein'}</div>
						</div>
						<div className={jobOpening.excludedDisabilities ? 'grid' : 'hidden'}>
							<div className="col-fixed">Ausgeschl. Bhd</div>
							<div className="col">{jobOpening.excludedDisabilities}</div>
						</div>
						<div className={jobOpening.mobilityRequired !== null ? 'grid' : 'hidden'}>
							<div className="col-fixed">Mobilität</div>
							<div className="col">{jobOpening.mobilityRequired ? 'Ja' : 'Nein'}</div>
						</div>
						<div className={jobOpening.mobilityType ? 'grid' : 'hidden'}>
							<div className="col-fixed">Mobilitätstyp</div>
							<div className="col">{jobOpening.mobilityType}</div>
						</div>
						<div className={jobOpening.status ? 'grid' : 'hidden'}>
							<div className="col-fixed">Status</div>
							<div className="col">{configurationsList.JobOpeningStatusList.find(e => jobOpening.status === e.alias).display}</div>
						</div>
					</>}
			</Card>
		</>
	)
}
