import { reactLocalStorage } from 'reactjs-localstorage';

export const loglevel = reactLocalStorage.get('loglevel', 'info');
export const loglevellogin = reactLocalStorage.get('loglevellogin', 'info');
export const loglevelcontext = reactLocalStorage.get('loglevelcontext', 'info');

enum LogLevel {
	DEBUG,
	INFO,
	WARN,
	ERROR
}

class Logger {
	private logLevel: LogLevel;

	constructor(logLevel: LogLevel = LogLevel.INFO) {
		this.logLevel = logLevel;
	}

	private shouldLog(level: LogLevel): boolean {
		return level >= this.logLevel;
	}

	private formatMessage(level: LogLevel, message: string): string {
		const levelStr = LogLevel[level];
		const timestamp = new Date().toISOString();
		return `[${timestamp}] [${levelStr}] ${message}`;
	}

	public debug(message: string): void {
		if (this.shouldLog(LogLevel.DEBUG)) {
			console.debug(this.formatMessage(LogLevel.DEBUG, message));
		}
	}

	public info(message: string): void {
		if (this.shouldLog(LogLevel.INFO)) {
			console.info(this.formatMessage(LogLevel.INFO, message));
		}
	}

	public warn(message: string): void {
		if (this.shouldLog(LogLevel.WARN)) {
			console.warn(this.formatMessage(LogLevel.WARN, message));
		}
	}

	public error(message: string, error?: Error): void {
		if (this.shouldLog(LogLevel.ERROR)) {
			console.error(this.formatMessage(LogLevel.ERROR, message), error);
		}
	}

	public setLogLevel(level: LogLevel): void {
		this.logLevel = level;
	}
}
// Usage example:
const logger = new Logger(LogLevel.DEBUG);

export default logger
export const LoggerContext = new Logger(LogLevel.DEBUG);
export const LoggerLogin = new Logger(LogLevel.DEBUG);

export const logDefault = (classname: string, loglevel: string, message: string, object: any) => {
	const logdefault = reactLocalStorage.get('logdefault', '').split(' ');
	if (logdefault.includes(classname)) {
		object ? console.log(loglevel, classname + ': ' + message, object) : console.log(loglevel, message)
	}
}

export const logContext = (classname: string, loglevel: string, message: string, object: any) => {
	const logcontext = reactLocalStorage.get('logcontext', '').split(' ');
	if (logcontext.includes(classname)) {
		console.log(loglevel, message, object || '')
	}
}


// Changing log level
//logger.setLogLevel(LogLevel.ERROR);


/*
import winston, { createLogger, format, transports } from 'winston';

// Configure the Winston logger. For the complete documentation see https://github.com/winstonjs/winston

winston.addColors({
	verbose: 'magenta',
});

const util = require('util');

function transform(info: any, opts: any) {
	const args = info[Symbol.for('splat')];
	if (args) { info.message = util.format(info.message, ...args); }
	return info;
}

function utilFormatter() { return { transform }; }

export const loglevel = reactLocalStorage.get('loglevel', 'info');
export const loglevellogin = reactLocalStorage.get('loglevellogin', 'info');
export const loglevelcontext = reactLocalStorage.get('loglevelcontext', 'info');

export const logDefault = (classname: string, loglevel: string, message: string, object: any) => {
	const logdefault = reactLocalStorage.get('logdefault', '').split(' ');
	if (logdefault.includes(classname)) {
		object ? logger.log(loglevel, classname + ': ' + message, object) : logger.log(loglevel, message)
	}
}

export const logContext = (classname: string, loglevel: string, message: string, object: any) => {
	const logcontext = reactLocalStorage.get('logcontext', '').split(' ');
	if (logcontext.includes(classname)) {
		LoggerContext.log(loglevel, message, object || '')
	}
}

const createFormat = (_label: any) => {
	return format.combine(
		format.colorize({ all: true }),
		format.timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
		format.label({ label: _label }),
		format.splat(),
		utilFormatter(),     // <-- this is what changed
		format.printf((info: any) => `[${info.timestamp}] ${info.label}: ${info.message}`)
	)
}

const logger = createLogger({
	level: loglevel, format: createFormat(''), transports: [new transports.Console({ handleExceptions: true })]
});


export const LoggerContext = createLogger({

	level: loglevelcontext,
	format: createFormat('[C]'),
	transports: [
		new transports.Console({
			format: format.combine(
				format.colorize(),
			)
		})]
});
export const LoggerLogin = createLogger({
	level: loglevellogin, format: createFormat('[L]'), transports: [new transports.Console({ handleExceptions: true })]
});

export default logger;
*/