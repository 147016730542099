import { Button } from "primereact/button"


export const BasicSheetTitleLayout = ({ listButtons, title, subtitle, overlaypanel }) => {

	const renderButtons = () => {
		return listButtons.map(button => {
			return <Button onClick={button.command} key={button.key} icon={button.icon} disabled={button.disabled} className='flex text-white color-background-blue' label={button.label} />
		})
	}

	return (
		<div className='flex flex-column '>
			<div className='flex justify-content-end mb-4 gap-2'>
				{renderButtons()}
			</div>

			<div className='flex grid'>
				<div className='col flex flex-column text-4xl '>
					<div className="flex mt-0 text-4xl font-semibold justify-content-center text-center mx-8">
						{title}
					</div>
					<div className='flex text-lg justify-content-center my-2 gap-2'>
						{subtitle}
					</div>
				</div>
				<div className={overlaypanel ? 'col-fixed w-2rem' : 'hidden'}>
					{overlaypanel}
				</div>
			</div>
		</div>)
}