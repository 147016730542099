import React, { useContext, useEffect, useState } from "react";
import client, { getData } from "../feathers";
import { LoggerContext } from "../Utils/logger";
import { SocketErrorProvider } from "./SocketErrorContext";
import * as Sentry from "@sentry/react";
import { ENUM_TABVIEWS } from "../Enums/ENUM_TABVIEWS";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { useFBiBMode } from "./ConfigurationsContext";

const TabViewsIndexContext = React.createContext();
const TabViewsIndexUpdateContext = React.createContext();
const CurrentUserContext = React.createContext();
const CurrentUserUpdateContext = React.createContext();
const CurrentUserIsSuperAdminContext = React.createContext();
const CurrentUserIsAdminContext = React.createContext();
const CurrentUserIsReaderContext = React.createContext();
const CurrentUserIsReaderEAAContext = React.createContext();
const CurrentUserIsReaderFBiBContext = React.createContext();
const UserSwitchModeContext = React.createContext();
const UserSwitchModeUpdateContext = React.createContext();

export function useTabViewsIndex() {
	return useContext(TabViewsIndexContext);
}
export function useTabViewsIndexUpdate() {
	return useContext(TabViewsIndexUpdateContext);
}
export function useCurrentUser() {
	return useContext(CurrentUserContext);
}
export function useCurrentUserUpdate() {
	return useContext(CurrentUserUpdateContext);
}
export function useCurrentUserIsSuperAdmin() {
	return useContext(CurrentUserIsSuperAdminContext);
}
export function useCurrentUserIsAdmin() {
	return useContext(CurrentUserIsAdminContext);
}
export function useCurrentUserIsReader() {
	return useContext(CurrentUserIsReaderContext);
}
export function useCurrentUserIsReaderEAA() {
	return useContext(CurrentUserIsReaderEAAContext);
}
export function useCurrentUserIsReaderFBiB() {
	return useContext(CurrentUserIsReaderFBiBContext);
}
export function useUserSwitchMode() {
	return useContext(UserSwitchModeContext);
}
export function useUserSwitchModeUpdate() {
	return useContext(UserSwitchModeUpdateContext);
}

export function CurrentUserProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [tabViewsIndex, setTabViewIndex] = useState({ [ENUM_TABVIEWS.OVERVIEWS]: ENUM_DATAVIEWS_CONFIG.OVERVIEWS_COMPANYADDRESSES });
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isReader, setIsReader] = useState(false);
	const [isReaderEAA, setIsReaderEAA] = useState(false);
	const [isReaderFBiB, setIsReaderFBiB] = useState(false);
	const [switchedUser, setSwitchedUser] = useState(null);
	const [isSwitchMode, setIsSwitchMode] = useState(false);
	const fbibMode = useFBiBMode();

	async function setUser(user) {
		if (user) {
			const { permissions, email } = user;
			setIsSuperAdmin(user.isAdmin === 1);
			setIsAdmin(user.permissions === 'ADMIN');
			setIsReader(user.permissions === 'READER' && user.permissionsFBiB === 'READER')
			setIsReaderEAA(user.permissions === 'READER');
			setIsReaderFBiB(user.permissionsFBiB === 'READER');
			Sentry.setUser({ email });
			Sentry.setTag('permission', permissions);
		} else {
			setTabViewIndex(null);
			setIsSuperAdmin(false);
			setIsAdmin(false);
			setIsReader(true);
			Sentry.setUser(null);
			Sentry.setTag('permission', '');
		}

		setCurrentUser(user);
	}


	async function setSwitchMode(switchUser) {
		await getData('users', currentUser.id).then((user) => {
			if (!user.isAdmin || !switchUser) {
				setIsSwitchMode(false);
				setSwitchedUser(null);
				setIsAdmin(currentUser.permissions === 'ADMIN');
				setIsReader(currentUser.permissions === 'READER');
			} else {
				if (switchUser) {
					switchUser.permissions = 'READER';
					switchUser.isSwitchedUser = true;
				}
				setIsSwitchMode(true);
				setSwitchedUser(switchUser);
				setIsAdmin(switchUser.permissions === 'ADMIN');
				setIsReader(switchUser.permissions === 'READER');
			}

		})
	}

	return (
		<TabViewsIndexUpdateContext.Provider value={setTabViewIndex}>
			<TabViewsIndexContext.Provider value={tabViewsIndex}>
				<CurrentUserContext.Provider value={switchedUser || currentUser}>
					<CurrentUserIsSuperAdminContext.Provider value={isSuperAdmin || isSwitchMode}>
						<CurrentUserIsAdminContext.Provider value={isAdmin}>
							<CurrentUserIsReaderContext.Provider value={isReader}>
								<CurrentUserIsReaderEAAContext.Provider value={isReaderEAA}>
									<CurrentUserIsReaderFBiBContext.Provider value={isReaderFBiB}>
										<CurrentUserUpdateContext.Provider value={setUser}>
											<UserSwitchModeContext.Provider value={isSwitchMode}>
												<UserSwitchModeUpdateContext.Provider value={setSwitchMode}>
													<SocketErrorProvider>
														{children}
													</SocketErrorProvider>
												</UserSwitchModeUpdateContext.Provider>
											</UserSwitchModeContext.Provider>
										</CurrentUserUpdateContext.Provider>
									</CurrentUserIsReaderFBiBContext.Provider>
								</CurrentUserIsReaderEAAContext.Provider>
							</CurrentUserIsReaderContext.Provider>
						</CurrentUserIsAdminContext.Provider>
					</CurrentUserIsSuperAdminContext.Provider>
				</CurrentUserContext.Provider>
			</TabViewsIndexContext.Provider>
		</TabViewsIndexUpdateContext.Provider>
	)
}