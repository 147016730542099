import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { EAADivider } from "../Components/EAADivider";
import { getData, patchData } from "../feathers";
import { JobOpeningCard } from '../JobOpenings/JobOpeningCard';
import { JobOpeningForm, emptyJobOpening, validateJobOpening } from '../JobOpenings/JobOpeningForm';
import logger, { logDefault } from "../Utils/logger";
import { ENUM_ICONS } from '../Components/Icons';
import { Card } from 'primereact/card';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { parseISO } from 'date-fns';

const CLASSNAME = 'CompanyJobOpenings';
export const CompanyJobOpenings = ({ company, numCarousel, onUpdateCompany, hideDivider, readonly }) => {
	const [displayDialog, setDisplayDialog] = useState(false);
	const [jobOpeningId, setJobOpeningIdId] = useState();
	const [jobOpenings, setJobOpenings] = useState([]);

	useEffect(() => {
		setJobOpenings(company.jobOpenings || [])
	}, [company])

	const handlerEdit = async (jobOpeningId) => {
		if (jobOpeningId) {
			await getData(ENUM_SERVICES.JOBPROFILES, jobOpeningId).then((result) => {
				result.openingDate = parseISO(result.openingDate)
				formik.setValues(result);
			})
		} else {
			formik.setValues({ ...copyObject(emptyJobOpening), companyId: company.id, company });
		}
		setDisplayDialog(true)
	}

	const handlerDelete = async (jobOpening) => {
		jobOpening.active = 0
		logDefault(CLASSNAME, 'info', 'handlerDelete', jobOpening)
		await patchData('job-openings', jobOpening).then(() => {
			onUpdateCompany();
		})
	}

	const handleSave = async () => {
		const jobOpening = formik.values
		jobOpening.eaaCaseId = (jobOpening.eaaCase ? jobOpening.eaaCase.id : jobOpening.eaaCaseId)
		jobOpening.companyId = company.id
		await patchData(ENUM_SERVICES.JOBPROFILES, jobOpening).catch((error) => {
			logger.error('Error while handleSave in CompanyJobOpenings: ' + error.message)
		})
		onUpdateCompany();
		setDisplayDialog(false)
	}

	const renderListEntries = (item) => {
		return <JobOpeningCard
			key={'jobopening_' + item.id}
			item={item}
			onEditHandler={handlerEdit}
			onDeleteHandler={handlerDelete}
			readonly={readonly} />
	}
	const renderHeaderJobopenings = () => {
		return <div className='flex justify-content-between border-bottom-1'>
			<div className='flex font-medium  mb-2 '>Stellenprofile ({jobOpenings.length}) </div>
			<div className={readonly ? 'hidden' : 'flex w-1rem'} onClick={() => handlerEdit()}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
		</div>
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Stellenprofile</div>
			{renderHeaderJobopenings()}
		</div>
	}

	const formik = useFormik({
		initialValues: copyObject(emptyJobOpening),
		validate: validateJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const responsiveOptions = [
		{ breakpoint: '1024px', numVisible: 3, numScroll: 3 },
		{ breakpoint: '600px', numVisible: 2, numScroll: 2 },
		{ breakpoint: '480px', numVisible: 1, numScroll: 1 }
	];

	return (
		<>
			<Card className="companysheet card-sheet bg-white h-19rem" header={renderHeader}>
				<EAADivider className={(hideDivider || readonly ? 'hidden ' : '') + 'm-0 my-3 p-1'} icon={ENUM_ICONS.PLUS} label='Neues Stellenprofil' onClickHandler={(e) => { setJobOpeningIdId(null); setDisplayDialog(true) }} />
				<Carousel circular={true} className='flex w-full mt-1 ' contentClassName='flex justify-content-center w-full' showNavigators={jobOpenings.length > 1} showIndicators={false} value={jobOpenings} numVisible={numCarousel} numScroll={numCarousel} itemTemplate={renderListEntries} responsiveOptions={responsiveOptions} />
				<EditDialog
					displayDialog={displayDialog}
					formik={formik}
					header={jobOpeningId ? 'Stellenprofil ändern' : 'Stellenprofil anlegen'}
					form={<JobOpeningForm
						formik={formik}
					/>}
					labelButtonOk={jobOpeningId ? 'Speichern' : 'Neu anlegen'}
					patchForm={handleSave}
					setDisplayDialog={setDisplayDialog}
				/>

			</Card>
		</>
	)
}
