import { BreadcrumbsProvider } from '../../Persistence/Breadcrumbs';
import { OrganizationsProvider } from '../../Persistence/OrganizationsContext';
import { DataViewFilterProvider } from '../../Persistence/DataViewFilterContext';
import { CompaniesProvider } from '../../Persistence/CompaniesContext';
import { StrgPressedProvider } from '../../Persistence/StrgPressed';
import { EAACasesProvider } from '../../Persistence/EAACasesContext';
import { ProviderActivitiesProvider } from '../../Persistence/ProviderActivitiesContext';
import { UserProvider } from '../../Persistence/UserContext';
import { PostcodesProvider } from '../../Persistence/PostcodesContext';
import { RegionTagsProvider } from '../../Persistence/RegionTagsContext';
import { TaskareaTagsProvider } from '../../Persistence/TaskareaTagsContext';
import { ErrorProvider } from '../../Persistence/ErrorContext';
import { RemindersProvider } from '../../Persistence/RemindersContext';
import { RequestDistributionsProvider } from '../../Persistence/RequestDistributionsContext';
import { CaseRequestTypesProvider } from '../../Persistence/CaseRequestTypesContext';
import { AssociatedContactsProvider } from '../../Persistence/AssociatedContactsContext';
import { CaseEventsProvider } from '../../Persistence/CaseEventsContext';
import { useConfigurationsComplete, useConfigurationsList } from '../../Persistence/ConfigurationsContext';
import { FBiBNavigationPage } from './FBiBNavigationPage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENUM_ROUTES } from '../../Navigation/Routes';

export const FBiBApplicationInit = () => {
	const configurationCompleted = useConfigurationsComplete();
	const configurationList = useConfigurationsList();
	const navigate = useNavigate();
	useEffect(() => {
		if (configurationList && configurationList.eaaModules && configurationList.eaaModules.FBIB !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [configurationList, navigate])


	useEffect(() => {
		document.title = 'FBiB Management' + (configurationList && configurationList.title ? ' - ' + configurationList.title : '');
	}, [configurationCompleted])

	return (!configurationCompleted ?
		<div className='flex h-full w-full '>
			<div className='flex flex-column w-full h-full justify-content-center '>
				<div className='flex justify-content-center text-4xl'>Das FBiB DOQ wird für Sie gestartet</div>
				<div className='flex w-full justify-content-center  '>
					<img src={window.location.origin + "/icons/fbib_logo.png"} className='flex w-3' />
				</div>
			</div>
		</div> :
		<CaseEventsProvider >
			<AssociatedContactsProvider >
				<RequestDistributionsProvider>
					<CompaniesProvider>
						<ProviderActivitiesProvider>
							<EAACasesProvider>
								<UserProvider>
									<OrganizationsProvider>
										<PostcodesProvider>
											<RegionTagsProvider>
												<CaseRequestTypesProvider>
													<TaskareaTagsProvider>
														<DataViewFilterProvider>
															<RemindersProvider>
																<BreadcrumbsProvider>
																	<StrgPressedProvider>
																		<ErrorProvider>
																			<FBiBNavigationPage />
																		</ErrorProvider>
																	</StrgPressedProvider>
																</BreadcrumbsProvider>
															</RemindersProvider>
														</DataViewFilterProvider>
													</TaskareaTagsProvider>
												</CaseRequestTypesProvider>
											</RegionTagsProvider>
										</PostcodesProvider>
									</OrganizationsProvider>
								</UserProvider>
							</EAACasesProvider>
						</ProviderActivitiesProvider>
					</CompaniesProvider>
				</RequestDistributionsProvider>
			</AssociatedContactsProvider>
		</CaseEventsProvider>
	)
}