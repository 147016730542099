import { getTime } from "date-fns";
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils";

export const InputField = ({ id, type, label, formik, className, autoFocus, tabIndex, col }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id]}</small>;
	};
	return (
		<div className={"field " + (col || 'col')}>
			<label htmlFor={id} className='block'>{label}</label>
			<InputText id={id} name={id} type={type} autoFocus={autoFocus} tabIndex={tabIndex} value={formik.values[id]} onChange={(e) => { formik.handleBlur(e); formik.handleChange(e); }} className={classNames({ 'p-invalid': isFormFieldValid(id) }) + (className ? className : ' w-full')} autoComplete={'off'} onFocus={(event) => { event.target.setAttribute('autocomplete', 'new-password') }} />
			{getFormErrorMessage(id)}
		</div>
	)

}

