import { parseISO } from "date-fns";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { getData } from "../../feathers";

export function getTypeAvtivityObject(configurationsList: any, typeActivity: any) {
	return configurationsList.ProvideractivitiesTypeList.find((e: any) => e.alias === typeActivity) || { display: 'n.n.' }
}

export async function getProviderActivity(configurationsList: any, providerActivityId: any) {
	return await getData(ENUM_SERVICES.FBIBPROVIDERACTIVITIES, providerActivityId).then((provideractivity) => {
		provideractivity.date = parseISO(provideractivity.date);
		provideractivity.relatedUsers = provideractivity.relatedUsers.map((relatedUser: any) => {
			relatedUser.roleType = relatedUser.roleType ? configurationsList.CaseRoleList.find((e: any) => e.alias === relatedUser.roleType) : null;
			return relatedUser;
		});
		return provideractivity
	})
}