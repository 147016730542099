import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { ENUM_ROUTES } from "../../Navigation/Routes";
import { logDefault } from "../../Utils/logger";
import { deleteData, getData, patchData } from "../../feathers";


const CLASSNAME = 'FBiBJobOpeningManager';
const servicename = ENUM_SERVICES.FBIBJOBPROFILES;


export const openFBiBJobOpening = async (id: number, addBreadcrumb: Function) => {
	logDefault(CLASSNAME, 'info', 'openFBiBCase id:' + id, null)
	await getData(servicename, id).then((jobOpening) => {
		addBreadcrumb(`${jobOpening.name}`, `${ENUM_ROUTES.FBIB_JOBPROFILES}/` + id);
	})
}

export const deleteFBiBJobOpening = async (jobOpening: any, minusBreadcrumbs: Function, setToastParams: Function) => {
	await deleteData(servicename, jobOpening.id).then(async () => {
		minusBreadcrumbs();
	}).catch((error) => {
		setToastParams({ title: 'FBiB Stelle konnte nicht gelöscht werden', message: error.message })
	})
}

export const toggleFBiBJobOpeningStatus = async (jobOpening: any, currentUser: any, isReader: any, setFBiBJobOpening: Function, setReadonly: Function, setToastParams: Function, configurationsList?: any) => {
	jobOpening.active = jobOpening.active ? 0 : 1;
	jobOpening.status = jobOpening.active === 1 ? 'OPEN' : 'CLOSED';
	jobOpening.fbibCaseId = jobOpening.active === 1 ? jobOpening.fbibCaseId : null;
	return await patchFBiBJobOpening(jobOpening, currentUser, isReader, setFBiBJobOpening, setReadonly, setToastParams, configurationsList)
}

export const patchFBiBJobOpening = async (jobOpening: any, currentUser: any, isReader: any, setFBiBJobOpening: Function, setReadonly: Function, setToastParams: Function, configurationsList?: any) => {
	logDefault(CLASSNAME, 'info', 'fbibJobOpening:', jobOpening)
	return await patchData(servicename, jobOpening, currentUser).then(async (jobOpening) => {
		return await getFBiBJobOpening(jobOpening.id, isReader, setFBiBJobOpening, setReadonly, setToastParams, configurationsList);
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'FBiB Stelle mit der Nummer "' + jobOpening.id + '" konnte nicht aktualisiert werden', message: error.message })
		} else {
			throw error
		}
	});
}

export const getFBiBJobOpening = async (id: any, isReader: any, setFBiBJobOpening: Function, setReadonly: Function, setToastParams: Function, configurationsList: any) => {
	return await getData(servicename, id).then((jobOpening) => {
		jobOpening.openingDate = new Date(jobOpening.openingDate)
		if (configurationsList) {
			if (jobOpening.company && jobOpening.company.contactPersons) {
				jobOpening.company.contactPersons = jobOpening.company.contactPersons.map((contactPerson: any) => {
					contactPerson.contactType = configurationsList.ContactTypeList.find((e: any) => e.alias === contactPerson.contactType);
					return contactPerson;
				})
			}
		}
		if (setFBiBJobOpening) {
			setFBiBJobOpening(jobOpening);
		}
		if (setReadonly) {
			setReadonly(!jobOpening.active || isReader)
		}
		return jobOpening;
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'FBiB Stelle mit der Nummer "' + id + '" konnte nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}