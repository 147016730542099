import { format, parseISO } from "date-fns";
import { Card } from "primereact/card";
import { ENUM_ICONS } from "../../Components/Icons";
import { ROUTES } from "../../Navigation/Routes";
import { useBreadcrumbsUpdate } from "../../Persistence/Breadcrumbs";
import { useCurrentUser, useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext"
import { useConfigurationsList, useFormatDate } from "../../Persistence/ConfigurationsContext";

export const FBiBJobOpeningCaseSheet = ({ jobOpening }) => {
	const isReader = useCurrentUserIsReaderFBiB();
	const configurationsList = useConfigurationsList();
	const formatDate = useFormatDate();
	const currentUser = useCurrentUser();
	const updateBreadcrumbs = useBreadcrumbsUpdate();

	const renderCaseSheetHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Falldaten</div>
			{jobOpening.fbibCase ?
				<div className='flex justify-content-between border-bottom-1'>
					<div className='flex font-medium  mb-2 '>{jobOpening.fbibCase.name}</div>
					<div className={isReader ? 'hidden' : 'flex '}>
						<div className='flex w-1rem' onClick={() => {
							const route = ROUTES.FBIB_CASES
							updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + jobOpening.fbibCase.id }] })
						}}>
							<i className={ENUM_ICONS.EYE} />
						</div>
					</div>
				</div> : ''}

		</div>
	}

	const renderCaseSheet = () => {
		const fbibCase = (jobOpening && jobOpening.fbibCase) ? jobOpening.fbibCase : null;
		return <Card className="jobopeningsheet card-sheet" header={renderCaseSheetHeader}>
			{!fbibCase ? 'keinem Fall zugeordnet' : <div className='m-1'>
				<div className={fbibCase.name ? 'grid' : 'hidden'}>
					<div className="col-fixed">Fall-ID</div>
					<div className="col">{'FBiB-FA-' + fbibCase.id.toString().padStart(5, "0")}</div>
				</div>
				<div className={fbibCase.date ? 'grid' : 'hidden'}>
					<div className="col-fixed">Falldatum</div>
					<div className="col">{formatDate(parseISO(fbibCase.date))}</div>
				</div>
				<div className={fbibCase.casePerson ? 'grid' : 'hidden'}>
					<div className="col-fixed">Fallperson</div>
					<div className="col">{fbibCase.casePerson ? fbibCase.casePerson.personId : ''}</div>
				</div>
				<div className={fbibCase.responsible !== null ? 'grid' : 'hidden'}>
					<div className="col-fixed">Verantwortliche*r</div>
					<div className="col">{fbibCase.responsible.displayname || fbibCase.responsible.user.displayname}</div>
				</div>
				<div className={fbibCase.creator ? 'grid' : 'hidden'}>
					<div className="col-fixed">Ersteller*in</div>
					<div className="col">{fbibCase.creator.displayname}</div>
				</div>
				<div className={fbibCase.status ? 'grid' : 'hidden'}>
					<div className="col-fixed">Status</div>
					<div className="col">{!fbibCase.status ? '' : configurationsList.FBiBCaseStatusList.find(e => e.alias === fbibCase.status).display}</div>
				</div>
			</div>}
		</Card>
	}

	return renderCaseSheet()
}